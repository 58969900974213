import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";
import { Product } from '../models/entities';

export interface reloadToTable {reload: boolean, crudRequest: any};
export interface reloadProductStock {clear: boolean, product: {[k: string]: Product | undefined} };


@Injectable({
  providedIn: 'root'
})
export class SharedService {


  // FIXME: for holo, condition, language on setInsert
  private nextReload = new BehaviorSubject(false);

  public nextReloadData = this.nextReload.asObservable();

  public nextReloadSave(v: boolean) {
    this.nextReload.next(v);
  }


  // FIXME: para saber cuando deshabilitar paginacion y sort y retornar a la normalidad
  private nextNormal = new BehaviorSubject<reloadToTable>({reload: false, crudRequest: {}});

  public nextNormalData = this.nextNormal.asObservable();

  public nextNormalDataSave( v: reloadToTable ) {
    this.nextNormal.next(v);
  }


  private subDataProductHeader = new BehaviorSubject<reloadProductStock>({product: {}, clear: false});

  public loadDataProductHeader: Observable<reloadProductStock> = this.subDataProductHeader.asObservable();

  public dataProductHeader(v: reloadProductStock) {
    this.subDataProductHeader.next(v);
  }




  constructor() { }
}
