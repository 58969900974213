<div class="container-fluid justA">
  <div class="row justA" *ngIf="children">
    <div class="col-12 justA">
      <ng-container class="justA" *ngFor="let comp of children">

        <ng-container class="justA" *ngComponentOutlet="comp.component;">
        </ng-container>

      </ng-container>
    </div>
  </div>
</div>
