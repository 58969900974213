import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import { Category } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class CategoryService extends DynacrudWeakDataService<Category> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('categories', CategoryService, dynacrudApiWrapper);
  }

  getType(): Type<Category> | string {
    return 'category';
  }
}
