import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';


export interface StateRows {
  selectAll: boolean;
  row: any[];
}

const INITIAL_STATE : StateRows = {selectAll: false, row: []};

// TODO: MIGLIORARE trans transferService core service

@Injectable({
  providedIn: 'root'
})
export class CountRowsService {

  private _state = new BehaviorSubject<StateRows>(INITIAL_STATE);

  state = this._state.asObservable();

  public pushRow(newRow: any) {
    const oldState = this._state.getValue();
    oldState.row.push(newRow || {});
    oldState.row = _.uniqWith( oldState.row , _.isEqual);
    this._state.next({ ...oldState, row: [...oldState.row]});

  }
  
  public deleteRow(index?: number) {
    const oldState = this._state.getValue();
    if (index) {
      // oldState.row.slice(0 , index); // fixme santiago voglio eliminare uno?
    } else {
      oldState.row.splice(0, oldState.row.length);
    }
    oldState.row = _.uniqWith( oldState.row , _.isEqual);
    this._state.next({ ...oldState, row: [...oldState.row] });

  }
}
