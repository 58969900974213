import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, debounceTime } from 'rxjs';
import { DynacrudApi, OperationType, SearchRequest } from 'src/app/core/models/dynacrud';
import { environment } from 'src/environments/environment';
import { Formula, Motivation, SessionsWrapper, WrapperWarehouseSessions } from '../models/entities';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SessionWrapperService {

  private URL = environment.apiUrl + '/v2/sessionWrappers';
  constructor( private http: HttpClient, private dynacrudApiWrap: DynacrudApiWrapper ) { }


  getMotivations(): Observable<DynacrudApi<Motivation[]>> {
    return this.http.get<DynacrudApi<Motivation[]>>(environment.apiUrl + '/v2/sessions/motivations');
  }




  private getVersioneSubject = new BehaviorSubject<SessionsWrapper | undefined>(undefined);
  public readonly getVersione = this.getVersioneSubject.asObservable();

  public reloadVersione(v: SessionsWrapper) {

    this.getVersioneSubject.next(v);

  }


  // subjects -->

  refreshActionSub = new BehaviorSubject<boolean>(false);
  refreshAction$ = this.refreshActionSub.asObservable();

  private rowsSub = new BehaviorSubject<any[]>([]);
  public readonly rowObs$ = this.rowsSub.asObservable();

  private refreshTableSessionSub = new BehaviorSubject<boolean>(false);
  public readonly refreshTableSessionAction$ = this.refreshTableSessionSub.asObservable();


  refreshTable(v: boolean) {
    this.refreshTableSessionSub.next(v);
  }

  countArr: any[] = [];
  cacheCountArr: any[] = [];

  addRow(v: any) {

    this.countArr.push(v);

    this.countArr = _.uniqBy(this.countArr, 'id');

    this.rowsSub.next(this.countArr);

  }

  addRows(values: any[]) {

    this.countArr.push(...values);
    this.rowsSub.next([]);
    
    this.countArr = _.uniqBy(this.countArr, 'id');
    this.rowsSub.next(this.countArr);

  }

  clearRows( ) {

    this.countArr = _.cloneDeep([]);
    this.rowsSub.next([]);
    this.rowsSub.next(this.countArr);

  }

  

  deleteRow(value: any) {

    this.rowsSub.next([]);
    this.countArr = _.cloneDeep(this.countArr.filter(on => on.id != value.id)) ;
    this.rowsSub.next(this.countArr);

  }

  private selectActionSub = new BehaviorSubject<{rows: {selected: boolean, row: any }[], all?: boolean }>({rows: [], all: false});
  selectRowsReload$ = this.selectActionSub.asObservable();

  selectChangeRowsOnSessionsWrapper(values: {rows: {selected: boolean, row: any }[], all?: boolean }) {
    this.selectActionSub.next(values);
  }


  refreshAction(value: boolean) {
    this.refreshActionSub.next(value);
  }

  private baseRoute<T>(type: string, id: number | undefined, typeHttp: string, body?: any, onlyID?: boolean) {
    const baseRouteURL = onlyID ?  this.URL + `/${id}`  : id ?  this.URL + `/${id}/`  : type ?  this.URL  + '/' : this.URL  ;

    if (body) {
      return (this.http as {[k: string]: any})[typeHttp](baseRouteURL + type, {...body}) as Observable<T>;
    }

    return (this.http as {[k: string]: any})[typeHttp](baseRouteURL + type) as Observable<T>;
  }

  private baseRouteWithSearchRequest<T>(type: string, id: number | undefined, typeHttp: string, search: SearchRequest, body?: any, onlyID?: boolean) {
    const baseRouteURL = onlyID ?  this.URL + `/${id}`  : id ?  this.URL + `/${id}/`  : this.URL + '/' ;

    const paramas = { params: { searchRequest: btoa(JSON.stringify(search)) } };

    return (this.http as {[k: string]: any})[typeHttp](baseRouteURL + type, paramas) as Observable<T>;
  }


  // cerca
  public warehouseSessions (wrapperId: number, search: SearchRequest): Observable<DynacrudApi<WrapperWarehouseSessions[]>> {
    return this.baseRouteWithSearchRequest('whSessions', wrapperId, 'get', search);
  }

  public resetAll<T>(id: number) {
    return this.baseRoute<T>('resetAll', id, 'post');
  }

  public calculate(id: number) {
    return this.baseRoute<DynacrudApi<SessionsWrapper>>('calculate', id, 'post');
  }

  public addSession(idWrapp: number, BodyNoId: {motivation?: Motivation; totalPurchasePrice?: number; descrizione?: string; formula?: Formula; warehouseSessionIds: number[]}): Observable<DynacrudApi<SessionsWrapper>> {
    
    const valueArr = BodyNoId.warehouseSessionIds.toString().split(',');
    return this.http.post<any>(this.URL + `/${idWrapp}/addSessions`, {}, {params: {'sessionIds' :  valueArr} }).pipe(debounceTime(50));

  }

  public addSessionBulk(id: number, body: any[], isFullSessionsWithNoId: boolean, BodyNoId: {motivation?: Motivation; totalPurchasePrice?: number; descrizione?: string; formula?: Formula; warehouseSessionIds: number[]} ) {
    return this.baseRoute( isFullSessionsWithNoId ? 'createFromSessions' : 'addSessionBulk', isFullSessionsWithNoId ? undefined : id , 'post', isFullSessionsWithNoId ? BodyNoId : body );
  }

  public editSessionWrapper(id: number, body: any) {
    return this.baseRoute<DynacrudApi<SessionsWrapper>>(``, id, 'put', body);
  }

  public creaSessionWrap(body: any) {
    return this.baseRoute('', undefined, 'post', body);
  }

  public deleteSessionWrapper(id: number) {
    return this.baseRoute(``, id, 'delete');
  }

  public allWarehouseSessions(id: number): Observable<DynacrudApi<any[]>> {
    return this.dynacrudApiWrap.getFor('sessions').search({filter: {filterBy: {field: 'wrapper.id', operation: OperationType.EQ, value: id}}}).pipe(debounceTime(50));
  }

  public baseId(id: number): Observable<DynacrudApi<SessionsWrapper>> {
    return this.baseRoute(``, id, 'get', undefined, true);
  }




  public detachFromWrapper(value: any, multiple: boolean) {

    const urlSingle = environment.apiUrl + `/v2/sessions/detach`;

    const valueIds = multiple ? (value as any[]).map(d => d.id).toString().split(',') : value.id;

    return this.http.post<DynacrudApi<SessionsWrapper>>(urlSingle, {}, {params: {'sessionIds': valueIds }});

  }


  public getCustomPriceFormulas() : Observable<DynacrudApi<Formula[]>> {
    return this.http.get<DynacrudApi<Formula[]>>( environment.apiUrl + '/v2/customPriceFormulas');
  }

 

}
