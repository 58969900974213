import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { SubSink } from 'subsink';
import { SessionWrapperService } from '../../../services/session-wrapper.service';
import { DynaConfActionType, State, selectCurrentSelection } from 'src/app/store/shared/shared.reducers';
import { catchError, debounceTime, distinctUntilChanged, map, throwError } from 'rxjs';
import { BaseAction } from 'src/app/store/store.actions';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';

@Component({
  selector: 'app-wrapp-all-sessions-action',
  templateUrl: './wrapp-all-sessions-action.component.html',
  styleUrls: ['./wrapp-all-sessions-action.component.scss']
})
export class WrappAllSessionsActionComponent implements TableActionComponent, OnDestroy, OnInit {

  @Input() data!: ActionData;
  selects: { rows: { selected: boolean, row: any }[], all: boolean } | undefined = undefined;
  idWrap: number | undefined = undefined
  ngOnInit() {
    this.subs.add(
      this.activeRouter.params.subscribe(params => {
        this.idWrap = +params['id'] /*|| il id di una session wrapper oppure undefined quindi la crea di base  */;
      }),

      this.store.pipe(select(selectCurrentSelection), debounceTime(50), distinctUntilChanged()).subscribe(res => {
        this.selects = res;
      })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }


  public rowIds(): number[] {

    return this.selects?.rows?.map(d => d?.row?.id) || [];

  }


  private subs = new SubSink();
  constructor(private sessionWrapService: SessionWrapperService, private activeRouter: ActivatedRoute,
    private dialogLayoutService: DialogService, private store: Store<State>, private router: Router) { }

  setAllWrapp() {

    const isSession = this.router.url.includes('Wrapper') ? false : true;

    if (!isSession) {

      if (!this.idWrap) {
        this.sessionWrapService.addRows(this.selects?.rows.map(d => d.row) as any[]);

      } else {
        if (this.idWrap && this.rowIds() && this.rowIds()?.length != undefined && (this.rowIds() as number[]).length > 0) {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));
          const numbers: number[] = this.rowIds() as number[];

          this.subs.add(
            this.sessionWrapService.addSession(this.idWrap as number, { warehouseSessionIds: numbers }).pipe(
              catchError((err) => {
                this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
                return throwError(() => err);
              }), map(d => d.data ) ).subscribe(d => {

                this.dialogLayoutService.openSnack('Sessioni aggiunti', 'done', 5000);
                this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));

                this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
                this.sessionWrapService.refreshAction(true);

                this.sessionWrapService.reloadVersione(d);

              })
          );

        }
      }

    } else {

      this.router.navigateByUrl(`app/warehouse/sessionWrapper/add`).then(
        () => {
          this.sessionWrapService.addRows(this.selects?.rows.map(d => d.row) as any[]);
        }
      );

    }
  }


}
