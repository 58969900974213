<div class="ent_but">

  <div class="d-flex  gap-1 align-items-center">
    <ng-container *ngIf="loadingSave; else noLoading">

      <div>
        <mat-spinner mode="indeterminate" diameter="30" style="padding: 0"></mat-spinner>
      </div>

    </ng-container>
    <ng-template #noLoading>

      <!--
        <button type="button" [disabled]="copyProducts && copyProducts.length < 1 " class="btn custom_warn"
          (click)="readyCallActive(); $event.preventDefault(); ">
          Save draft
        </button>
      -->

    </ng-template>

    <div class="wrap d-flex align-items-center">
      <input type="checkbox" class="hidsn" id="s4" name="persiste" [(ngModel)]="persistValue" />
      <label class="slider-v2 hidsn" for="s4" ></label>
      <span>
        Mantieni valori {{ persistValueOn ? '😎' : '😫' }}
      </span>
    </div>

  </div>


  <!-- draft -->

  <!-- 
  <ng-container *ngIf="validKey">
    <button mat-raised-button matBadgeColor="warn" [matBadge]=" existLoad ? '*' : '' "
      [matTooltip]="existLoad ? ' c\'è da caricare '  : '' " color="primary"
      (click)="loadCalls(validKey, true); $event.preventDefault(); ">
      Load draft
    </button>
  </ng-container>
  -->

</div>

<ng-container *ngIf="tableData && tableData.data">


  <ng-container *ngIf="tableData && tableData.data.length > 0; else nada">
    <cdk-virtual-scroll-viewport *ngIf="productStructure" scrollWindow itemSize="108" class="__sd_sd">

      <table class="table table-borderless table-sm m-0 smsm_ "
        *ngIf="productStructure && productStructure.stockfields && productStructure.stockfields.length > 0 && productStructure.fields && productStructure.fields.length > 0 ">

        <thead class="th_ead" [ngClass]="{ 'fixed-header': true }" [style.top]="inverseTranslation">
          <tr class="d-flex w-100 justify-content-between align-items-center">
            <th scope="col" class="d-flex" style="width: 40px; justify-content: center;">
              <div class="d-flex flex-column gap-1 align-items-start">
                <div class="d-flex">All</div>
                <div class="d-flex">
                  <div class="checkbox-wrapper-31 checkbox-wrapper-32">
                    <input checked="" type="checkbox" name="all" [(ngModel)]="productSelectAll" id="all_ch">

                    <svg viewBox="0 0 35.6 35.6">
                      <circle class="background" cx="17.8" cy="17.8" r="17.8"></circle>
                      <circle class="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                      <polyline class="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                    </svg>

                  </div>
                </div>
              </div>
            </th>

            <th scope="col" class="d-flex" style="width: 40px;">Image</th>

            <th scope="col" class="d-flex justify-content-center"
              (click)="setSort({$field: 'name',  $editable: false,  $label: 'name',  $sortable: true, $id: 'name'}); $event.preventDefault(); "
              style="width: 180px;">
              <span style=" display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }">
                Name
                <span style=" display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }"
                  matBadgeSize="small" *ngIf="sortStatus['name']" matBadgePosition="before" matBadgeColor="accent"
                  [matBadge]="sortStatus['name'].position + 1">
                  <mat-icon style="font-size: 20px !important; ">
                    {{sortStatus['name'].direction == 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </mat-icon>
                </span>

              </span>

            </th>

            <ng-container *ngFor="let f of productStructure.fields; index as ffIndex">
              <ng-container *ngIf="f && f.field && f.field != 'reserved' ">

                <th scope="col" class="d-flex justify-content-center" style="width: 100px;"
                  (click)="setSort({$field: f.field + '.id',  $editable: false,  $label: f.field,  $sortable: true, $id: f.field + '.id' }); $event.preventDefault(); ">

                  <span style=" display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }">
                    {{f.field}}
                    <span style=" display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }"
                      matBadgeSize="small" *ngIf="sortStatus[f.field + '.id']" matBadgePosition="before"
                      matBadgeColor="accent" [matBadge]="sortStatus[f.field + '.id'].position + 1">
                      <mat-icon style="font-size: 20px !important; ">
                        {{sortStatus[f.field + '.id'].direction == 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down'}}
                      </mat-icon>
                    </span>

                  </span>
                </th>

              </ng-container>

            </ng-container>

            <th scope="col" class="d-flex" style="width: 70px;"
              (click)="setSort({$field: 'number',  $editable: false,  $label: 'number',  $sortable: true, $id: 'number', $sortExpression: 'numberOrder'}); $event.preventDefault(); ">

              <span style="display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }">
                Numero
                <span style="display: flex" [ngStyle]="{cursor: noPaginationAndSort ? 'not-allowed' : 'pointer'  }"
                  matBadgeSize="small" *ngIf="sortStatus['number']" matBadgePosition="before" matBadgeColor="accent"
                  [matBadge]="sortStatus['number'].position + 1">

                  <mat-icon style="font-size: 20px !important; ">
                    {{sortStatus['number'].direction == 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down'}}
                  </mat-icon>

                </span>
              </span>
            </th>

            <ng-container *ngFor="let field of productStructure?.stockfields; index as idNon">
              <ng-container *ngIf="field.field && field.type == 'LOOKUP'">
                <th scope="col" class="d-flex">
                  <div class="d-flex" style="width: 130px;">

                    <!-- aqui 1 -->
                    <app-structure-rapid-look-up-field-normal class="w-100" (getValues)="getValues($event)"
                      [name]="field.field + '_' + field.type + idNon" [structureField]="field"
                      [resetAll]="resetValueOnFireObs"
                      [valueSet]="productGestioneFieldsAll(field.field + 'Row').productFieldAll"
                      [(ngModel)]="productGestioneFieldsAll(field.field + 'Row').productFieldAll">
                    </app-structure-rapid-look-up-field-normal>
                  </div>
                </th>
              </ng-container>

              <ng-container *ngIf="field.field && field.type == 'FLAG'">
                <th scope="col" class="d-flex">
                  <div class=" d-flex ">
                    <img (click)="setValueFieldAll(field); $event.preventDefault();" width="30" height="30"
                      [src]="getImagePath(field) || ''" matTooltip="{{field.field}}" [alt]="field" class="agg_img"
                      [ngClass]="{'black_all' : !valueFiledAll[field.field], 'color_all': valueFiledAll[field.field]   }">
                  </div>
                </th>
              </ng-container>
            </ng-container>

            <th scope="col" class="d-flex" style="width: 90px;">
              <input type="text" name="allComments" placeholder="Comments" [(ngModel)]="commentsAll" class="fixNormal_">
            </th>

            <th scope="col" class="d-flex" style="width: 138px;">
              <div class="d-flex " style="width: 130px;">
                <div class="d-flex flex-row p-0 m-0 contingenci">
                  <div class="d-flex inp_">
                    <div class="d-flex position-relative">
                      <input type="number" [min]="0" #genericFieldAll="ngModel" class="w-100"
                        [(ngModel)]="entityValueAll" placeholder="Warehouse" autocomplete="off" required />

                      <span *ngIf="!errorCustomAll && entityAll" class="errorVola">{{entityAll.name}}</span>
                    </div>
                  </div>
                  <div class="d-flex selt_">
                    <select class="form-select form-select-sm w-100" [(ngModel)]="entityValueAll"
                      [compareWith]="compareEntity" aria-label=".form-select-sm example">
                      <option selected></option>
                      <option style="color : black;" *ngFor="let ent of dataExtra['warehouses']" [value]="ent.id">
                        {{ent.id}} - {{ getEntitiesDescription(ent) }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </th>

            <th scope="col" class="d-flex" style="width: 90px;">
              <input type="number" name="allQuantity" #valueInputQuantityAll class="fixNormal_" placeholder="Quantity"
                [(ngModel)]="quantityAll" min="1" (ngModelChange)="validInput(undefined, valueInputQuantityAll)"
                onkeypress=" return (event.charCode != 8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 44 ) ">

            </th>

            <th scope="col" class="d-flex" style="width: 90px;">
              <input type="number" name="allpurchasePrice" #valueInputPurchasePrice class="fixNormal_"
                placeholder="purchase Price" [(ngModel)]="purchasePriceModel" min="0"
                (ngModelChange)="validInputDouble(undefined, valueInputPurchasePrice)"
                onkeypress=" return (event.charCode != 8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 44 ) ">

            </th>

            <th scope="col" class="d-flex" style="width: 140px; min-height: 64px;">
              <div class="d-flex flex-column justify-content-center align-items-center gap-1 w-100">
                <div class="d-flex">
                  Actions
                </div>

                <div class="d-flex justify-content-center gap-1 w -100">
                  <div class="d-flex align-items-center">

                    <ng-container *ngIf="allsValid() && allsValidValue ;">
                      <ng-container *ngIf="!loadAll; else loadingAllTem; ">
                        <button type="button" (click)="setAll(); loadAll = true; $event.preventDefault(); "
                          class="btn btn-outline-success btn-sm">
                          Save
                          {{allsValidValue > 1 ? allsValidValue + ' items' : allsValidValue != undefined ?
                          allsValidValue + ' item' : ''}}
                        </button>
                      </ng-container>
                      <ng-template #loadingAllTem>
                        <div>
                          <mat-spinner mode="indeterminate" diameter="30" style="padding: 0"></mat-spinner>
                        </div>

                      </ng-template>
                    </ng-container>


                  </div>

                </div>
              </div>
            </th>
          </tr>
        </thead>

        <!-- ! BODY TABLE -->
        <tbody class="d-flex w-100 justify-content-between flex-column"
          [ngStyle]="{'padding': loadingAll ? 'inherent' : '10px' }"
          *ngIf=" ( loadingAll || loadingType['MULTIPLE'] ) ; else loadingTemplate">

          <tr class="search-results" *cdkVirtualFor="let item of copyProducts ; let is = index;"
            [ngClass]="{'selectedRow' :  item.active}">

            <!-- productSelectValue[is] || -->

            <ng-container *ngIf=" loadingAll && productStructure 
              && item.warehouses && item.warehouses.length > 0 
              && item.languages && item.languages.length > 0 
              && item.conditions && item.conditions.length  ;">

              <!-- item.conditions && item.conditions.length > 0 && item.languages && item.languages.length > 0 &&-->

              <td style="width: 40px; justify-content: center;">
                <div class="d-flex align-items-center">
                  <div class="checkbox-wrapper-31">
                    <input checked="" type="checkbox" name="selectProduct_{{ is }}"
                      [(ngModel)]="productGestione(is, false, item.id).productSelect" id="is">
                    <svg viewBox="0 0 35.6 35.6">
                      <circle class="background" cx="17.8" cy="17.8" r="17.8"></circle>
                      <circle class="stroke" cx="17.8" cy="17.8" r="14.37"></circle>
                      <polyline class="check" points="11.78 18.12 15.55 22.23 25.17 12.87"></polyline>
                    </svg>
                  </div>
                </div>
              </td>

              <td style="width: 40px;">

                <div class="d-flex align-items-center">
                  <img #trigger="cdkOverlayOrigin" class="agg_img" cdkOverlayOrigin
                    (click)="showImage(is); $event.stopPropagation()" [src]="item.card_data.image"
                    [alt]="item.card_data.name" width="40" (error)="noImage(item)" height="50" />

                  <ng-template #cdkConOverlay="cdkConnectedOverlay" cdkConnectedOverlay
                    [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen[is]">
                    <div class="d-flex panel_onfire justify-content-center flex-column align-items-center w-100">
                      <div class="d-flex w-100" style="max-width: 200px">
                        <img [src]="item['card_data']['image']" [alt]="item['card_data']['image']"
                          (error)="noImage(item)" width="200" height="300" />
                      </div>
                    </div>
                  </ng-template>

                </div>
              </td>

              <td style="width: 180px;">
                <div class="d-flex w-100 justify-content-center">
                  <span>{{ item["name"] }}</span>
                </div>
              </td>

              <ng-container *ngFor="let f of productStructure.fields; index as ffIndex">
                <ng-container *ngIf="f && f.field && f.field != 'reserved'">
                  <td style="width: 100px; ">
                    <div class="d-flex w-100 justify-content-center" *ngIf="item[f.field]">

                      <ng-container *ngIf="f.field == 'color'">
                        <span
                          [ngStyle]=" {'width' : '20px', 'height': '20px', 'background-color' : showColor( item['color']['name'] ), 'border-radius': '15px' } "></span>
                      </ng-container>


                      <div class="module line-clamp" matTooltip="{{   item[f.field]['name'] }}">
                        <span> {{ item[f.field]['name'] }} </span>
                      </div>

                    </div>
                  </td>
                </ng-container>
              </ng-container>

              <td style="width: 70px;">
                <div class="d-flex">
                  <span>{{ item["number"] }}</span>
                </div>
              </td>

              <ng-container *ngFor="let field of productStructure?.stockfields;">
                <ng-container *ngIf="field.field && field.type == 'LOOKUP'">

                  <td *ngIf="field.field == 'language' ">
                    <div class="d-flex" style="width: 130px;" *ngIf="item.languages && item.languages.length > 0">

                      <div class="d-flex " style="width: 130px;">

                        <div class="d-flex flex-row p-0 m-0 contingenci align-items-center">
                          <div class="d-flex inp_ flex-column position-relative align-items-center h-100">
                            <form #form_sL="ngForm" class="d-flex h-100 w-100">

                              <!-- [ngClass]="{'error': errorCustomLingua[is + '_' + item.id] }" -->

                              <input type="number" [min]="0" #inputLanguage
                                (ngModelChange)="getChangeFormValueCustom(form_sL, inputLanguage)" class="w-100 h-100"
                                [name]="is + '_' + item.id "
                                [(ngModel)]="productGestione(is, true, item.id, inputLanguage ).entityValueLingua"
                                placeholder="Lingua" autocomplete="off" required="true" />

                              <!-- <span *ngIf="errorCustomLingua[is + '_' + item.id]" class="errorVola">error value</span> -->
                              <span *ngIf="!(errorCustomLingua[is + '_' + item.id]) && item['languageRow']"
                                class="errorVola">{{item['languageRow']?.name}}</span>

                            </form>

                          </div>

                          <div class="d-flex selt_ h-100">
                            <select required class="form-select form-select-sm w-100 h-100"
                              [name]="is + '_' + item.id + field.field + '_select' "
                              [(ngModel)]="productGestione(is, true, item.id).entityValueLingua"
                              [compareWith]="compareEntity" aria-label=".form-select-sm example">
                              <option selected></option>
                              <option style="color : black;" *ngFor="let ent of item.languages" [value]="ent.id">
                                {{ent.id}} - {{getEntitiesDescription(ent)}}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                  </td>

                  <td *ngIf="field.field == 'condition' ">
                    <div class="d-flex" style="width: 130px;" *ngIf="item.conditions && item.conditions.length > 0">

                      <div class="d-flex " style="width: 130px;">

                        <div class="d-flex flex-row p-0 m-0 contingenci align-items-center">
                          <div class="d-flex inp_ flex-column position-relative align-items-center h-100">
                            <form #form_sC="ngForm" class="d-flex h-100 w-100">

                              <!-- [ngClass]="{'error': errorCustomCondizione[is + '_' + item.id] }" -->

                              <input type="number" [min]="0" #inputCondition
                                (ngModelChange)="getChangeFormValueCustom(form_sC, inputCondition)" class="w-100 h-100"
                                [name]="is + '_' + item.id "
                                [(ngModel)]="productGestione(is, true, item.id, inputCondition ).entityValueCondition"
                                placeholder="Condizione" autocomplete="off" required="true" />

                              <span *ngIf="errorCustomCondizione[is + '_' + item.id]" class="errorVola">error
                                value</span>
                              <span *ngIf="!(errorCustomCondizione[is + '_' + item.id]) && item['conditionRow']"
                                class="errorVola">{{item['conditionRow']?.name}}</span>

                            </form>

                          </div>

                          <div class="d-flex selt_ h-100">
                            <select required class="form-select form-select-sm w-100 h-100"
                              [name]="is + '_' + item.id + field.field + '_select' "
                              [(ngModel)]="productGestione(is, true, item.id).entityValueCondition"
                              [compareWith]="compareEntity" aria-label=".form-select-sm example">
                              <option selected></option>
                              <option style="color : black;" *ngFor="let ent of item.conditions" [value]="ent.id">
                                {{ent.id}} - {{getEntitiesDescription(ent)}}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                  </td>

                  <td *ngIf="field.field == 'holo' ">
                    <div class="d-flex" style="width: 130px;" *ngIf="item.holos && item.holos.length > 0">

                      <div class="d-flex " style="width: 130px;">

                        <div class="d-flex flex-row p-0 m-0 contingenci align-items-center">
                          <div class="d-flex inp_ flex-column position-relative align-items-center h-100">
                            <form #form_s="ngForm" class="d-flex h-100 w-100">

                              <input type="number" [min]="0" #inputHolo
                                (ngModelChange)="getChangeFormValueCustom(form_s, inputHolo)" class="w-100 h-100"
                                [name]="is + '_' + item.id " [ngClass]="{'error': errorCustomHolo[is + '_' + item.id] }"
                                [(ngModel)]="productGestione(is, true, item.id, inputHolo ).entityValueHolos"
                                placeholder="Holo" autocomplete="off" required="true" />

                              <!-- <span *ngIf="errorCustomHolo[is + '_' + item.id]" class="errorVola">error value</span> -->
                              <span *ngIf="!(errorCustomHolo[is + '_' + item.id]) && item['holoRow']"
                                class="errorVola">{{item['holoRow']?.name}}</span>

                            </form>

                          </div>

                          <div class="d-flex selt_ h-100">
                            <select required class="form-select form-select-sm w-100 h-100"
                              [name]="is + '_' + item.id + field.field + '_select' "
                              [(ngModel)]="productGestione(is, true, item.id).entityValueHolos"
                              [compareWith]="compareEntity" aria-label=".form-select-sm example">
                              <option selected></option>
                              <option style="color : black;" *ngFor="let ent of item.holos" [value]="ent.id">
                                {{ent.id}} - {{getEntitiesDescription(ent)}}
                              </option>
                            </select>
                          </div>

                        </div>
                      </div>
                    </div>
                  </td>

                </ng-container>

                <ng-container *ngIf="field.field && field.type == 'FLAG'">

                  <td>
                    <div class=" d-flex " *ngIf="field.field">
                      <img class="agg_img" (click)="setValueField(field, is); $event.preventDefault(); " width="30"
                        height="30" [src]="getImagePath(field) || ''" [alt]="field"
                        [ngClass]="{'black' : !item[field.field], 'color': item[field.field]  }">
                    </div>
                  </td>

                </ng-container>
              </ng-container>

              <td style="width: 90px;">
                <div class="d-flex maxNormal h-100">
                  <!-- comments -->
                  <input type="text" style="height: 30px" placeholder="Scrivi" name="comment_{{ is }}"
                    [(ngModel)]="productGestione(is, true).productComment" />
                </div>
              </td>

              <td>
                <div class="d-flex " style="width: 130px;">

                  <div class="d-flex flex-row p-0 m-0 contingenci align-items-center">
                    <div class="d-flex inp_ flex-column position-relative align-items-center h-100">
                      <form #form="ngForm" class="d-flex h-100 w-100">

                        <input type="number" [min]="0" (ngModelChange)="getChangeForm(form)" class="w-100 h-100"
                          [name]="is + '_' + item.id" [ngClass]="{'error': errorCustom[is + '_' + item.id] }"
                          [(ngModel)]="productGestione(is, true, item.id).entityValueWarehouse" placeholder="Warehouse"
                          autocomplete="off" required="true" />

                        <!-- <span *ngIf="errorCustom[is + '_' + item.id]" class="errorVola">error value</span> -->

                        <span *ngIf="!(errorCustom[is + '_' + item.id]) && item['warehouseRow']"
                          class="errorVola">{{item['warehouseRow']?.name}}</span>

                      </form>
                    </div>
                    <div class="d-flex selt_ h-100">
                      <select [required]="true" class="form-select form-select-sm w-100 h-100"
                        [name]="is + '_' + item.id + '_select' "
                        [(ngModel)]="productGestione(is, true, item.id).entityValueWarehouse"
                        [compareWith]="compareEntity" aria-label=".form-select-sm example">
                        <option selected></option>
                        <option style="color : black;" *ngFor="let ent of item.warehouses" [value]="ent.id">
                          {{ent.id}} - {{getEntitiesDescription(ent)}}
                        </option>
                      </select>
                    </div>

                  </div>
                </div>
              </td>

              <td style="width: 90px;">
                <div class="d-flex maxNormal h-100">
                  <!-- quantity -->
                  <!-- return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)); -->
                  <input required #valueInputQuantity style="height: 30px" type="number" placeholder="Quantity" min="1"
                    (ngModelChange)="validInput(is, valueInputQuantity)"
                    onkeypress=" return (event.charCode != 8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 44 ) ;"
                    [(ngModel)]="productGestione(is, true).productQuantity" name="quantity_{{ is }} " />
                </div>
              </td>

              <td style="width: 90px;">
                <div class="d-flex maxNormal h-100">
                  <!-- quantity -->
                  <!-- return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57)); -->
                  <input #valueInputPurchase style="height: 30px" type="number" placeholder="Purchase Price" min="0"
                    (ngModelChange)="validInputDouble(is, valueInputPurchase)"
                    onkeypress=" return (event.charCode != 8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46 || event.charCode == 44 );"
                    [(ngModel)]="productGestione(is, true).productPurchasePrice" name="purchasePrice_{{ is }} " />
                </div>
              </td>

              <td style="width: 140px;">
                <div class="d-flex gap-1 flex-row" style="max-width: 110px;">

                  <button [disabled]="loadingType[is + 'SINGLE']" type="button" class="btn btn-primary btn-sm"
                    matTooltip="duplica" (click)="clonedRow(is); $event.preventDefault();  ">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-back" viewBox="0 0 16 16">
                      <path
                        d="M0 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-2H2a2 2 0 0 1-2-2V2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H2z" />
                    </svg>
                  </button>


                  <button type="button" [disabled]="loadingType[is + 'SINGLE']" class="btn btn-danger btn-sm"
                    matTooltip="elimina" (click)="deletedRow(is); $event.preventDefault();">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                      class="bi bi-trash" viewBox="0 0 16 16">
                      <path
                        d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                      <path
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                    </svg>
                  </button>


                  <ng-container *ngIf="loadingType[is + 'SINGLE']; else showButton;">
                    <div>
                      <mat-spinner mode="indeterminate" diameter="30" style="padding: 0"></mat-spinner>
                    </div>
                  </ng-container>
                  <ng-template #showButton>
                    <span matTooltip="save row">

                      <button type="submit" [disabled]=" item && item.brandId == 6 ?  (!item.quantity || !item.languageRow || !item.conditionRow || !item.warehouseRow || !item.holoRow) :
                         (!item.quantity || !item.languageRow || !item.conditionRow || !item.warehouseRow)  "
                        (click)="saveRow(is); $event.preventDefault(); " class="btn btn-success btn-sm">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                          class="bi bi-save" viewBox="0 0 16 16">
                          <path
                            d="M2 1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H9.5a1 1 0 0 0-1 1v7.293l2.646-2.647a.5.5 0 0 1 .708.708l-3.5 3.5a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L7.5 9.293V2a2 2 0 0 1 2-2H14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h2.5a.5.5 0 0 1 0 1H2z" />
                        </svg>
                      </button>
                    </span>
                  </ng-template>
                </div>
              </td>
            </ng-container>
          </tr>

        </tbody>
      </table>

      <ng-template #loadingTemplate>
        <div class="d-flex flex-column justify-content-center align-items-center p-1 w-100 h-100">
          <div>
            <mat-spinner mode="indeterminate" diameter="90" style="padding: 0"></mat-spinner>
          </div>
        </div>
      </ng-template>

    </cdk-virtual-scroll-viewport>

  </ng-container>


  <mat-paginator *ngIf="!noPaginationAndSort" class="mat-elevation-z1" style="margin-top: 60px;"
    [pageSize]="tableData.tableStatus.page.length > 0 ?  tableData.tableStatus.page.pageSize : 0"
    [pageIndex]="tableData.tableStatus.page.pageIndex" [showFirstLastButtons]="true"
    [length]="tableData.tableStatus.page.length">
  </mat-paginator>
</ng-container>


<ng-template #nada>
  <div class="d-flex justify-content-center w-100 align-items-center">

    <h2>No product found!</h2>

  </div>
</ng-template>