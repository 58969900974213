import { HttpClient } from '@angular/common/http';
import { Injectable, Type } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { isString } from 'lodash';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/shared/models/entities';
import { environment } from 'src/environments/environment';
import { DynacrudApi, MovementRequest, SearchRequest } from '../models/dynacrud';
import { ContextService } from '../services/context.service';
import { paramsCustom } from '../models/route-config';

export interface DynacrudApiInfo {
    path: string;
    full: boolean;
    hasState?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class DynacrudApiWrapper {

    constructor(
        protected http: HttpClient
    ) { }

    private sourceInfoCache: { [key: string]: DynacrudApiInfo } = {
        // expansion: { path: 'expansions', full: false},
    };

    protected getBasePath() {
        return environment.apiUrl;
    }

    // TODO: getDefaultImpl
    public getDefaultImpl<T extends Entity>(type: Type<T> | string, params?: paramsCustom): DynaCrudService<T> {

        let typeString: string = type.toString();

        if (!isString(type)) {
            typeString = type.constructor.name.toString();
        }

        const apiInfo: DynacrudApiInfo | undefined = this.getInfo(typeString as any);
        typeString = apiInfo ? apiInfo.path : typeString;

        const relativeUrl: string = typeString;
        const me = this;
        return {
            delete(entity: T): Observable<DynacrudApi<T>> {
                return me.http.delete<DynacrudApi<T>>(me.url(relativeUrl) + `/${entity.id}`);
            },

            save(entity: T): Observable<DynacrudApi<T>> {
                if (entity.id) {
                    return me.http.put<DynacrudApi<T>>(me.url(relativeUrl) + `/${entity.id}`, entity);
                } else {
                    return me.http.post<DynacrudApi<T>>(me.url(relativeUrl), entity);
                }
            },

            get(id: any): Observable<DynacrudApi<T>> {
                return me.http.get<DynacrudApi<T>>(me.url(relativeUrl) + `/${id}`);
            },
            search(searchRequest?: SearchRequest): Observable<DynacrudApi<T[]>> {
                if (!searchRequest) {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
                } else {
                    if (params) {
                        return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { ...params, searchRequest: btoa(JSON.stringify(searchRequest)) } });
                    }
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { searchRequest: btoa(JSON.stringify(searchRequest)) } });
                }
            },
            searchCustomer(searchRequest: SearchRequest, movementRequest?: MovementRequest) {
                if (!movementRequest && !searchRequest ) {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
                } else {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { searchRequest: btoa(JSON.stringify(searchRequest)), movementRequest: btoa(JSON.stringify(movementRequest))  } });
                }
                
            },
            lookup(text: string, pageSize: number): Observable<DynacrudApi<T[]>> {
                return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
            }
        } as DynaCrudService<T>;


    }

    private url(relativeUrl: string): string {
        return this.getBasePath() + '/v2/' + (relativeUrl);
    }

    // TODO: getFor searchRequest
    public getFor<T extends Entity>(type: Type<T> | string | { value: string; s: boolean }, params?: paramsCustom): DynaCrudService<T> {
        let typeString: any = undefined;

        if (!isString(type) && !(type as any)?.value) {
            typeString = type.constructor.name.toString();
        }

        if (isString(type)) {
            typeString = type.toString();
        }

        if ((type as { value: string; s: boolean })['value']) {
            typeString = type;
        }

        const apiInfo: DynacrudApiInfo | undefined = this.getInfo(typeString);
        typeString = apiInfo ? apiInfo.path : typeString?.value || typeString;

        const service = ContextService.instance()?.getService(typeString?.value || typeString, 'DynaCrudService');

        if (service) {
            return service;
        }
        const relativeUrl: string = typeString;

        const me = this;
        return {

            get url(): string {
                return me.getBasePath() + '/v2/' + (relativeUrl);
            },

            delete(entity: T): Observable<DynacrudApi<T>> {
                return me.http.delete<DynacrudApi<T>>(me.url(relativeUrl) + `/${entity.id}`);
            },

            save(entity: T): Observable<DynacrudApi<T>> {
                if (entity.id) {
                    return me.http.put<DynacrudApi<T>>(me.url(relativeUrl) + `/${entity.id}`, entity);
                } else {
                    return me.http.post<DynacrudApi<T>>(me.url(relativeUrl), entity);
                }
            },

            get(id: any): Observable<DynacrudApi<T>> {
                return me.http.get<DynacrudApi<T>>(me.url(relativeUrl) + `/${id}`);
            },
            search(searchRequest?: SearchRequest): Observable<DynacrudApi<T[]>> {
        
                if (!searchRequest) {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
                } else {
                    if (params) {
                        return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { ...params,  searchRequest: btoa(JSON.stringify(searchRequest)) } });
                    }
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { searchRequest: btoa(JSON.stringify(searchRequest)) } });
                }
            },

            searchCustomer(searchRequest: SearchRequest, movementRequest?: MovementRequest) {
                if (!searchRequest && !movementRequest) {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
                } else {
                    return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { searchRequest: btoa(JSON.stringify(searchRequest)), movementRequest: btoa(JSON.stringify(movementRequest))  } });
                    // return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl), { params: { searchRequest: btoa(JSON.stringify(searchRequest)) } });
                }
                
            },

            lookup(text: string, pageSize: number): Observable<DynacrudApi<T[]>> {
                return me.http.get<DynacrudApi<T[]>>(me.url(relativeUrl));
            }
        } as DynaCrudService<T>;
    }

    getInfo(keyValue: { [key: string]: any } /** puo essere 's' || 'value' || string */): DynacrudApiInfo | undefined {
        const key = keyValue['value'] || keyValue;
        if (key) {
            const source: string = key.toString();
            let sourceInfo: DynacrudApiInfo = this.sourceInfoCache[source];
            if (!sourceInfo) {
                let sourcePath = source;
                if (sourcePath.indexOf('/') == -1) {
                    sourcePath = _.camelCase(source);
                    if (sourcePath.endsWith('y')) {
                        sourcePath = sourcePath.substr(0, sourcePath.lastIndexOf('y')) + 'ies';
                    } else if (!sourcePath.endsWith('s') && !keyValue['s']) {
                        sourcePath = sourcePath + 's';
                    } else {
                        sourcePath = sourcePath;
                    }
                }

                sourceInfo = { path: sourcePath, full: true };
                this.sourceInfoCache[source] = sourceInfo;
            }
            return this.sourceInfoCache[source];
        }
        return undefined;

    }




}


export interface DynaCrudService<T extends Entity> {
    delete(entity: T): Observable<DynacrudApi<T>>;
    search(searchRequest?: SearchRequest ): Observable<DynacrudApi<T[]>>;
    searchCustomer(searchRequest?: SearchRequest, movementRequest?: MovementRequest ): Observable<DynacrudApi<T[]>>;
    get(id: any): Observable<DynacrudApi<T>>;
    save(entity: T): Observable<DynacrudApi<T>>;
}

