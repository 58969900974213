import { Injectable, Type } from '@angular/core';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { DynacrudWeakDataService } from './DynacrudWeakDataService';
import { Frame } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class FrameService extends DynacrudWeakDataService<Frame> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('frames', FrameService, dynacrudApiWrapper);
  }

  getType(): Type<Frame> | string {
    return 'frame';
  }
}
