<!-- && environment.test -->

<ng-container
  *ngIf="(data.item.stateId > 3 && ( data.item.motivation && data.item.motivation.name && !data.item.wrapper ) ) ">

  <button mat-icon-button matTooltip="aggiungere sessione a un wrapper"
    [disabled]="row().systemSession || row().hasNegativeQuantity"
    (click)="set(); $event.preventDefault(); $event.stopPropagation(); ">
    <mat-icon>
      reply
    </mat-icon>
  </button>
</ng-container>