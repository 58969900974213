import {Component, Input, OnInit} from '@angular/core';
import {ColumnData, ColumnFieldComponent} from "../../../../core/components/table/Column";

@Component({
  selector: 'app-icons-structure-single',
  templateUrl: './icons-structure-single.component.html',
  styleUrls: ['./icons-structure-single.component.scss']
})
export class IconsStructureSingleComponent implements ColumnFieldComponent, OnInit {

  @Input() field: string | undefined;

  @Input() fieldValue: string | undefined;


  data!: ColumnData;

  loadGetImagePath() {
    return `./assets/icons/${this.field}/${this.fieldValue}.svg`;
  }


  ngOnInit() {

    this.field = this.data.field?.field?.toString()?.slice(12, this.data.field?.field?.toString()?.length );

    this.fieldValue = this.data.row?.combination[''+this.field]?.name;

    this.loadGetImagePath();

  }


}
