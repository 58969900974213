<ng-container *ngIf="!structureField.stock && structureDataFilter?.productSearch?.product?.id; else enabledOrStock">

  <app-default-select [featureCode]="featureCode" [readonly]="true" [name]="structureField.field || ''" [(ngModel)]="productValue" class=""
    [label]="structureField.field || ''" [multiple]="false" [filterBy]="lookupFilter"
    [source]="structureField.source || ''"></app-default-select>

</ng-container>
<ng-template #enabledOrStock>

  <app-default-select [featureCode]="featureCode" [disabled]="disabled" [modifyData]="modifyData" [name]="structureField.field || ''"
    [(ngModel)]="value" class="" [label]="structureField.field || ''" [multiple]="mult == false ? mult : true"
    [filterBy]="lookupFilter" [source]="structureField.source || ''"></app-default-select>

</ng-template>