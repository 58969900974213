import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, distinctUntilChanged, debounceTime, catchError, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { TableSelection } from 'src/app/core/components/table/table/table.component';
import { TableEvent } from 'src/app/core/models/table-config';
import { TransferType } from 'src/app/shared/models/entities';
import { HostAllRowsInColumnsService } from 'src/app/shared/services/host-all-rows-in-columns.service';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { selectFeatureCode, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';
import { WarehouseMovementDTO } from '../../../models/entities';
import { AddMovementService } from '../../../services/add-movement.service';
import { CountRowsService } from '../../../services/count-rows.service';
import { ReloadRowItemsSingleService } from '../../columns/quantity-session-movement-column/quantity-session-movement-column.component';
import { SuccesComponent } from '../../columns/select-warehouse-change-trans-column/select-warehouse-change-trans-column.component';

export class whMovements {
  productId?: number;
  languageId?: number;
  holoId?: number;
  conditionId?: number;
  warehouseFromId?: number;
  warehouseToId?: number;
  signed?: boolean;
  stamped?: boolean;
  inked?: boolean;
  altered?: boolean;
  foil?: boolean;
  firstEdition?: boolean;
  shadowless?: boolean;
  staff?: boolean;
  comments?: string;
  quantity?: number;
}

@Component({
  selector: 'app-add-movement-session-action',
  templateUrl: './add-movement-session-action.component.html',
  styleUrls: ['./add-movement-session-action.component.scss']
})
export class AddMovementSessionActionComponent implements TableActionComponent, OnInit, OnDestroy {

  constructor(private store: Store<State>, private hostallRows: HostAllRowsInColumnsService, private _snackBar: MatSnackBar,
    private _countRowsService: CountRowsService, private addMovement: AddMovementService,
    private route: ActivatedRoute, private router: Router, private hostRow: HostColumnEditService, private _DATASERVICESINGLE: ReloadRowItemsSingleService) { }

  private result: any;
  error!: boolean;
  private codeFeature!: string;
  data!: ActionData;
  event?: EventEmitter<TableEvent>;
  idSession!: number;
  warehouseMovementDTO: WarehouseMovementDTO = {};
  subs = new SubSink();
  currentSelectProp: any;
  itemSessionId!: number | undefined;
  items!: TableSelection;
  go = new BehaviorSubject<boolean | null>(null);
  private goSub = this.go.asObservable();

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  initValues() {
    const TYPE_ = TransferType;
    if ((TYPE_.TRANS == this.getCode(this.codeFeature))) {
      this.subs.add(
        this.hostallRows.dataRowToAll.pipe().subscribe(result => {
          if (result != null) {
            this.currentSelectProp = this.data.item;
            this.go.next(true);
          } else {
            this.go.next(false);
          }

        })

      );
    } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature))) {
      this.subs.add(
        this.hostallRows.dataRowToAll.pipe().subscribe(result => {
          if (result != null) {
            this.currentSelectProp = this.data.item;
            this.go.next(true);
          } else {
            this.go.next(false);
          }
        })
      );

    }


  }

  private getCode(f: string): string {
    // controllo il codice ? posso modificarlo , ma non lo sovrascrivo
    return f;
  }


  ngOnInit(): void {
    const TYPE_ = TransferType;
    /**get feature code */
    this.codeFeature = '';

    this.subs.add(

      this.store.pipe(select(selectFeatureCode), distinctUntilChanged(), debounceTime(50)).subscribe(data => {
        if (data) {
          const isCode = data; // fixme santiago controllo il type di feature code e posso gestirla dopo? mi serve questo
          this.codeFeature = this.getCode(isCode);


          this.initValues();
        }
      })
    );


    this.subs.add(
      this.route.paramMap.subscribe(param => {
        const id = param.get('id');
        if (id != null) {
          this.itemSessionId = parseInt(id);
        }
      })
    );

    this.goSub.pipe(distinctUntilChanged()).subscribe(d => {
      if (d) {
        if (this.data.item.id && this.valid()) {
          this.currentSelectProp = this.data.item;
          if (this.currentSelectProp?.id) {
            // trans / unload / other
            if ((TYPE_.TRANS == this.getCode(this.codeFeature))) {
              if ((this.currentSelectProp.instanceValue <= this.data.item.avaible && this.currentSelectProp.instanceValue > 0) && (this.currentSelectProp?.instanceValueWarehouse?.id)) {
                const itemRow_ = { selected: true, row: this.currentSelectProp };
                this._countRowsService.pushRow(itemRow_);
              }
            } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature))) {
              if ((this.currentSelectProp.instanceValue <= this.data.item.avaible && this.currentSelectProp.instanceValue > 0) /*&& (this.currentSelectProp?.instanceValueWarehouse?.id)*/) {
                const itemRow_ = { selected: true, row: this.currentSelectProp };
                this._countRowsService.pushRow(itemRow_);
              }
            }
          }
        }
      }
    });
  }

  public valid(): boolean | undefined {
    const TYPE_ = TransferType;
    if (this.currentSelectProp) {
      if (this.currentSelectProp.id == this.data.item.id) {
        if ((TYPE_.TRANS == this.getCode(this.codeFeature))) {
          if (((this.currentSelectProp.instanceValue <= this.data.item.avaible) && this.currentSelectProp?.instanceValue > 0 && this.currentSelectProp.instanceValueWarehouse?.id) && (this.currentSelectProp?.instanceValueWarehouse?.id !== this.data?.item?.warehouse.id)) {
            // push to array with values
            this.error = false;
            this.initValues();
            return true;
          } else {
            // removes to array with id  --> id object
            this.go.next(false);
            this.error = true;
            return false;
          }
        } else if ((TYPE_.UNLOAD == this.getCode(this.codeFeature))) {

          if (((this.currentSelectProp.instanceValue <= this.data.item.avaible) && this.currentSelectProp?.instanceValue > 0 /*&& this.currentSelectProp.instanceValueWarehouse?.id*/)) {
            // push to array with values
            this.error = false;
            this.initValues();
            return true;
          } else {
            // removes to array with id  --> id object
            this.go.next(false);
            this.error = true;
            return false;
          }
        }
      }

    }

    return undefined;
  }

  private preSave() {
    const rowInsert = <WarehouseMovementDTO>Object.assign(new whMovements(), {
      productId: this.data?.item.product.id,
      quantity: this.data?.item.instanceValue,
      conditionId: this.data.item?.combination?.conditionId || null,
      languageId: this.data.item?.combination?.languageId || null,
      holoId: this.data.item?.combination?.holo?.id != null && this.data.item?.combination?.holo?.id != undefined ? this.data.item?.combination?.holo?.id : null,
      signed: this.data.item?.combination?.signed != null ? this.data.item.combination.signed : null,
      stamped: this.data.item?.combination?.stamped != null ? this.data.item.combination.stamped : null,
      inked: this.data.item?.combination?.inked != null ? this.data.item.combination.inked : null,
      altered: this.data.item?.combination?.altered != null ? this.data.item.combination.altered : null,
      foil: this.data.item?.combination?.foil != null ? this.data.item.combination.foil : null,
      firstEdition: this.data.item?.combination?.firstEdition != null ? this.data.item.combination.firstEdition : null,
      shadowless: this.data.item?.combination?.shadowless != null ? this.data.item.combination.shadowless : null,
      staff: this.data.item.combination?.staff != null ? this.data.item.combination.staff : null,
      warehouseFromId: this.data.item.warehouse?.id,
      warehouseToId: this.data.item?.instanceValueWarehouse?.id || null,
      comments: this.data.item.comments || null,
    });
    return rowInsert;

  }

  getValueSessionId(val: any): number | undefined {
    if (val.data.length > 0) {
      this.itemSessionId = val.data[0]?.warehouseSession?.id;
      return val.data[0]?.warehouseSession?.id;
    }

    return undefined;

  }

  openSnackBar() {

    this._snackBar.openFromComponent(SuccesComponent, {
      duration: 5000,
    });

  }

  saveInsert(e: Event) {
    e.preventDefault();
    if (this.currentSelectProp?.id) {
      const itemToSave = this.preSave();

      this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));
      this.addMovement.saveMovement(itemToSave, this.itemSessionId != null ? this.itemSessionId : undefined).pipe(debounceTime(50),
        catchError(err => {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          return throwError(err);
        })
      ).subscribe(dataResult => {
        if (dataResult) {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          this.result = dataResult;
          // refresh data row --> fixme santiago creare un refresh
          (this.result.data as WarehouseMovementDTO[]).forEach((cards) => {
            if (cards.productId == this.data.item.productId) {
              this.data.item.instanceValue = 0;
              this.data.item.instanceValueWarehouse = null;
              this._DATASERVICESINGLE._data.next({ data: this.data.item.productId, reload: true });
              this.hostRow._resetData.next(false);

            }
          });

          if (!this.itemSessionId || this.itemSessionId == null || this.itemSessionId == undefined) {
            this.itemSessionId = this.getValueSessionId(this.result);
          } else {
            this.itemSessionId = this.itemSessionId;
          }

          // this.hostRow._newIdSession.next(this.itemSessionId);
          this.openSnackBar();

          this.hostRow._newIdSession.next({ id: this.itemSessionId, code: this.codeFeature });

          // this.store.dispatch(new BaseAction(DynaConfActionType.Refresh)); // FIXME SANTIAGO

          this.subs.add(
            this.route.paramMap.pipe(distinctUntilChanged()).subscribe(param => {
              const id = param.get('id');
              this.idSession = parseInt(id || '');
              if (!id) {
                this.router.navigate([`app/warehouse/session/movement/${this.codeFeature}/${this.itemSessionId}`], {
                  replaceUrl: true,
                  queryParamsHandling: 'merge',
                  skipLocationChange: false
                }).then(() => {
                  this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

                  (this.result.data as WarehouseMovementDTO[]).forEach((cards) => {
                    if (cards.productId == this.data.item.productId) {
                      this.data.item.instanceValue = 0;
                      this.data.item.instanceValueWarehouse = null;
                      this._DATASERVICESINGLE._data.next({ data: this.data.item.productId, reload: true });
                      this.hostRow._resetData.next(false);
                      this.hostRow.hostSetAllQuantity(false);
                      this._countRowsService.deleteRow();
                      this.hostRow._newIdSession.next({ id: this.itemSessionId, code: this.codeFeature });

                    }
                  });
                });
              } else {
                this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
              }
            })
          );


          this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));





        }
      })
    }
  }

}
