

<div class="d-flex align-items-center gap-2">

  <div class="d-flex" *ngIf="youcango">
    <button mat-icon-button matTooltip="Vai al wrapper"  (click)="taylorCanGo(); $event.preventDefault(); " >
      <!-- tylor vai al warpp -->
      <mat-icon>
        switch_access_shortcut
      </mat-icon>
    </button>
  </div>
  <div class="d-flex" *ngIf="youcandetach">

    <button mat-icon-button color="warn" matTooltip="stacca dal wrapper"  (click)="taylorCanDetach(); $event.preventDefault(); $event.stopPropagation(); ">
      <mat-icon svgIcon="pip" aria-hidden="false" aria-label="Svg Icon"></mat-icon>
    </button>
    
  </div>

</div>