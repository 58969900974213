import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import _ from 'lodash';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';

export const TransferTypeRoutePages = {
  IN: 'IN',
  TRANS: 'TRANS',
  OUT: 'OUT',
  'NEW-SET-INSERT': 'NEW-SET-INSERT'

}

@Component({
  selector: 'app-add-session-movement',
  templateUrl: './add-session-movement.component.html',
  styleUrls: ['./add-session-movement.component.scss']
})
export class AddSessionMovementComponent {

  constructor(private router: Router, private store: Store<State>) { }

  SELECTS_AVAIVABLE = environment.SELECTS_AVAIVABLE;

  newGo(route: string) {

    const rProp = _.cloneDeep(route);
    const endPart = rProp.substring(rProp.lastIndexOf('/') + 1);


    this.router.navigateByUrl(`${route}`).then(() => {


      this.store.dispatch(new BaseAction(DynaConfActionType.ResetPage, true));
      // if (endPart && endPart.length > 0 && endPart == TransferTypeRoutePages.IN) {

      // } else {

      // }


    });

  }

  // newLoad() {
  //   this.router.navigateByUrl(`app/warehouse/session/movement/IN`).then(() => {

  //     //this.store.dispatch(new BaseAction(DynaConfActionType.ResetPage, true));
  //   });
  // }
  // newTransfer() {
  //   this.router.navigateByUrl(`app/warehouse/session/movement/TRANS`).then(() => {
  //     console.log('trans');
  //     this.store.dispatch(new BaseAction(DynaConfActionType.ResetPage, true));
  //   });
  // }
  // newUnload() {
  //   this.router.navigateByUrl(`app/warehouse/session/movement/OUT`).then(() => {
  //     console.log('out');
  //     this.store.dispatch(new BaseAction(DynaConfActionType.ResetPage, true));
  //   });
  // }

  // newSetInsert () {

  //   this.router.navigateByUrl(`app/warehouse/NEW-SET-INSERT`).then(() => {});

  // }

}
