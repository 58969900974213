import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewContainerRef } from '@angular/core';
import { TableAction, TableEvent } from 'src/app/core/models/table-config';
import { SubSink } from 'subsink';
import { TableActionComponent } from '../Actions';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html',
  styleUrls: ['./action.component.scss']
})
export class ActionComponent implements OnInit, OnDestroy {

  @Input()
  action!: TableAction;

  @Input()
  item: any;

  @Input()
  instanceCustom: any;

  @Output()
  event = new EventEmitter<TableEvent>();

  private subs = new SubSink();


  constructor(private viewContainerRefHost: ViewContainerRef) { }

  ngOnInit() {
    // now i dont need componentFactory --> https://stackoverflow.com/questions/70946038/replace-deprecated-angular-componentfactoryresolver-componentfactory

    if (this.action.actionComponent) {
      const component = this.viewContainerRefHost.createComponent(this.action.actionComponent);
      const actionComponent: TableActionComponent = (component.instance as TableActionComponent);
      actionComponent.data = { action: this.action, item: this.item, instanceValue: this.instanceCustom };
      if (actionComponent.event) {
        this.subs.add(actionComponent.event.subscribe(te => this.event.emit(te)));
      }
    }
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
