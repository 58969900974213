import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { fromEvent, map, debounceTime, distinctUntilChanged, take } from 'rxjs';
import { MaterialModule } from 'src/app/material/material/material.module';
import { ASCIIFolder } from 'src/app/shared/models/entities';
import { DynaCrudRequest, selectConf, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-default-search-header',
  templateUrl: './default-search-header.component.html',
  styleUrls: ['./default-search-header.component.scss'],
  imports: [CommonModule, MaterialModule, TranslateModule],
  standalone: true
})
export class DefaultSearchHeaderComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  private featureCode!: string;
  private crudRequest!: DynaCrudRequest;

  @ViewChild('defaultSearch', { static: true }) defaultSearch!: ElementRef;

  @Input() public placeholder!: string;

  @Input() defaultFeatureCode: string | undefined = undefined;

  private isFocus = false;

  constructor(private store: Store<State>) { }

  private dispatchFilterChange(text: string) {
    let searchText = '';
    if (text.length > 1) {
      searchText = text;

      searchText = ASCIIFolder.foldReplacing(searchText);

      // this.resetTable();

    }
    if (this.featureCode && this.crudRequest.searchRequest && this.crudRequest.searchRequest.filter) {
      if (searchText !== this.crudRequest.searchRequest.filter.text) {
        this.crudRequest.searchRequest.filter.text = searchText;
        this.crudRequest.searchRequest.pagination = { page: 0, size: 20 };
        this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
      }
    }
  }

  ngOnInit() {
    let first = true;
    this.subs.add(this.store.pipe(select(selectConf), distinctUntilChanged(), debounceTime(0) ).subscribe(conf => {
  
      if (conf && conf.route) {
        // se c'è il conf.route --> c'è sempre il route
        this.featureCode = this.defaultFeatureCode ? this.defaultFeatureCode : (conf.route.code as string);

        if (conf.dynaData && conf.dynaData[this.featureCode]) {
          this.crudRequest = conf.dynaData[this.featureCode].request || { searchRequest: { filter: {} }, customData: null };
        } else {
          this.crudRequest = { searchRequest: { filter: {} }, customData: null };
        }

        this.crudRequest.searchRequest = this.crudRequest.searchRequest || { filter: {} };
        this.crudRequest.searchRequest.filter = this.crudRequest.searchRequest.filter || {};

        if (!this.crudRequest.searchRequest?.filter?.text) {
          // ! hago mantener todos los demas datos. 
          this.crudRequest.searchRequest.filter = { ...this.crudRequest.searchRequest.filter, text: this.defaultSearch.nativeElement.value || ''  };
        }
        
        if (first) {

          if ( this.featureCode && this.crudRequest.searchRequest && this.crudRequest.searchRequest.filter ) {
            if (this.defaultSearch.nativeElement.value) {
              this.crudRequest.searchRequest.pagination = { page: 0, size: 20 };
              this.crudRequest.searchRequest.filter = { ...this.crudRequest.searchRequest.filter, text:  this.defaultSearch.nativeElement.value };
              this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
              first = false;
            }

          }
        }
        this.defaultSearch.nativeElement.value = this.crudRequest.searchRequest.filter.text || '';

      }
    }
    ));


    fromEvent(this.defaultSearch.nativeElement, 'keyup').pipe(
      // get value
      map((event: any) => {
        return event.target.value;
      })
      // Time in milliseconds between key events
      , debounceTime(300)
      // If previous query is diffent from current
      , distinctUntilChanged()
      // subscription for response
    ).subscribe((text: string) => {
      this.dispatchFilterChange(text)
    });
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  /*onPaste(context: ClipboardEvent) {

    const value: string | undefined = context.clipboardData?.getData('text');
    const textSearch: string = ASCIIFolder.foldReplacing(value);

    if (this.featureCode && this.crudRequest.searchRequest && this.crudRequest.searchRequest.filter) {
      if ((value !== this.crudRequest.searchRequest.filter.text)) {
        this.crudRequest.searchRequest.filter.text = textSearch;
        this.crudRequest.searchRequest.pagination = { page: 0, size: 20 };
        this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
      }
    }

  }*/

  // resetTable() {
  //   if (this.crudRequest.searchRequest && this.crudRequest.searchRequest.pagination && parseInt(this.crudRequest.searchRequest.pagination.page.toString())  > 0) {
  //     const valuePagination = {page: 0, size: 20};
  //     this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, valuePagination));
  //   }
  // }

  setFocus(b: boolean) {
    this.isFocus = b;
  }

  clearSearch(){
    this.defaultSearch.nativeElement.value = '';
    this.dispatchFilterChange("")
  }

}
