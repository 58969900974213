import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {RouterModule} from '@angular/router';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {MaterialModule} from '../material/material/material.module';
import {DialogComponent} from './components/dialog/dialog.component';
import {SnackDeleteComponent} from './components/snack-delete/snack-delete.component';
import {TranslateModule} from '@ngx-translate/core';
import {BackPageButtonComponent} from './components/back-page-button/back-page-button.component';
import {BackTopButtonComponent} from './components/back-top-button/back-top-button.component';
import {
  ColumnIconFieldSelectComponent
} from '../core/components/column-icon-field-select/column-icon-field-select.component';
import {SharedHttpInterceptor} from './interceptors/http.interceptors';
import {DialogService} from './services/dialog.service';
import {BrandService} from './services/brand.service';
import {CategoryService} from './services/category.service';
import {ConditionService} from './services/condition.service';
import {ExpansionService} from './services/expansion.service';
import {LanguageService} from './services/language.service';
import {RarityService} from './services/rarity.service';
import {WarehouseService} from './services/warehouse.service';
import {BreadcrumComponent} from './components/breadcrum/breadcrum.component';
import {GetRoleUserComponent} from './components/get-role-user/get-role-user.component';
import {
  IconsStructureSingleComponent
} from './components/columns/icons-structure-single/icons-structure-single.component';
import { FormsModule } from '@angular/forms';
import { ScrollComponentForComponent } from './components/scroll-component-for/scroll-component-for.component';
import { ColorService } from './services/color.service';
import { FrameService } from './services/frame.service';
import { DefaultSearchStockProductComponent } from './components/default-search-stock-product/default-search-stock-product.component';
import { OverlayModule } from '@angular/cdk/overlay';


@NgModule({
  declarations: [
    DialogComponent,
    SnackDeleteComponent,
    BackPageButtonComponent,
    BackTopButtonComponent,
    ColumnIconFieldSelectComponent,
    BreadcrumComponent,
    GetRoleUserComponent,
    IconsStructureSingleComponent,
    ScrollComponentForComponent,
    DefaultSearchStockProductComponent,
    
  ],

  imports: [
    // InfiniteScrollModule,
    CommonModule,
    RouterModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    TranslateModule,
    NgOptimizedImage,
    OverlayModule
    
  ],
  exports: [
    TranslateModule,
    ColumnIconFieldSelectComponent,
    RouterModule,
    HttpClientModule,
    GetRoleUserComponent,
    DialogComponent,
    BackTopButtonComponent,
    MaterialModule,
    BreadcrumComponent,
    DefaultSearchStockProductComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SharedHttpInterceptor,
      multi: true
    },
    DialogService,

  ],
})
export class SharedModule {
  constructor(private languageService: LanguageService,
              private warehouseService: WarehouseService,
              private brandService: BrandService,
              private colorService: ColorService,
              private categoryService: CategoryService,
              private conditionService: ConditionService,
              private expansionService: ExpansionService,
              private frameService: FrameService,
              private rarityService: RarityService) {
  }
}
