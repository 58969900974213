

<!-- for disabled : || ( currSession && currSession.status && (currSession.status.id == statusSession.APPROVED))-->
<button matTooltip="Cancella e ricrea" color="primary" mat-icon-button (click)="goEdit(); $event.preventDefault(); "
  [disabled]=" (currSession?.status?.id > 3 ) ||  data.item?.status == 3 ">

  <mat-icon>
    exposure
  </mat-icon>

</button>