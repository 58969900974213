import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {isArray, isObject} from 'lodash';
import {ControlValueAccessorBaseImpl} from 'src/app/core/components/control-value/ControlValueAccessorBaseImpl';
import {Entity, ProductStructureField, Brand, Product} from 'src/app/shared/models/entities';
import {StructureFieldType, StructureDataFilter} from 'src/app/structure/models/Structure';

export const STRUCTURE_LOOKUP_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StructureFieldLookUpComponent),
  multi: true
};

@Component({
  selector: 'app-structure-field-look-up',
  templateUrl: './structure-field-look-up.component.html',
  styleUrls: ['./structure-field-look-up.component.scss'],
  providers: [STRUCTURE_LOOKUP_CONTROL_VALUE_ACCESSOR]
})
export class StructureFieldLookUpComponent extends ControlValueAccessorBaseImpl<Entity> implements StructureFieldType, OnInit, OnChanges {

  @Input()
  structureField!: ProductStructureField;

  @Input()
  structureDataFilter!: StructureDataFilter;

  @Input()
  modifyData!: CallableFunction;

  @Input()
  featureCode: string | undefined;

  @Input()
  mult!: boolean

  @Input() colorIs: string | undefined;


  get productValue(): any {

    return this.structureDataFilter && this.structureDataFilter.productSearch &&
    this.structureDataFilter.productSearch.product ? ((this.structureDataFilter.productSearch.product as {
      [key: string]: any
    })[(this.structureField.field as string)]) : null;
  }

  set productValue(val: any) {

  }


  brands: Brand[] = [];
  permittedValues: any[] = [];

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    this.loadFilters();
  }

  override ngOnInit(): void {
    this.loadFilters();
  }

  private loadFilters() {
    if (this.structureDataFilter && this.structureDataFilter.brand) {
      this.brands = this.structureDataFilter.brand || [];
    } else {
      this.brands = [];
    }

    this.permittedValues = [];
    if (this.structureField) {
      let fieldValues = this.structureField.values;
      if (fieldValues && !isArray(fieldValues)) {
        fieldValues = JSON.parse(fieldValues as string);
        if (!isArray(fieldValues)) {
          fieldValues = '';
        } else {
          this.permittedValues = fieldValues as [];
        }
      }
    }
  }

  lookupFilter: CallableFunction = (item: any) => {
    return this.brandFilter(item) && this.valuesFilter(item);
  }

  private brandFilter: CallableFunction = (item: any) => {
    if (this.brands.length === 0) {
      return true;
    }
    if (!isObject(item)) {
      return true;
    }
    if ((item as any).brandId) {
      return this.brands && this.brands.length > 0 ? this.brands.findIndex(b => b.id === (item as any).brandId) !== -1 : (this.brands as Brand).id == (item as any).brandId;
    }

    return true;
  }

  private valuesFilter: CallableFunction = (item: any) => {
    if (this.permittedValues.length === 0) {
      return true;
    }
    if (!isObject(item)) {
      return true;
    }
    if ((item as any).id) {
      return this.permittedValues.findIndex(v => v == (item as any).id) !== -1;
    }

    return true;
  }

}
