import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { ASCIIFolder, Product, Stock } from '../../models/entities';
import { SharedService } from '../../services/shared.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Store, select } from '@ngrx/store';
import { State, selectCurrentDynaData } from 'src/app/store/shared/shared.reducers';
import { Observable, debounceTime, distinctUntilChanged, fromEvent, map, switchMap } from 'rxjs';
import { DynaCrudService, DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { DynacrudApi, OperationType, SearchRequest } from 'src/app/core/models/dynacrud';

@Component({
  selector: 'app-default-search-stock-product',
  templateUrl: './default-search-stock-product.component.html',
  styleUrls: ['./default-search-stock-product.component.scss']
})
export class DefaultSearchStockProductComponent implements OnInit {
  valueProducts: {[k: string] : Product | undefined} = {};
  loading = false;
  @ViewChild('searchStock') searchStock!: ElementRef;
  productResult: Product[] = [];
  isOpen: { [key: string]: any } = {};
  showImg = false;
  private subs = new SubSink();
  
  stockService: DynaCrudService<Stock>;

  constructor(private sharedService: SharedService, private store: Store<State>, private dynacrudApiWrapper: DynacrudApiWrapper) {
    this.stockService = this.dynacrudApiWrapper.getFor('product');
  }

  @Input() isVisible?: boolean;

  code: string = '';
  
  ngOnInit(): void {
    this.subs.add(this.store.pipe(select(selectCurrentDynaData), distinctUntilChanged(), debounceTime(0)).subscribe(request => {

      console.log('request', request);

      if (request.route && request.route.code) {
        this.code = request.route.code;

        this.valueProducts[this.code] = request.request?.customData?.product || undefined;
      }

      console.log('valueProducts', this.valueProducts);

    }));
  }

  // add hostlistener
  
  @HostListener('document:keyup.esc', ['$event'])
  onDocumentClick(event: MouseEvent) {
    if ((this.valueProducts && this.valueProducts[this.code]?.id) || (this.valueProducts && this.valueProducts[this.code]?.idMetaProduct) ) {
      this.clearSelect();
    }
  }


  ngAfterViewInit(): void {
    if (!this.isVisible){
      return;
    }

    fromEvent(
      this.searchStock.nativeElement, 'keyup'
    ).pipe(
      map((e: any) => {
        return e.target.value || '';
      }),
      distinctUntilChanged(),
      debounceTime(300),
      switchMap((searchText: string) => {
        this.loading = true;
        searchText = ASCIIFolder.foldReplacing(searchText);
        if (searchText && searchText.length > 2) {
          return this.stockService?.search({ ...this.defaultSearchProduct(searchText) }).pipe(/**gestione error */)
        }

        return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
      })
    ).subscribe((res: any) => {
      this.loading = false;
      const d = res as DynacrudApi<Product[]>;

      this.productResult = d.data || [];

    });
  }

  defaultSearchProduct(t: string): SearchRequest {

    return {
      filter: {
        filterBy: {
          operation: OperationType.AND,
          filters: [
            { field: 'obsolete', operation: OperationType.EQ, value: false },
          ]
        }, text: t
      }, pagination: { page: 0, size: 20 }, sort: []
    };

  }

  clearSelect() {
    this.searchStock.nativeElement.value = '';
    this.valueProducts[this.code] = undefined;
    this.sharedService.dataProductHeader( {product: this.valueProducts, clear: true} );
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showImg = false;
    this.isOpen = {};
  }

  selectProduct(prod: Product | null) {
    if (prod) {
      this.searchStock.nativeElement.value = prod.name + (prod?.expansion && prod?.expansion?.id ? (' ' + prod.expansion?.name) : '');
      this.valueProducts[this.code] = prod;
      // add to gestione
    } else {
      this.searchStock.nativeElement.value = '';
      this.valueProducts[this.code] = undefined;
    }

    console.log('ha pushato');

    this.sharedService.dataProductHeader( {product: this.valueProducts, clear: false} );
  }

  noImage(value: Product): void {
    value.imageName = environment.DefaultImageMagic || '';
  }

  hoverProduct(e: any, product: any) {
    this.isOpen = {};
    this.isOpen[product.id] = !this.isOpen[product.id];
    e.stopPropagation();
  }

}
