import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Subject, debounceTime, distinctUntilChanged, catchError, throwError } from 'rxjs';
import { MaterialModule } from 'src/app/material/material/material.module';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DynaCrudRequest, CurrentDynaData, selectCurrentDynaData, RowSelect, DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { DynacrudApiWrapper, DynaCrudService } from '../../api/dynacrud-api';
import { TableEvent } from '../../models/table-config';
import { ActionData, TableActionComponent } from '../table/Actions';

@Component({
  selector: 'app-delete-row',
  templateUrl: './delete-row.component.html',
  styleUrls: ['./delete-row.component.scss'],
  imports: [MaterialModule, TranslateModule, CommonModule],
  standalone: true
})
export class DeleteRowComponent implements TableActionComponent, OnInit, OnDestroy {

  constructor(private dialog: DialogService, private dynacrudService: DynacrudApiWrapper, private store: Store<State>) { }
  private dynaCrudRequestSub: Subject<DynaCrudRequest> = new Subject<DynaCrudRequest>();
  private lastDynaCrudRequest!: DynaCrudRequest;
  subs = new SubSink();
  toRow = false;

  routeCurrent!: CurrentDynaData;
  data!: ActionData;
  event?: EventEmitter<TableEvent>;
  private service!: DynaCrudService<any>;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    let first = true;
    this.subs.add(
      this.store.pipe(select(selectCurrentDynaData), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(currentDynaData => {
        if (currentDynaData && currentDynaData.route) {
          if (first) {
            first = false;
            this.init(currentDynaData);
          }
          const dynaCrudRequest: DynaCrudRequest = currentDynaData.request || { searchRequest: { filter: {} } };
          this.dynaCrudRequestSub.next(dynaCrudRequest);
        }
      })
    );
  }

  init(currentDynaData: CurrentDynaData) {
    const routeConf = currentDynaData?.route;
    this.routeCurrent = currentDynaData;
    if (routeConf) {
      this.service = this.dynacrudService.getFor(routeConf.featureType || routeConf.code || '');


    }

  }

  deletedRow(e: Event) {
    e.stopPropagation();
    const dataRow: RowSelect = { row: this.data.item, select: this.toRow = !this.toRow };
    this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, dataRow));

    const options = {
      title: `dialog.deleted_${this.routeCurrent?.route?.code}.title`,
      message: `dialog.deleted_${this.routeCurrent?.route?.code}.message_delete`,
      cancelText: `dialog.deleted_${this.routeCurrent?.route?.code}.cancel`,
      confirmText: `dialog.deleted_${this.routeCurrent?.route?.code}.confirm`
    };

    console.log('item', this.data.item);
    this.dialog.open(options);
    this.dialog.confirmed().subscribe(confirmed => {
      if (confirmed) {
        if (this.data.item.id) {
          this.subs.add(
            this.service.delete(this.data.item).pipe(catchError(err => {
              this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
              return throwError(err);
            }), debounceTime(environment.defaultDebounceTime)).subscribe((data) => {
              // this.dynaCrudRequestSub.next(this.lastDynaCrudRequest);
              this.dialog.openSnack(`msg.${this.routeCurrent?.route?.code}.deleteSuccess`, 'delete');
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
              this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));

            })
          );
        }
      } else {
        this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
      }
    });
    this.dialog.dialogRef.backdropClick().subscribe(data => {
      this.store.dispatch(new BaseAction(DynaConfActionType.RowSelect, false));
    });
  }

}
