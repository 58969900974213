<mat-toolbar class="header-cell__">

  <mat-toolbar-row>
    <div class="d-flex w-100 justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <button mat-icon-button (click)="toogleSideNav()" id="menu-toggler">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button routerLink="/" id="home">
          <mat-icon>home</mat-icon>
        </button>
        <app-title [isEditTitle]="edita"></app-title>
      </div>

      <div class="d-flex align-items-center">
        <div class=" searchGenerale noHint ">


          <app-layout-position position="searchProductHeader"></app-layout-position>


          <!-- <app-default-search-stock-product [isVisible]="isSearchProductVisible"></app-default-search-stock-product> -->
<!-- 
          <mat-form-field>
            <mat-label> {{ valueProducts && valueProducts.id ? (valueProducts.name + ' (' +
              valueProducts.expansion.enName + ')') : 'Search products'}} </mat-label>
            <input [disabled]="valueProducts && valueProducts.id" type="text" matInput name="searchProducts"
              #searchStock [matAutocomplete]="auto">

            <button mat-icon-button matSuffix *ngIf="!loading" (click)="clearSelect()">
              <mat-icon>
                clear
              </mat-icon>
            </button>

            <div matSuffix class="p-1" *ngIf="loading">
              <mat-spinner diameter="40"></mat-spinner>
            </div>

            <mat-autocomplete (optionSelected)="selectProduct($event.option.value)" #auto="matAutocomplete">
              <mat-option *ngFor="let option of productResult" [value]="option">
                <div class="d-flex justify-content-between gap-2">
                  <div class="d-flex">
                    <img [alt]="option?.name || ('default image with id' + option.id)" [src]="option.imageName"
                      #trigger="cdkOverlayOrigin" cdkOverlayOrigin width="24" (error)="noImage(option)"
                      (mouseover)="hoverProduct($event, option)" (mouseleave)="onMouseLeave()">

                    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
                      [cdkConnectedOverlayOpen]="isOpen[option.id]">

                      <div class="d-flex panel_onfire_ow justify-content-center flex-column align-items-center w-100">
                        <div class="d-flex w-100">
                          <img [src]="option.imageName" (error)="noImage(option)" [alt]="option.imageName" width="200">
                        </div>
                      </div>
                    </ng-template>

                  </div>
                  <div class="d-flex">
                    {{option.name}}
                  </div>
                </div>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->

        </div>
        <app-add-session-movement></app-add-session-movement>
        <app-layout-position position="search"></app-layout-position>
        <button matTooltip="{{username}}" class="custom_btn_" mat-button [matMenuTriggerFor]="menu"
          id="user-dropdown-toggler">
          <mat-icon>account_circle</mat-icon>
          <span class="nome_email">
            {{username}}
          </span>
        </button>

        <mat-menu #menu="matMenu" x-position="before">
          <button mat-menu-item disabled> Refresh</button>
          <button mat-menu-item disabled> Profilo</button>
          <button mat-menu-item (click)="logout()"> Logout</button>
        </mat-menu>
      </div>
    </div>
  </mat-toolbar-row>
  <!--mat-toolbar-row>
  <small>FIXME K3 qui vorrei un array di tollbar dinamiche per le azioni disponibile sul record selezionato ... selezione multipla ecc... ordinamenti , indicatori di filtro</small>
</mat-toolbar-row-->

</mat-toolbar>