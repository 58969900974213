<!--p>{{data?.item | json }}}</p-->
<ng-container *ngIf="!saving">


  <ng-container *ngIf="objectRoles && objectRoles[USER_ROLES_.admin]">

    <button mat-icon-button [matTooltip]="'session.action.fast_approve' | translate"
      *ngIf="session?.status?.id == statuses.OPEN" (click)="changeStatus($event, statuses.APPROVED)">
      <mat-icon>fact_check</mat-icon>
    </button>

  </ng-container>

  <button mat-icon-button [matTooltip]="'session.action.waiting_approval' | translate"
    *ngIf="session?.status?.id == statuses.OPEN" (click)="changeStatus($event, statuses.NOT_APPROVED)">
    <mat-icon>add_task</mat-icon>
  </button>
  <button mat-icon-button [matTooltip]="'session.action.reopen' | translate"
    *ngIf="session?.status?.id == statuses.NOT_APPROVED" (click)="changeStatus($event, statuses.OPEN);">
    <mat-icon>unpublished</mat-icon>
  </button>


  <button mat-icon-button [matTooltip]="'session.action.approve' | translate"
    *ngIf="session?.status?.id == statuses.NOT_APPROVED" (click)="changeStatus($event, statuses.APPROVED)">
    <mat-icon>add_task</mat-icon>
  </button>

</ng-container>
<div matSuffix color="warning" *ngIf="saving">
  <mat-spinner diameter="20"></mat-spinner>
</div>