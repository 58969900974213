import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import jwtDecode from 'jwt-decode';
import { Observable, tap } from 'rxjs';
import { AuthActionType, State, selectIsAutenticated } from 'src/app/store/auth/auth.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { Auth } from '../models/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private URL = `${environment.apiUrl}/v2/login`;

  constructor(private httpClient: HttpClient, private store: Store<State>, private router: Router) { }

  // isLog(): Observable<boolean> {
    
  //   return this.store.pipe(select(selectIsAutenticated));
  // }


  login(username: string, password: string): Observable<any> {
    const body = {username, password};
    const bodyTocoockies = JSON.stringify(body);

    localStorage.setItem('paramsToauthgAlactus', bodyTocoockies);


    // mi serve avere un controllo. 
    document.cookie = `paramsToauthgAlactus=${bodyTocoockies}`;

    return this.httpClient.post(this.URL, body).pipe(
      tap((res : any) => {
        const decodedJWT = jwtDecode(res.token) as any;
        const authResp: Auth = {
          isAuthenticated: true,
          username: decodedJWT.username || decodedJWT.email,
          roles: decodedJWT.roles || [],
          token: {
            type: res.type,
            value: res.token
          }
        } ;

        if ( authResp.roles) {
          authResp.roles = authResp.roles.map(r => r.toLowerCase().replace('role_', ''));
        }
        sessionStorage.setItem(environment.appVersion + '_auth', JSON.stringify(authResp));
        this.store.dispatch(new BaseAction(AuthActionType.FULL, authResp ));
      })
    );
  }

  logout() {


    localStorage.removeItem('paramsToauthgAlactus');
    localStorage.removeItem('credentialsDate');
    localStorage.removeItem('mantieni');

    
    sessionStorage.removeItem(environment.appVersion + '_auth');

    localStorage.removeItem(environment.appVersion + '_auth');
    this.store.dispatch(new BaseAction(AuthActionType.FULL, {} ));
    this.router.navigateByUrl('/auth').then();
  
  }

}
