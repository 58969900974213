import { createFeatureSelector, createSelector, MemoizedSelector } from "@ngrx/store";
import { Customer, OrderSummary } from "src/app/features/shop/models/entities";
import { Order } from "src/app/shared/models/entities";
import { BaseAction } from "../store.actions";
import * as rootReducers from '../store.reducers';

export interface  CartSummary {
  amount: number | undefined;
  items: number | undefined;
  disable: boolean | undefined;
}


export interface Cart {
  order?: Order; // order completo
  customer?: Customer; // cliente
  summary?: CartSummary; // riepilogo per carrello-component
  // cartSummaryDetail?: number;
}

export interface Shop {
  requestCartId: any;
  cartLoading: boolean;
  order: Order | null;
  currentCart: Cart | null;
  cartOrderSummary: OrderSummary | null;
}

export interface State extends rootReducers.State {
  shop: Shop;
}

const initialState: Shop = {
  requestCartId: null,
  cartLoading: false,
  order: null,
  currentCart: {} || null,
  cartOrderSummary: null
};

export enum ShopActionType {
  CartSelect = '[Shop] CartSelect',
  CartLoaded= '[Shop] CartLoaded',
  CartChange= '[Shop] CartChange',
}

export function shopReducer(state: Shop = initialState, action: BaseAction): Shop {
  switch (action.type) {
    case ShopActionType.CartSelect: {
      return {
        ...state,
        requestCartId: action.payload,
        cartLoading: true,
        currentCart: null
      } ;
    }
    case ShopActionType.CartLoaded: {

      return {
        ...state,  order: action.payload
      } ;

    }
    case ShopActionType.CartChange: {

      return {
        ...state,  currentCart: action.payload, cartLoading: false
      } ;

    }
    default:
      return state;
  }

}

export const FEATURE_NAME = 'shop';
export const selectShop = createFeatureSelector<State, Shop>(
    FEATURE_NAME
);

export const selectRequestCartId: MemoizedSelector<State, any>  = createSelector(selectShop, (state: Shop) => state.requestCartId);
export const selectCurrentCart: MemoizedSelector<State, Cart | null>  = createSelector(selectShop, (state: Shop) => state.currentCart);
export const selectCartLoading: MemoizedSelector<State, boolean>  = createSelector(selectShop, (state: Shop) => state.cartLoading);
export const selectCurrentOrder: MemoizedSelector<State, Order | undefined>  = createSelector(selectCurrentCart, (state: Cart | null) => state?.order);
export const selectCurrentCustomer: MemoizedSelector<State, Customer | undefined>  = createSelector(selectCurrentCart, (state: Cart | null) => state?.customer);