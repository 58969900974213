import { Component, Directive, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { debounceTime, map } from 'rxjs';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';
import { SessionService } from 'src/app/shared/services/session.service';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-purchase-price-session-column',
  templateUrl: './purchase-price-session-column.component.html',
  styleUrls: ['./purchase-price-session-column.component.scss']
})
export class PurchasePriceSessionColumnComponent implements ColumnFieldComponent, OnInit, OnDestroy {
  data!: ColumnData;
  row: any = {};
  applyPriceGenerated : {[k: string]: any} = {};
  private subs = new SubSink();
  @ViewChild('myInputElement') myInputElement: any;
  isEdit= true;
  idSession : number | undefined;
  forzaRoma = false;
  priceToEditValue: number | undefined;
  purchaseValueDefault : number | undefined;

  constructor(private sessionService: SessionService,
    private activateRouter: ActivatedRoute,
    private store: Store<State>) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  

  ngOnInit(): void {
    this.row = this.data.row;
    this.forzaRoma = this.row?.warehouseSession?.systemSession ? true : false;
    this.priceToEditValue = this.row.purchasePrice != undefined ?  this.row.purchasePrice : undefined;
    this.applyPriceGenerated['active'] = this.row.purchasePriceGenerated != undefined ?  this.row.purchasePriceGenerated : null;
    this.purchaseValueDefault = this.row?.purchasePrice != undefined ? this.row?.purchasePrice : undefined;

    this.subs.add(
      this.activateRouter.params.pipe().subscribe(d => {
        this.idSession = +d['id'] || undefined;
      })
    );
  
  }

  
  
  public get priceToEdit() : number | undefined {
    return this.priceToEditValue;
  }

  
  public set priceToEdit(v : number | undefined) {
    this.priceToEditValue = v;
  }


  save() {
    const inputValue = (this.myInputElement.nativeElement as HTMLInputElement).value;

    const idMovement = this.row.id;
    const idSession = this.idSession;
    const purchase = inputValue;

    if (idSession) {
      this.sessionService.savePurchasePrice(idSession, idMovement, purchase).pipe(debounceTime(50), map(a => a.data)).subscribe(res => {

        if (res) {

          this.purchaseValueDefault = res.purchasePrice;
          this.row.purchasePrice = this.purchaseValueDefault;
          this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
          this.isEdit = true;

        }

      
      });

    }


  }

  isMovement(): boolean {

    return ((this.row && this.row.nextMovement && this.row.nextMovement.id)
    || (this.row && this.row.previousMovement && this.row.previousMovement.id)
    || (this.row.quantity != undefined && this.row.quantity < 0) );

  }

  limitarDecimales(event: any) {
    
    event.target.value = event.target.value.replace(/,/g, '.');
    const inputValue: string = event.target.value;
    const regex: RegExp = /^\d+(?:[.,]\d{0,2})?$/;
    

    if (!regex.test(inputValue)) {
      event.target.value = inputValue.slice(0, -1); // Elimina el último carácter no válido
    }

    this.priceToEditValue =  event.target.value;
  }
  
}


@Directive({
  selector: '[appTwoDecimalPlaces]'
})
export class TwoDecimalPlacesDirective {

  constructor(private el: ElementRef) {}

  

  @HostListener('input', ['$event']) onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Elimina todos los caracteres no numéricos y puntos adicionales después de dos decimales
    value = value.replace(/[^\d.]/g, ''); // Elimina caracteres no numéricos excepto puntos
    const decimalPartsPunto = value.split('.')
    if (decimalPartsPunto.length > 1) {
      const integerPart = decimalPartsPunto[0];
      const decimalPart = decimalPartsPunto[1].substring(0, 2);
      value = integerPart + '.' + decimalPart;
    }

    const decimalPartsComa = value.split(',')
    if (decimalPartsComa.length > 1) {
      const integerPart = decimalPartsComa[0];
      const decimalPart = decimalPartsComa[1].substring(0, 2);
      value = integerPart + ',' + decimalPart;
    }

    // Asigna el valor formateado de vuelta al campo de entrada
    input.value = value;
  
  
  }
}
