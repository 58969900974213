import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { catchError, throwError } from 'rxjs';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { DynacrudApi, USER_ROLES } from 'src/app/core/models/dynacrud';
import { WarehouseSession, WarehouseSessionStatus } from 'src/app/shared/models/entities';
import { WarehouseSessionStatuses } from '../../../models/entities';
import { WarehouseSessionService } from '../../../services/warehouse-session.service';
import { GetRoleUserComponent } from 'src/app/shared/components/get-role-user/get-role-user.component';
import { Store } from '@ngrx/store';
import { State } from 'src/app/store/auth/auth.reducers';

@Component({
  selector: 'app-session-status-action',
  templateUrl: './session-status-action.component.html',
  styleUrls: ['./session-status-action.component.scss']
})
export class SessionStatusActionComponent  implements TableActionComponent {

  @Input()
  data!: ActionData;
  saving = false;

  USER_ROLES_ = USER_ROLES;
  objectRoles: {[key: string]: any}= {};

  public getRl = new GetRoleUserComponent(this.storeOther)

  get session(): WarehouseSession {
    return this.data?.item as WarehouseSession;
  }

  get statuses() {
    return WarehouseSessionStatuses;
  }

  constructor(private warehouseSessionService: WarehouseSessionService, private storeOther: Store<State>) {
    const inst = this.getRl;
    inst.ngOnInit();
    this.objectRoles = inst.authRole;
  }

  changeStatus(event: Event, status: number) {
    event.stopPropagation();
    this.saving = true;
    this.warehouseSessionService.changeStatus(this.session.id, status).pipe(
        catchError(err => {
          this.saving = false;
          return throwError(err);
        })
    ).subscribe( (ret: DynacrudApi<WarehouseSessionStatus>) => {
      this.data.item = _.merge(this.data.item, { status: ret.data});
      this.saving = false;
    });


  }

}
