<ng-container *ngIf="data && data.row">
    <div (click)="openTo(); $event.preventDefault();" [ngClass]="{'hove' : aSession != undefined}" class="container-desc gap-2"  [matTooltip]="data.row.description" matTooltipClass="description-tooltip"
        matTooltipPosition="above">

        <mat-icon color="primary" class="w-50">
            {{data.row.sessionId ? 'list' : data.row.orderId ? 'shopping_cart' : null}}
        </mat-icon>

        <span class="description__">
            {{data.row.description}}
        </span>

    </div>
</ng-container>