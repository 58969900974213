import { AfterViewInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorBaseImpl } from 'src/app/core/components/control-value/ControlValueAccessorBaseImpl';
import { ProductStructureField } from 'src/app/shared/models/entities';
import { StructureDataFilter, StructureFieldType } from 'src/app/structure/models/Structure';

export const STRUCTURE_FLAG_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StructureFieldFlagComponent),
  multi: true
};

@Component({
  selector: 'app-structure-field-flag',
  templateUrl: './structure-field-flag.component.html',
  styleUrls: ['./structure-field-flag.component.scss'],
  providers: [STRUCTURE_FLAG_CONTROL_VALUE_ACCESSOR]
})
export class StructureFieldFlagComponent extends ControlValueAccessorBaseImpl<boolean> implements StructureFieldType, OnInit {

  @Input()
  structureField!: ProductStructureField;

  @Input()
  structureDataFilter!: StructureDataFilter;

  @Input() 
  featureCode : string | undefined;


  constructor() {
    super();
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.structureDataFilter != undefined) {

      if (this.structureDataFilter.productSearch?.product) {
        setTimeout(() => {
          super.writeValue((this.structureDataFilter.productSearch?.product as { [key: string]: any })[this.structureField.field as string]);
        }, 50);
      } else {
        super.writeValue((this.structureDataFilter as { [key: string]: any })[this.structureField.field as string]);
      }
      
    }

  }


}
