import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { Filter, OperationType, Sort } from 'src/app/core/models/dynacrud';
import { DynaConfActionType, DynaCrudRequest, State, selectDynaCrudRequest } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { Motivation } from '../../../models/entities';
import { SessionWrapperService } from '../../../services/session-wrapper.service';
import { isNullOrUndefined } from 'is-what';

@Component({
  selector: 'app-session-wrappers-filter',
  templateUrl: './session-wrappers-filter.component.html',
  styleUrls: ['./session-wrappers-filter.component.scss']
})
export class SessionWrappersFilterComponent implements OnInit {

  private subs = new SubSink();

  filter: any = { motivation: undefined };

  private crudRequest: DynaCrudRequest = {
    searchRequest: {},
    customData: this.filter
  };

  sortItems: Sort[] | undefined = [];

  constructor(private store: Store<State>, private sessionWrappService: SessionWrapperService) { }

  ngOnInit(): void {


    this.sessionWrappService.getMotivations().pipe(debounceTime(50), map(m => m.data)).subscribe(mot => {
      this.motivations = mot;


    })

    let first = true;
    this.subs.add(this.store.pipe(select(selectDynaCrudRequest), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(request => {

      this.crudRequest = request ? { ...request } : this.crudRequest;
      this.filter = this.crudRequest.customData || this.filter;

      if (this.crudRequest && this.crudRequest.searchRequest) {
        this.sortItems = this.crudRequest.searchRequest.sort;
      }

      if (first) {
        first = false;
        this.searchMotivation();
      }

    }
    ));
  }

  get motivation(): Motivation | undefined {
    return this.filter.motivation;
  }

  set motivation(v: Motivation | undefined) {
    this.filter.motivation = v;
  }

  motivations: Motivation[] = [];

  searchMotivation() {

    const possibleFilters: Filter[] = [{ field: 'obsolete', operation: OperationType.EQ, value: 'false' }];



    if (this.motivation && this.motivation.name) {
      possibleFilters.push({ field: 'motivation', operation: OperationType.EQ, value: this.motivation.name });
    }

    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }

    this.crudRequest.customData = { ...this.filter };
    this.crudRequest.searchRequest = { filter: { filterBy: filterByObj, text: this.crudRequest.searchRequest?.filter?.text || '' }, pagination: { page: 0, size: 20, active: true } };
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

  }


  public compareEntityName(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.name === o2.name;
  }

}
