<ng-container *ngIf="structureReady">
  <form name="form-cart" #f="ngForm" class="full__form  pt-3  " (keyup.enter)="animatedButton = true; search($event)">

    <div class="search">
      <app-product-double-search (clearProduct)="getBrandCategory($event)" name="productDouble2" #productDouble2
                                 id="productDouble2" [(ngModel)]="productSearch"
                                 [structureDataFilter]="filter"></app-product-double-search>
    </div>
    <div class="showSelects d-flex gap-1 flex-wrap">

      <!--SISTEMATIZAR FIXME SANTIAGO-->
      <ng-container *ngIf="hasProduct() ">

        <app-default-select class="itemShow" [readonly]="true" name="category" [filterBy]="categoryFilter"
                            [(ngModel)]="productCategory" label="category" [multiple]="false"
                            source="category"></app-default-select>

        <app-default-select class="itemShow" [readonly]="true" name="brand"
                            [(ngModel)]="productBrand" label="brand" [multiple]="false"
                            source="brand"></app-default-select>

        <mat-form-field class="field_num">
          <mat-label>{{'numero' | translate}}</mat-label>
          <input type="number" matInput [(ngModel)]="numberProduct" #numberPduct="ngModel" name="numeroProduct"
                 [disabled]="hasProduct()">
          <mat-error *ngIf="numberPduct.errors">{{'order_product.hint_error' | translate}}</mat-error>
        </mat-form-field>

      </ng-container>

      <ng-container *ngIf="!hasProduct()">

        <app-default-select class="itemShow" [disabled]="hasProduct()" name="category" [(ngModel)]="categories"
                            [filterBy]="categoryFilter" label="category" [multiple]="false"
                            source="category"></app-default-select>

        <app-default-select class="itemShow" [disabled]="hasProduct()" name="brand" [(ngModel)]="brands" label="brand"
                            [multiple]="false" source="brand" [filterBy]="brandFilter"></app-default-select>
      </ng-container>

      <ng-container *ngIf="validBrand() ">

        <mat-form-field class="field_num">
          <mat-label>{{'order_product.min' | translate}}</mat-label>
          <input type="number" matInput [(ngModel)]="filter['minNumber']" #minOrder="ngModel" name="productNumberMin"
                 [disabled]="hasProduct()" min="0"
                 [max]="filter['maxNumber'] != undefined && filter['maxNumber'] != null ? isMax(filter['maxNumber'] || '0') : null"
                 maxlength="20">
          <mat-error *ngIf="minOrder.errors">{{'order_product.hint_error' | translate}}</mat-error>
        </mat-form-field>

        <mat-form-field class="field_num">
          <mat-label>{{'order_product.max' | translate}}</mat-label>
          <input type="number" [(ngModel)]="filter['maxNumber']" matInput name="productNumberMax" #maxOrder="ngModel"
                 [disabled]=" hasProduct()" min="0" maxlength="20">
          <mat-error *ngIf="maxOrder.errors">{{'order_product.hint_error' | translate}}</mat-error>
        </mat-form-field>
      </ng-container>


      <ng-container *ngIf="productStructure && productStructure.fields && productStructure.fields.length > 0 ">
        <ng-container *ngFor="let field of productStructure.fields">
          <ng-container *ngIf="field && field.field ">

            <app-structure-field [disabled]="hasProduct()" [name]="field.field" [(ngModel)]="filter[field.field]"
                                 [structureField]="field" [structureDataFilter]="filter"></app-structure-field>
          </ng-container>
        </ng-container>
      </ng-container>


    </div>

    <!--buttons-->

    <div class="row-button" style="padding: 10px">

      <div class="d-flex">
        <button mat-raised-button color="accent" [disabled]="!sessionId"
                (click)="goSession(); $event.preventDefault(); ">

          Go to session

        </button>

      </div>

      <div class="d-flex">
        <button class="button-search" mat-raised-button color="warn" [disabled]="!filter.productStructure" (click)=" clearSort(true); ">
          Clear sort
        </button>

        <button class="button-search" mat-raised-button color="warn" [disabled]="!filter.productStructure" (click)=" selectsReset(f); ">
          Reset
        </button>

        <button [disabled]="(productSearch && productSearch.err) || !filter.productStructure || false  "
                [ngClass]="{'button-search': true, 'animatedButton_all' : animatedButton}" mat-raised-button
                color="primary"
                (click)=" animatedButton = true; search($event)">
          Search
          <mat-icon class="iconSearch">search</mat-icon>
        </button>
      </div>
    </div>

    <div *ngIf="animatedButton" class="yugiContain">
      <span class="text_duel">TIME TO DUEL</span>
      <img [src]="'https://i.giphy.com/media/vZRdMe89bFkTm/giphy.webp'" class="yugiItem" width="200" height="100"
           alt="time to duel">
    </div>
  </form>
</ng-container>
