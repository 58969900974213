import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';

@Component({
  selector: 'app-description-tooltip',
  templateUrl: './description-tooltip.component.html',
  styleUrls: ['./description-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DescriptionTooltipComponent implements ColumnFieldComponent, OnInit {
  data!: ColumnData;
  aSession: any;
  constructor(private router: Router) { }
  ngOnInit(): void {
    this.aSession = this.data.row && this.data.row.sessionId ? true : this.data.row && this.data.row.orderId ? false : undefined;

  }
  openTo() {
    if (this.aSession != undefined) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([this.aSession ? `app/warehouse/session/${this.data.row.sessionId}` :
        this.data.row?.marketplaceOrder ?  `app/marketplaces/orders/${this.data.row.orderId}` : `app/shop/checkout/${this.data.row.orderId}`])
      );
      window.open(url);
    }
  }
}
