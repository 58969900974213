import { routerReducer, RouterReducerState } from "@ngrx/router-store";
import { Action, ActionReducerMap, createFeatureSelector, MemoizedSelector, MetaReducer } from "@ngrx/store";
import { Auth } from "../auth/models/auth";
import { Layout } from "../layout/models/layout-models";
import { authReducer } from "./auth/auth.reducers";
import { layoutReducer } from "./layout/layout.reducers";
import { RouterStateUrl } from "./router/router.state";
import { DynaConf, dynaConfReducer } from "./shared/shared.reducers";
import { Shop, shopReducer } from "./shop/shop.reducers";
import { storageMetaReducer } from "./store.metareducers";







export const reducers: ActionReducerMap<State | unknown> =  {

  layout: layoutReducer,
  router: routerReducer,
  auth: authReducer,
  conf: dynaConfReducer,
  shop: shopReducer,
  
// settings: settingsReducer,
};


export const metaReducers: MetaReducer<any>[] = [storageMetaReducer];

export const selectRouterState: MemoizedSelector<State, RouterReducerState<RouterStateUrl>> = createFeatureSelector<
  State,
  RouterReducerState<RouterStateUrl>
  >('router');


export interface State {
  auth: Auth;
  conf: DynaConf;
  shop: Shop;
  layout: Layout;
  router: RouterReducerState<RouterStateUrl> | RouterReducerState<any>;
  // settings: SettingsState;
}

