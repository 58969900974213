import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { ControlValueAccessorBaseImpl } from 'src/app/core/components/control-value/ControlValueAccessorBaseImpl';
import { ProductSearch, StructureDataFilter } from 'src/app/structure/models/Structure';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ASCIIFolder, Brand, Category, Product, ProductStructure, ProductStructureFieldType } from 'src/app/shared/models/entities';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DynacrudApiWrapper, DynaCrudService } from 'src/app/core/api/dynacrud-api';
import { catchError, debounceTime, distinctUntilChanged, fromEvent, map, Observable, of, switchMap } from 'rxjs';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DynacrudApi, Filter, OperationType } from 'src/app/core/models/dynacrud';
import { isArray } from 'lodash';
import { environment } from 'src/environments/environment';
import _ from 'lodash';

export const PRODUCT_DOUBLE_SEARCH_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ProductDoubleSearchComponent),
  multi: true
};


@Component({
  selector: 'app-product-double-search',
  templateUrl: './product-double-search.component.html',
  styleUrls: ['./product-double-search.component.scss'],
  providers: [PRODUCT_DOUBLE_SEARCH_CONTROL_VALUE_ACCESSOR],
  encapsulation: ViewEncapsulation.None
})
export class ProductDoubleSearchComponent extends ControlValueAccessorBaseImpl<ProductSearch> implements AfterContentInit, OnInit {

  loading = false;
  showImg = false;
  clicEventProduct = false;
  isOpen: { [key: string]: any } = {};

  @ViewChild('productSuggestInput', {static: true}) productSuggestInput!: ElementRef;
  @ViewChild('productsAutocompleteTrigger', {static: true}) productAutoCompletePanel!: MatAutocompleteTrigger;

  @Input()
  structureDataFilter!: StructureDataFilter;

  @Input()
  structureDataFilterAsync!: StructureDataFilter | null;

  productStructure!: ProductStructure;

  @Input()
  RequiredValue: boolean;

  @Input() noAll!: boolean

  @Input() newValueForClearProduct!: { messages: [], meta: null, data: [] };

  isClear = false;


  @Output() clearProduct = new EventEmitter<any | undefined>();

  private productsService: DynaCrudService<Product>;

  product$!: Observable<Product[]>;

  products: Product[] = [];

  constructor(dynacrudApiWrapper: DynacrudApiWrapper, private dialog: DialogService, private el: ElementRef) {
    super();
    this.productsService = dynacrudApiWrapper.getFor('products');
    this.RequiredValue = false;
  }


  @Input()
  changeValue!: boolean;

  getPositionSprite(i: any): { x: number | undefined, y: number | undefined } {
    if (i) {
      let x = this.getFloor((i % 10)) * 21;
      let y = this.getFloor((i / 10)) * 21;
      return {x: x, y: y};
    }
    return {x: undefined, y: undefined};
  }

  getProduct(event: any) {
    if (event) {
      this.clicEventProduct = true;
    }
  }

  getFloor(x: any) {
    return Math.floor(x);
  }


  hoverProduct(e: any, product: any) {
    this.isOpen = {};
    this.isOpen[product.id] = !this.isOpen[product.id];
    e.stopPropagation();
  }

  // clear button

  public clear() {

    this.selectProduct(null);
    this.productSuggestInput.nativeElement.value = '';
    this.productAutoCompletePanel.openPanel();

    this.clearProduct.emit(true);
  }

  // key esc

  @HostListener('document:keyup.esc', ['$event']) onEsc() {
    if (this.value) {
      this.earlyClear(this.value?.product);
      this.focus();
    }
  }

  private earlyClear(p: any) {
    this.selectProduct(p, true);
    this.productSuggestInput.nativeElement.value = '';
    this.clearProduct.emit('refresh');
  }

  hover() {
    this.showImg = true;
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.showImg = false;
    this.isOpen = {};
  }

  private cacheStructure: any = {};

  private loadSearchFilter(): Filter {
    const possibleFilters: Filter[] = [];
    const structFilter: StructureDataFilter = this.structureDataFilter;
    this.cacheStructure = _.cloneDeep(this.structureDataFilter);
    if ((structFilter.brand && structFilter.brand.length > 0)) {
      possibleFilters.push({ operation: OperationType.IN, field: 'brand.id', value: structFilter.brand.map(b => b.id) });
    } else if ((structFilter.brand as Brand)?.id) {
      const br = (structFilter.brand as Brand);
      possibleFilters.push({ operation: OperationType.IN, field: 'brand.id', value: [br.id] });
    }
    if (structFilter.category && structFilter.category.length > 0) {
      possibleFilters.push({ operation: OperationType.IN, field: 'category.id', value: structFilter.category.map(b => b.id) });

    } else if ((structFilter.category as any)?.id) {
      const cat = (structFilter.category as any);
      possibleFilters.push({ operation: OperationType.IN, field: 'category.id', value: [cat.id] });

    }

    if (this.noAll) {
      possibleFilters.push({ field: 'id', operation: OperationType.GT, value: 0 })
    }

    possibleFilters.push({field: 'obsolete', value: false, operation: OperationType.EQ});


    if (structFilter.productStructure && structFilter.productStructure.fields) {
      structFilter.productStructure.fields.forEach(f => {
        if (f) {
          if (structFilter[f.field || '']) {

            const fieldName = f.field + (f.type == ProductStructureFieldType.LOOKUP ? '.id' : '');

            if (isArray(structFilter[f.field || ''])) {
              if (structFilter[f.field || ''].length > 0) {
                possibleFilters.push({
                  operation: OperationType.IN,
                  field: fieldName,
                  value: structFilter[f.field || ''].map((b: any) => b.id)
                });
              }
            } else {
              possibleFilters.push({ operation: OperationType.IN, field: fieldName, value: structFilter[f.field || ''] });
            }
          }
        }

      });
    }
    // FIXME: HO CABLATO SESSION
    if (structFilter && structFilter['isSession']) {
      possibleFilters.push({ operation: OperationType.GT, field: 'id', value: '0' });
    }

    for (const f of possibleFilters) {
      if (f.operation == OperationType.IN) {
        const arrayValue = f.value as any[];
        if (arrayValue.length == 1) {
          f.operation = OperationType.EQ;
          f.value = arrayValue[0];
        }
      }
    }

    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = {operation: OperationType.AND, filters: possibleFilters};
      } else {
        filterByObj = possibleFilters[0];
      }
    }

    return filterByObj as Filter;

  }

  ngAfterContentInit(): void {
    setTimeout(() => {

      if (this.value) {
        this.brandCategory = { brand: this.value.product?.brand, category: this.value.product?.category };
      }

    }, 0);

    setTimeout(() => {
      if (this.value && this.value.product) {
        this.productSuggestInput.nativeElement.value = this.value.product.name + (this.value.product.expansion ? ' ' + this.value.product.expansion.name : '');
      } else {
        this.productSuggestInput.nativeElement.value = this.value?.text || '';
      }
    }, 0);
  }

  private focus() {
    setTimeout(() => {
      this.el.nativeElement.querySelector('.search_label_general')?.focus();
      // this.productAutoCompletePanel.openPanel();
    }, 0);
  }


  pasteValue(event: any) {

    this.loading = true;

    const value: string | undefined = event.clipboardData?.getData('text');

    const textSearch = ASCIIFolder.foldReplacing(value);

    new Observable<Product[]>(prodObs => {
      of(textSearch).pipe(

        debounceTime(300),
        distinctUntilChanged(),

        switchMap((textSearch: string) => {

          textSearch = ASCIIFolder.foldReplacing(textSearch);

          textSearch = textSearch.replace('\u200C', '');
          textSearch = textSearch.replace(/\u00a0/g, ' ');

          if (textSearch?.length > 0) {
            if (!textSearch.match(/^[A-z\u00C0-\u00ff\s'"+.,-\/#!$%^&*;:?1-9-0{}=\-_`~()]+$/)) {
              this.value = { text: textSearch.toString(), product: undefined, err: true };
              this.dialog.openSnack('controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return '';
            }
          } else {
            this.loading = true;
            this.value = {text: textSearch, product: undefined, err: false};
          }

          if (textSearch && textSearch.length > 2) {
            this.value = { text: textSearch.toString(), product: undefined, err: false, click: this.clicEventProduct };
            // this.loading = true;


            return this.productsService.search({
              filter: {
                text: textSearch.toString(),
                filterBy: this.loadSearchFilter()
              }
            }).pipe(catchError(() => {
              this.value = { text: textSearch.toString(), product: undefined, err: true };
              this.dialog.openSnack('controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
            }));


          } else {
            if (textSearch === '') {
              this.loading = false;
              return [];
            } else {
              return new Observable((obs) => obs.next({messages: [], meta: null, data: []}));
            }
          }

        })

      ).subscribe((api) => {


        if (api != undefined) {

          this.products = [...(api as DynacrudApi<Product[]>).data];

          if (this.structureDataFilter && this.structureDataFilter.productStructure &&
            this.cacheStructure && this.cacheStructure.productStructure &&
            (this.structureDataFilter.productStructure.id == this.cacheStructure.productStructure.id)) {

            this.productAutoCompletePanel.openPanel();

          }
          this.loading = false;
        }

      });

    }).subscribe();




  }




  override ngOnInit() {
    super.ngOnInit();
    if (!this.value) {
      this.value = undefined;
      this.clicEventProduct = false;
    }


    new Observable<Product[]>(prodObs => {
      fromEvent(this.productSuggestInput?.nativeElement, 'keyup').pipe(
        map((event: unknown) => {
          const myEvent: KeyboardEvent = event as KeyboardEvent;

          return (myEvent.target as any).value;

        }),
        // Time in milliseconds between key events
        debounceTime(300),
        distinctUntilChanged(),

        switchMap((textSearch: string) => {

          textSearch = ASCIIFolder.foldReplacing(textSearch);
          textSearch = textSearch.replace('\u200C', '');
          textSearch = textSearch.replace(/\u00a0/g, ' ');


          if (textSearch?.length > 0) {
            if (!textSearch.match(/^[A-z\u00C0-\u00ff\s'"+.,-\/#!$%^&*;:?1-9-0{}=\-_`~()]+$/)) {
              this.value = { text: textSearch.toString(), product: undefined, err: true };
              this.dialog.openSnack('controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return '';
            }
          } else {
            this.loading = true;
            this.value = { text: textSearch, product: undefined, err: false };
          }

          if (textSearch && textSearch.length > 2) {
            this.value = { text: textSearch.toString(), product: undefined, err: false, click: this.clicEventProduct };

            return this.productsService.search({
              filter: {
                text: textSearch.toString(),
                filterBy: this.loadSearchFilter()
              }
            }).pipe(catchError(() => {
              this.value = { text: textSearch.toString(), product: undefined, err: true };
              this.dialog.openSnack('controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
            }));


          } else {
            if (textSearch === '') {
              this.loading = false;
              return [];
            } else {
              return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
            }
          }
        })

      ).subscribe((api) => {
        console.log((api as DynacrudApi<Product[]>).data);

        if (api != undefined) {

          this.products = [...(api as DynacrudApi<Product[]>).data];

          if (this.structureDataFilter && this.structureDataFilter.productStructure &&
            this.cacheStructure && this.cacheStructure.productStructure &&
            (this.structureDataFilter.productStructure.id == this.cacheStructure.productStructure.id)) {

            this.productAutoCompletePanel.openPanel();

          }
          this.loading = false;
        }

      });

    }).subscribe();

  }

  isOpened(e: any) {

    if (this.structureDataFilter && this.structureDataFilter.productStructure &&
      this.cacheStructure && this.cacheStructure.productStructure &&
      (this.structureDataFilter.productStructure.id != this.cacheStructure.productStructure.id)) {

      this.productAutoCompletePanel.closePanel();

    }
  }

  validExpansionIcon(brand: Brand) {
    if (brand && brand.code) {
      const validExpansion: { [key: string]: any } = {MtG: true, PCG: true};
      return validExpansion[brand.code];
    }
  }


  noImage(value: Product): void {
    value.imageName = environment.DefaultImageMagic || '';
  }

  close() {
    this.dialog.closeBarRef();
  }

  public cacheValue: { brand: Brand | undefined, category: Category | undefined } = {
    brand: undefined,
    category: undefined
  };

  get brandCategory() {
    return this.cacheValue;
  }

  set brandCategory(value: { brand: Brand | undefined, category: Category | undefined }) {
    this.cacheValue = value;
  }


  selectProduct(prod: Product | null, esc?: boolean) {

    if (prod && !esc) {
      this.clearProduct.emit(false);

      this.clicEventProduct = true;
      this.value = {text: prod.name, product: prod, click: this.clicEventProduct};
      this.productSuggestInput.nativeElement.value = prod.name + (prod?.expansion && prod?.expansion?.id ? (' ' + prod.expansion?.name) : '');
      this.brandCategory = { brand: this.value.product?.brand, category: this.value.product?.category };

    } else if ((!prod) || esc) {

      this.clicEventProduct = false;
      this.value = {
        text: '',
        product: undefined,
        brand: prod ? prod?.brand : undefined,
        category: prod ? prod?.category : undefined
      };
    }

  }

}
