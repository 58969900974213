<mat-form-field>
  <mat-label> {{ valueProducts && valueProducts[code]?.id ? (valueProducts[code]?.expansion ? valueProducts[code]?.name
    + ' (' +
    (valueProducts[code]?.expansion?.enName || '') + ')' : ''+ valueProducts[code]?.name) : 'Search products'}}
  </mat-label>
  <input [disabled]="valueProducts[code] && valueProducts[code]?.id" type="text" matInput name="searchProducts"
    #searchStock [matAutocomplete]="auto">

  <button mat-icon-button matSuffix *ngIf="!loading" (click)="clearSelect()">
    <mat-icon>
      clear
    </mat-icon>
  </button>

  <div matSuffix class="p-1" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>

  <mat-autocomplete (optionSelected)="selectProduct($event.option.value)" #auto="matAutocomplete">
    <mat-option *ngFor="let option of productResult" [value]="option">
      <div class="d-flex justify-content-between gap-2">
        <div class="d-flex">
          <img [alt]="option?.name || ('default image with id' + option.id)" [src]="option.imageName"
            #trigger="cdkOverlayOrigin" cdkOverlayOrigin width="24" (error)="noImage(option)"
            (mouseover)="hoverProduct($event, option)" (mouseleave)="onMouseLeave()">

          <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen[option.id]">

            <div class="d-flex panel_onfire_ow justify-content-center flex-column align-items-center w-100">
              <div class="d-flex w-100">
                <img [src]="option.imageName" (error)="noImage(option)" [alt]="option.imageName" width="200">
              </div>
            </div>
          </ng-template>

        </div>
        <div class="d-flex">
          {{option.name}}
        </div>
      </div>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>