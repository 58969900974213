import { Component } from '@angular/core';
import { Warehouse } from 'src/app/shared/models/entities';

@Component({
  selector: 'app-wrap-me-stock-filter',
  templateUrl: './wrap-me-stock-filter.component.html',
  styleUrls: ['./wrap-me-stock-filter.component.scss']
})
export class WrapMeStockFilterComponent {


  public warehouseFilter: CallableFunction = (w: Warehouse) => !w.virtual && !w.obsolete;

}
