<ng-container *ngIf="featureCode == featureCodeType.LOAD">
  <div class="d-flex flex-column w-100 p-2 gap-3 cont_full " (keyup.enter)="setItem(); $event.preventDefault();">
  
    <ng-container *ngIf="(featureCode == featureCodeType.LOAD)">
  
      <div class="d-flex justify-content-end  w-100 justify-content-between" style="height: 10%; align-items: center;"
        [ngClass]="{'on_head_button': !(featureCode == featureCodeType.LOAD), 'p-4' : (featureCode == featureCodeType.LOAD) }">
  
  
  
        <div class="d-flex align-items-center w-100">
          <!-- FIXME: + nome expansion -->
          <h2> {{ (cache['product']?.name || '') + ' ' + cache['product']?.expansion.name }} </h2>
        </div>
  
  
        <!-- <button mat-flat-button (click)="ver()">
            ver
          </button> -->
  
        <div class="d-flex w-100 align-items-center jus gap-2 justify-content-end">
          <button mat-raised-button color="warn" (click)=" modaleReset(); $event.preventDefault(); ">
            Reset
          </button>
  
          <button mat-raised-button color="primary" [matBadge]="valid() && valid().length ? valid().length : 0"
            matBadgeColor="primary" (click)=" modaleApplica(); $event.preventDefault(); ">
            Applica
          </button>
  
          <button mat-icon-button mat-dialog-close>
            <mat-icon>
              close
            </mat-icon>
          </button>
  
        </div>
  
  
      </div>
  
    </ng-container>
  
  
  
  
    <ng-container *ngFor="let itemOff of listProducts; index as i">
  
      <div class="d-flex p-2 contain_card_single mat-elevation-z2 w-100">
  
  
  
        <div class="d-flex align-items-center image_name">
  
  
          <div class="d-flex gap-2" #trigger="cdkOverlayOrigin" cdkOverlayOrigin style="cursor: pointer;"
            (click)="isOpen[i] = !isOpen[i]">
            <img src="{{itemOff['cardData'].image}}" alt="{{cache.product.name}}" width="80">
  
            <!-- <span class="row_vertical"></span> -->
          </div>
  
          <ng-template #cdkConOverlay="cdkConnectedOverlay" cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen[i]">
  
            <div class="d-flex panel_onfire justify-content-center flex-column align-items-center w-100"
              (click)="isOpen[i] = !isOpen[i]">
  
              <div class="d-flex w-100" style="max-width: 200px;">
  
                <img src="{{cache.cardData.image}}" alt="{{cache.product.name}}" width="200">
  
              </div>
  
            </div>
          </ng-template>
  
        </div>
  
        <div class="d-flex card_detail" *ngIf="itemOff['combination'] ">
  
          <ng-container *ngFor="let field of productStructure?.stockfields; index as idNon">
            <ng-container *ngIf="field && (field.field && field.type == 'LOOKUP')">
  
              <app-structure-rapid-look-up class="rapid_look"
                [name]="field.field + '_' + i + field.type + idNon " [structureField]="field" [required]="true"
                #fieldLook [valueSet]="objectCacheItem[i]['combination'][field.field + 'Id'] "
                [(ngModel)]="objectCacheItem[i]['combination'][field.field + 'Id']"></app-structure-rapid-look-up>
  
  
  
            </ng-container>
  
            <ng-container *ngIf="field && field.field && field.type == 'FLAG'">
  
              <div class=" d-flex" *ngIf="field.field">
                <mat-checkbox [name]="field.field+'_'+ i + field.type + idNon "
                  [(ngModel)]="getData(i, field.field).combination">
                  <span>{{field.field | translate}}</span>
                </mat-checkbox>
              </div>
  
            </ng-container>
  
  
          </ng-container>
  
          <div class="d-flex custom_whds">
  
            <mat-form-field class="input_">
              <mat-label> {{'new_movement.warehouseTo' | translate}} </mat-label>
              <input class="in_from" name="fromVal_{{i}}" #inputFromval="ngModel" matInput type="number" [min]="0"
                [(ngModel)]="getData(i).warehouseRow" placeholder="To" required />
  
              <mat-hint matRipple><span class="primary">{{objectCacheItem && objectCacheItem[i] &&
                  objectCacheItem[i]['warehouse'] ? objectCacheItem[i]['warehouse'].name : ''}}</span></mat-hint>
              <mat-error *ngIf="inputFromval?.errors">Questo campo e obbligatorio </mat-error>
  
              <button matSuffix class="button_lookUp" type="button" mat-icon-button color="primary"
                [matMenuTriggerFor]="optFrom">
                <mat-icon class="icon-menu-look-up">menu</mat-icon>
              </button>
            </mat-form-field>
            <mat-menu #optFrom="matMenu">
              <button (click)="selectedWarehouseFrom(wh, i)" type="button" mat-menu *ngFor="let wh of warehouses"
                mat-menu-item>
                {{wh.id}} - {{wh.name}}
              </button>
            </mat-menu>
  
          </div>
  
  
          <div class="d-flex citem_ custom_whds ">
            <mat-form-field>
              <mat-label>Quantity</mat-label>
              <input #inputQuantity="ngModel" required matInput type="number" name="qty_{{i}}"
                [(ngModel)]="getData(i).quantity" min="0">
              <mat-error *ngIf="inputQuantity?.errors">Questo campo e obbligatorio </mat-error>
            </mat-form-field>
          </div>
  
          <div class="d-flex citem_ custom_whds">
            <mat-form-field>
              <mat-label>Comment</mat-label>
              <input [disabled]="featureCode != featureCodeType.LOAD" matInput type="text" name="commt_{{i}}"
                [(ngModel)]="getData(i).comments">
  
            </mat-form-field>
          </div>


          <div class="d-flex citem_ custom_whds">
            <mat-form-field>
              <mat-label>Prezzo di acquisto</mat-label>
              <input  (input)="limitarDecimales($event, i)" [disabled]="featureCode != featureCodeType.LOAD" matInput type="number" [min]="-1" name="commt_{{i}}"
                [(ngModel)]="getData(i).purchasePrice">
  
            </mat-form-field>
          </div>
  
        </div>
        <!-- other -->
  
        <div class="d-flex actions__">
          <button mat-raised-button color="accent" (click)="duplicate(i); $event.preventDefault();">
            Duplicate
          </button>
  
          <button mat-raised-button color="warn" (click)="deleteItem(i); $event.preventDefault();">
            Delete
          </button>
  
          <div class="d-flex justify-content-end w-100 ">
  
            <button mat-mini-fab matTooltip="Add item" [color]=" this.singleValid(i).validSingle ?  'primary' : 'warn' "
              (click)="add(i); $event.preventDefault();">
              <mat-icon>
                add
              </mat-icon>
            </button>
          </div>
  
        </div>
  
      </div>
  
    </ng-container>
  
  </div>

</ng-container>




<ng-container *ngIf=" featureCode != featureCodeType.LOAD ">

  <div class="d-flex w-100 flex-column">
    <div class="d-flex justify-content-end">

      <button mat-icon-button mat-dialog-close>
        <mat-icon>
          close
        </mat-icon>
      </button>

    </div>

    <div class="d-flex w-auto contan_tbl ">

      <app-table *ngIf="showTable" (status)="tableStatusChangeCart($event)" (rowSelect)="getRows($event)"
        [displayedColumns]="defaultListConfCustom.displayedColumns" [config]="defaultListConfCustom.tableConfig"
        [dataSource]="dataSource" class="table_summary_in_movement___">
      </app-table>

    </div>

  </div>



</ng-container>