import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { select, Store } from '@ngrx/store';
import { isNullOrUndefined } from 'is-what';
import { catchError, debounceTime, distinctUntilChanged, fromEvent, map, Observable, of, switchMap } from 'rxjs';
import { DynacrudApiWrapper, DynaCrudService } from 'src/app/core/api/dynacrud-api';
import { DynacrudApi, Filter, OperationType, Sort } from 'src/app/core/models/dynacrud';
import { Customer } from 'src/app/features/shop/models/entities';
import { Category, Brand, Warehouse, WorkingMovement, ASCIIFolder } from 'src/app/shared/models/entities';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { DynaConfActionType, DynaCrudRequest, selectDynaCrudRequest, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

interface SessionOrder {
  name: string;
  value?: string | null;
  id: any
}

interface WarehouseMovementsFilter {
  category?: Category[];
  brand?: Brand[];
  warehouse?: Warehouse[];
  warehouseFrom?: Warehouse[],
  warehouseTo?: Warehouse[],
  sessionOrder?: SessionOrder;
  text?: string;
  [key: string]: any;

}

export interface FilterMv {

}

@Component({
  selector: 'app-working-movements-filters',
  templateUrl: './working-movements-filters.component.html',
  styleUrls: ['./working-movements-filters.component.scss']
})
export class WorkingMovementsFiltersComponent implements OnInit, OnDestroy, AfterViewInit {

  hasCustomer = false;
  private customerService!: DynaCrudService<Customer>;
  // private productSubject: Subject<Product[]> = new Subject<Product[]>(); Note
  /*readonly*/ customerObs$!: Observable<Customer[]>; // = this.productSubject.asObservable();

  @ViewChild('productSuggestInputWork', { static: false }) productSuggestInput!: ElementRef;
  @ViewChild('productsAutocompleteWorkTrigger', { static: true }) productAutoCompletePanel!: MatAutocompleteTrigger;
  @ViewChild('searchDashboard') searchDashboard!: ElementRef<HTMLInputElement>;

  categoryFilter: CallableFunction = (w: Category) => w.macroCategory?.id != 2;

  loading!: boolean;
  constructor(private store: Store<State>, private formBuilder: FormBuilder, private dynacrudApiWrapper: DynacrudApiWrapper, private dialog: DialogService, private focusMonitor: FocusMonitor) {
    this.customerService = dynacrudApiWrapper.getFor('customers/working');
  }

  customerObs: Customer[] = [];
  ngAfterViewInit(): void {

    this.focusMonitor.stopMonitoring(document.getElementById('id_Delete') as HTMLElement);

    new Observable<Customer[]>(customerObsOn => {
      fromEvent(this.productSuggestInput?.nativeElement, 'keyup').pipe(
        map((event: any) => {
          return event.target.value;

        }),
        // Time in milliseconds between key events
        debounceTime(200),
        distinctUntilChanged(),

        switchMap((textSearch: string) => {



          textSearch = ASCIIFolder.foldReplacing(textSearch);
          textSearch = textSearch.replace('\u200C', '');

          if (textSearch?.length > 0) {
            if (!textSearch.match(/^[A-z\u00C0-\u00ff\s'"+.,-\/#!$%^&*;:?1-9-0{}=\-_`~()]+$/)) {

              this.value = { text: textSearch, customer: undefined };
              this.dialog.openSnack('controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return '';
            }
          } else {
            // this.loading = true;
            this.value = { text: textSearch, customer: undefined };
          }

          if (textSearch && textSearch.length > 2 && !this.value?.customer) {

            this.value = { text: textSearch, customer: undefined };

            this.loading = true;
            return this.customerService.searchCustomer({ filter: { text: textSearch, filterBy: this.loadSearchFilter() } }, { filter: { filterBy: this.getFilters() } }).pipe(catchError(err => {


              this.value = { text: textSearch, customer: undefined };
              let ms: string = err.error.message;
              let msp = ms.search(':');
              let msp2 = ms.search('!');
              let msres = ms.slice(msp + 1, msp2 + 2);


              this.dialog.openSnack(msres || 'controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
              return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));


            }));
          } else {
            if (textSearch === '') {
              this.loading = false;
              return [];
            } else {
              return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
            }

          }

        })
      ).subscribe((response) => {
        if (response) {
          customerObsOn.next((response as DynacrudApi<Customer[]>).data);
          this.customerObs = [...(response as DynacrudApi<Customer[]>).data];

          // if ((response as DynacrudApi<Customer[]>).data && (response as DynacrudApi<Customer[]>).data.length > 0 ) {
          //   this.hasCustomer = true;
          // }

        }
        this.loading = false;

      });
    }).subscribe();

    fromEvent(this.searchDashboard.nativeElement, 'keyup').pipe(
      map(ev => { return (ev.target as any).value || ''; }),
      distinctUntilChanged(),
      debounceTime(250)
    ).subscribe((d: string) => {
      if (d && d.length > 2) {
        this.search();
      } else if (d.length == 0) {
        this.search();
      }
    });


  }

  pasteValue(event: ClipboardEvent) {
    this.loading = true;

    const value: string | undefined = event.clipboardData?.getData('text');

    const textSearch = ASCIIFolder.foldReplacing(value);

    new Observable<Customer[]>(custo => {

      of(textSearch).pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((textSearch: string) => {

          textSearch = ASCIIFolder.foldReplacing(textSearch);

          if (textSearch && textSearch.length > 2) {
            this.value = { text: textSearch, customer: undefined };

            return this.customerService.searchCustomer({ filter: { text: textSearch, filterBy: this.loadSearchFilter() } },
              { filter: { filterBy: this.getFilters() } }).pipe(catchError(err => {
                this.loading = false;
                this.value = { text: textSearch, customer: undefined };
                let ms: string = err.error.message;
                let msp = ms.search(':');
                let msp2 = ms.search('!');
                let msres = ms.slice(msp + 1, msp2 + 2);


                this.dialog.openSnack(msres || 'controlla quello che hai scritto alcuni caratteri non sono consentiti', 'warning', 99999);
                return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));

              }));

          } else {
            return new Observable((obs) => obs.next({ messages: [], meta: null, data: [] }));
          }
        })

      ).subscribe((api: any) => {

        this.customerObs = [...(api as DynacrudApi<Customer[]>).data];

        this.loading = false;
      });
    }).subscribe()

  }

  getFilters(): Filter | undefined {


    const possibleFilters: Filter[] = [];

    if (this.filterFormGroup.value) {
      this.filter = { ...this.filterFormGroup.value };
    }

    if (this.filter.brand && this.filter.brand.length > 0) {
      const filterBrand = this.filter.brand;
      possibleFilters.push({ operation: OperationType.IN, field: 'product.brand.id', value: filterBrand.map(b => b.id) });
    }
    if (this.filter.category && this.filter.category.length > 0) {
      const filterCategory = this.filter.category;
      possibleFilters.push({ operation: OperationType.IN, field: 'product.category.id', value: filterCategory.map(b => b.id) });
    }
    if (this.filter.warehouse && this.filter.warehouse.length > 0) {
      const filterWarehouse = this.filter.warehouse;
      possibleFilters.push({ operation: OperationType.IN, field: 'warehouseId', value: filterWarehouse.map(b => b.id) });
    }

    // warehouse From field fromWarehouseId
    if (this.filter.warehouseFrom && this.filter.warehouseFrom.length > 0) {
      const filterWarehouse = this.filter.warehouseFrom;
      possibleFilters.push({ operation: OperationType.IN, field: 'fromWarehouseId', value: filterWarehouse.map(w => w.id) });
    }

    // warehouse To field toWarehouseId
    if (this.filter.warehouseTo && this.filter.warehouseTo.length > 0) {
      const filterWarehouse = this.filter.warehouseTo;
      possibleFilters.push({ operation: OperationType.IN, field: 'toWarehouseId', value: filterWarehouse.map(w => w.id) });
    }

    if (this.filter.sessionOrder?.value != null) {
      possibleFilters.push({ operation: OperationType.IS_NOT_NULL, field: this.filter.sessionOrder?.value });
    }

    if (this.filter?.['presale'] != null) {
      possibleFilters.push({ operation: OperationType.EQ, field: 'presale', value: this.filter['presale'] });
    }


    let filterByObj: Filter | undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }




    return filterByObj;

  }

  @HostListener('document:keyup.enter')
  enterKey() {
    console.log('enter inviato da exterior');
    this.search();
  }

  private subs = new SubSink();
  workingMovementsObs!: Observable<WorkingMovement[]>;
  sessionOrder: SessionOrder[] = [{ name: 'Orders', value: 'orderId', id: 1 }, { name: 'Sessions', value: 'sessionId', id: 2 }, { name: 'All', value: null, id: 3 }];

  filterFormGroup!: FormGroup;
  filter: WarehouseMovementsFilter = {
    warehouse: [],
    warehouseFrom: [],
    warehouseTo: [],
    category: [],
    brand: [],
    sessionOrder: undefined,
    text: ''
  };
  get fulltext () {
    return this.filter?.text;
  }

  set fulltext (v: string | undefined) {
    this.filter = {...this.filter, text: ASCIIFolder.foldReplacing(v || '')};
  }
  private crudRequest: DynaCrudRequest = {
    searchRequest: {},
    customData: this.filter
  };

  sortItems: Sort[] = [];
  brandFilter: CallableFunction = (w: Brand) => !w.obsolete;
  warehouseFilter: CallableFunction = (w: Warehouse) => !w.virtual;
  value: { text: string | undefined; customer: Customer | undefined; } | undefined;


  selectProduct(customer: Customer | null) {
    if (customer) {
      this.value = { text: customer.name + ' ' + customer?.surname, customer: customer };
      this.productSuggestInput.nativeElement.value = customer.name + ' ' + customer?.surname;
      this.hasCustomer = true;

    } else if (!customer) {

      this.value = { text: '', customer: undefined };
      this.hasCustomer = false;

    }

  }

  loadSearchFilter(): Filter | undefined {
    return {
      filters: [
        { field: 'obsolete', operation: OperationType.EQ, value: false },

      ], operation: OperationType.AND
    }
  }

  ngOnInit(): void {

    this.workingMovementsObs = this.dynacrudApiWrapper.getFor('workingMovements').search().pipe(map(ret => ret.data)) as Observable<WorkingMovement[]>;

    this.filterFormGroup = this.formBuilder.group({
      warehouse: [],
      category: [],
      presale: [],
      warehouseTo: [],
      brand: [],
      warehouseFrom: [],
      sessionOrder: null,
      text: ['']
    });
    let firstTime = true;
    this.subs.add(this.store.pipe(select(selectDynaCrudRequest), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(request => {

      this.crudRequest = request ? { ...request } : this.crudRequest;
      this.filter = this.crudRequest.customData;
      console.log('this.filter', this.filter);

      if (this.filter && this.filter['productSearch'] && this.filter['productSearch'].customer && this.filter['productSearch'].customer.id) {
        this.value = this.filter['productSearch'];

        if (this.value?.customer?.name && this.value?.customer?.surname) {

          this.hasCustomer = true;
        }

        this.productSuggestInput.nativeElement.value = this.value?.text || (this.value?.customer?.name || '' + this.value?.customer?.surname || '');
      }

      this.sortItems = this.crudRequest.searchRequest?.sort as Sort[];
      if (firstTime) {
        firstTime = false;
        if (this.filter != null) {
          this.filterFormGroup.patchValue({ ...this.filter });
          this.search();
        }

      }
    }
    ));


  }

  public compareEntity(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.id === o2.id;
  }

  clearCustomer(ev: any) {
    if (ev == true || ev?.pointerType == 'mouse') {

      this.value = undefined;
      this.productSuggestInput.nativeElement.value = '';
      this.hasCustomer = false;

    }
  }

  search(click?: Event) {
    console.log('lolalme');
    const possibleFilters: Filter[] = [];

    if (this.filterFormGroup.value) {
      this.filter = { ...this.filterFormGroup.value, text: this.filter?.text || ''  };
    }

    if (this.filter.brand && this.filter.brand.length > 0) {
      const filterBrand = this.filter.brand;
      possibleFilters.push({ operation: OperationType.IN, field: 'product.brand.id', value: filterBrand.map(b => b.id) });
    }
    if (this.filter.category && this.filter.category.length > 0) {
      const filterCategory = this.filter.category;
      possibleFilters.push({ operation: OperationType.IN, field: 'product.category.id', value: filterCategory.map(b => b.id) });
    }
    if (this.filter.warehouse && this.filter.warehouse.length > 0) {
      const filterWarehouse = this.filter.warehouse;
      possibleFilters.push({ operation: OperationType.IN, field: 'warehouseId', value: filterWarehouse.map(b => b.id) });
    }

    // warehouse From field fromWarehouseId
    if (this.filter.warehouseFrom && this.filter.warehouseFrom.length > 0) {
      const filterWarehouse = this.filter.warehouseFrom;
      possibleFilters.push({ operation: OperationType.IN, field: 'fromWarehouseId', value: filterWarehouse.map(w => w.id) });
    }

    // warehouse To field toWarehouseId
    if (this.filter.warehouseTo && this.filter.warehouseTo.length > 0) {
      const filterWarehouse = this.filter.warehouseTo;
      possibleFilters.push({ operation: OperationType.IN, field: 'toWarehouseId', value: filterWarehouse.map(w => w.id) });
    }


    if (this.filter.sessionOrder?.value != null) {
      possibleFilters.push({ operation: OperationType.IS_NOT_NULL, field: this.filter.sessionOrder?.value });
    }

    if (this.filter?.['presale'] != null) {
      possibleFilters.push({ operation: OperationType.EQ, field: 'presale', value: this.filter['presale'] });
    }


    if (this.value && this.value.customer && this.value.customer.id) {
      const idUser = this.value.customer.id;
      this.filter['productSearch'] = this.value;
      // FIXME: manca il field

      possibleFilters.push({ field: 'customerId', operation: OperationType.EQ, value: idUser })

    }

    /*all filters*/
    let filterByObj: Filter | undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }
    this.crudRequest.customData = { ...this.filter };
    if (click) {
      if (click.type) {
        this.crudRequest.searchRequest = { filter: { filterBy: filterByObj, text: this.crudRequest.customData.text || '' }, pagination: { page: 0, size: 20, active: true } };
        this.clearPagination();
      }
    } else {
      this.crudRequest.searchRequest = { filter: { filterBy: filterByObj, text: this.crudRequest.customData.text || '' } };
    }
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
  }

  private clearPagination() {
    const paginationClear: any = { pagination: { page: 0, size: 20 }, sort: this.sortItems };
    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, paginationClear));

  }

  clearSort() {
    if (this.crudRequest?.searchRequest?.sort) {
      this.crudRequest.searchRequest.sort = [];
    }
    if (this.crudRequest.searchRequest && this.crudRequest.searchRequest.pagination) {
      this.crudRequest.searchRequest.pagination = undefined;
    }
    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, this.crudRequest.searchRequest));

  }

  reset() {
    this.filterFormGroup.reset();
    this.filterFormGroup.get('sessionOrder')?.setValue(this.sessionOrder[2]);
    this.filter = this.filterFormGroup.value;
    this.filterFormGroup.get('sessionOrder')?.setValue(this.sessionOrder[0]);

    this.clearSort();
    this.clearCustomer(true);
    this.search();
  }


  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  clearFilter() {
    this.filter.text = '';
    this.search();
  }
}
