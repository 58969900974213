import { Component, OnInit } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from '../../Column';

@Component({
  selector: 'app-actions-set-insert',
  templateUrl: './actions-set-insert.component.html',
  styleUrls: ['./actions-set-insert.component.scss']
})
export class ActionsSetInsertComponent implements ColumnFieldComponent, OnInit {
  
  data!: ColumnData;


  ngOnInit(): void {
  }

  deleteItem () {

  }

  duplica () {
    
  }
}
