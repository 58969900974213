import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DefaultLayoutService {

  private scrollEventSubject = new BehaviorSubject<number>(0);
  public scrollRead = this.scrollEventSubject.asObservable();


  public setScrollPoint(v: number) {
    this.scrollEventSubject.next(v);
  }

  constructor() { }
}
