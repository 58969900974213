import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { SessionWrapperService } from '../../../services/session-wrapper.service';
import { Store } from '@ngrx/store';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { catchError, throwError } from 'rxjs';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';

@Component({
  selector: 'app-wrapp-handling-action',
  templateUrl: './wrapp-handling-action.component.html',
  styleUrls: ['./wrapp-handling-action.component.scss']
})
export class WrappHandlingActionComponent implements TableActionComponent, OnDestroy, OnInit {

  youcango = false;
  youcandetach = false;
  private subs = new SubSink();

  data!: ActionData;
  constructor(private sessionWrapService: SessionWrapperService, private store: Store<State>, private router: Router, private dialogLayoutService: DialogService) { }

  ngOnInit() {
    if (this.data && this.data.item) {
      this.youcango = !!(this.data.item.wrapper != undefined && this.data.item.wrapper != null);
      this.youcandetach = !!(this.data.item.wrapper != undefined && this.data.item.wrapper != null);
    }
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  taylorCanDetach() {

    const options = {
      title: `Staccare sessione dal wrapper`,
      message: `Vuoi staccare questa sessione dal wrappper`,
      cancelText: `No`,
      confirmText: `Yes`
    };

    this.dialogLayoutService.open(options);
    this.dialogLayoutService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));
    
        this.subs.add(
          this.sessionWrapService.detachFromWrapper(this.data.item, false).pipe(catchError(err => {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            return throwError(() => err);
          })).subscribe(d => {
    
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
          })
        );
      }
    });

  }

  taylorCanGo() {
    const id = this.data.item?.wrapper?.id || undefined;
    if (id) {
      this.router.navigateByUrl(`app/warehouse/sessionWrapper/${id}`).then();

    }


  }


}
