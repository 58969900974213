import { Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ColumnFieldComponent, ColumnData } from 'src/app/core/components/table/Column';
import { HostAllRowsInColumnsService } from 'src/app/shared/services/host-all-rows-in-columns.service';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-quantity-session-movement-column',
  templateUrl: './quantity-session-movement-column.component.html',
  styleUrls: ['./quantity-session-movement-column.component.scss']
})
export class QuantitySessionMovementColumnComponent implements ColumnFieldComponent, OnInit, OnDestroy {

  constructor(private dSubject: ReloadRowItemsSingleService, private hostServiceColumn: HostColumnEditService, private hostallRows: HostAllRowsInColumnsService) { /*super()*/ }
  subs = new SubSink();
  qtyToTransf!: number | undefined;
  data!: ColumnData;
  isElement!: boolean;
  setAvailable = false;
  blockAvailable = false;

  get rowTrue(): number | undefined {
    if (this.data.row?.instanceValue >= 0) {
      return this.data.row?.instanceValue;
    }
    return undefined;
  }
  rowSelect(n: number) {
    if (this.data.row?.isSelect == false || this.data.row?.isSelect == true /**puo essere false */ && n /**essite il id */) {
      return this.data.row.isSelect;
    }
  }
  getidRow(): number {
    return this.data.row.id;
  }
  ngOnDestroy(): void {
    this.clear();
    this.subs.unsubscribe();
  }
  clear(): void {
    this.qtyToTransf = undefined;
    this.bindingSingle(this.data.row.id).item = this.qtyToTransf;
  }


  setQuantity(e?: Event) {
    if (e) {
      e.preventDefault();
      /**PASSA PER IL EVENTO CLICK  */
      this.setAvailable = !this.setAvailable;
      this.blockAvailable = !this.blockAvailable;
    }
    if (this.setAvailable) {
      if (!this.isElement) {
        this.data.row.instanceValue = this.data.row.avaible;
        this.bindingSingle(this.data.row.id).item = this.data.row.instanceValue;
      }

    } else {
      if (!this.isElement) {
        this.data.row.instanceValue = 0; // nullable;
        this.bindingSingle(this.data.row.id).item = this.data.row.instanceValue;
        // this.setAvailable = false; 
      }
    }
  }
  ngOnInit(): void {
    this.dSubject.stateData.subscribe((res) => {
      if (res && res.reload) { // only true
        if (res.data && (res.data == this.data.row.productId)) {
          if (this.setAvailable != null) {
            this.isElement = false;
            this.setAvailable = false;
          }
        }
      }
    });
    this.hostServiceColumn.obsSelectRow.subscribe(d => {
      if (d && d.length > 0) {
        // ''
        d.forEach((item: { row: { id: any; }; }) => {
          if (item && item.row && item.row.id) {
            if (item.row.id == this.data.row.id) {
              if (item.row.id) {

                // dal header
                this.subs.add(
                  this.hostServiceColumn.setAllrowsQuantityObs.pipe().subscribe(active => {

                    // fixme santiago mi serve passare la proprieta setAvailable della clase e pure extenderla da la tabella al componente (questo componente oppure altro) ... per ora funziona
                    if ((this.rowSelect(this.getidRow()) == true)) { // son infinitos los cambios pero este puede ser el definitivo? piensa a tener el rowSelect y el id de la columna fuera del componente
                      if (active != null) { // is only true or false
                        // si una columna es true dejarala asi IMPORTANTEFIX
                        if (!this.isElement && this.blockAvailable != true) {
                          this.setAvailable = active; // fixme santiago deve arrivare solo quando non e null | undefined
                          this.setQuantity(); // fixme santiago il metodo deve essere privato? fare debug
                        }
                      }
                    }
                  })
                );

                this.subs.add(
                  this.hostServiceColumn.dataColInput.pipe().subscribe((actionVal: any) => {
                    if (actionVal) {
                      if ((this.rowSelect(this.getidRow()) == true)) {
                        if (!this.isElement && !this.setAvailable) {
                          this.qtyToTransf = actionVal[this.data.field.id];
                          this.data.row.instanceValue = this.qtyToTransf;
                          this.bindingSingle(this.data.row.id).item = this.data.row.instanceValue;
                        }
                      }
                    } else { /**fixme santiago controlla il valore inserito quando non esiste. me puede servir para agregar al estado :) */ }
                  })
                );
              }
            }
          }
        });
      }
    })
  }

  valueObt(value: any) {
    if (value != null && value > 0) {
      this.isElement = true;
    } else {
      this.isElement = false;
    }
  }
  bindingSingle(idNum: any) {
    let instance: { [key: string]: any } = {};
    const me = this;
    return {
      get item(): any {
        return instance[idNum] || me.qtyToTransf;
      },
      set item(item) {
        me.qtyToTransf = item;
        instance[idNum] = me.qtyToTransf;
        if (me.data.row) {
          me.data.row.instanceValue = me.qtyToTransf;
          if ((me.data.row.instanceValue > 0) && (me.isElement === true)) {
            // fixme santiago lo posso controllare con subject da (NULL) A (boolean);
            me.setAvailable = false;
          }
          // ''
          me.hostallRows.updatedDataSelectionDATASELECT(me.data.row);
        }
      },
    }
  }

}


@Injectable({
  providedIn: 'root'
})
export class ReloadRowItemsSingleService {
  public _data = new BehaviorSubject<{data: any, reload: boolean} | null>(null);
  public stateData = this._data.asObservable();
  

  constructor() { }
}