<ng-container *ngIf="typeTable && typeTable == 'EDIT' ; else normalTable">

    <app-table-edit-rows *ngIf="routeConf" [filtersOff]="currentSearch" [dataExtra]="dataExtra"
        [productStructureCond]="typeTableViewCondition" [getRowTable]="getRowTable"
        [displayedColumns]="displayedColumns" [config]="tableConfig" (rowSelect)="select($event)"
        (custom)="action($event)" [dataSource]="dataSource" (status)="tableStatusChange($event)"></app-table-edit-rows>

</ng-container>

<ng-template #normalTable>

    <ng-container *ngIf="strategySelect ; else noStrategy">

        <app-table *ngIf="routeConf" [onlycheck]="onlyCheck" [notInputPage]="notInputPage" (newPageSingle)="newPage($event)"
            [strategySelect]="strategySelect" [getRowTable]="getRowTable" [displayedColumns]="displayedColumns"
            [config]="tableConfig" (rowSelect)="select($event)" (custom)="action($event)" [dataSource]="dataSource"
            (status)="tableStatusChange($event)"></app-table>

    </ng-container>

    <ng-template #noStrategy>

        <app-table *ngIf="routeConf" [onlycheck]="onlyCheck" [notInputPage]="notInputPage" [getRowTable]="getRowTable"
            [displayedColumns]="displayedColumns" [config]="tableConfig" (rowSelect)="select($event)"
            (custom)="action($event)" [dataSource]="dataSource" (status)="tableStatusChange($event)"></app-table>

    </ng-template>

</ng-template>