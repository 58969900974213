import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener, Inject,
  Input,
  OnDestroy,
  OnInit,
  Output, Type,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { Store, select } from '@ngrx/store';
import { isEmptyObject, isNullOrUndefined, isPlainObject } from 'is-what';
import * as _ from 'lodash';
import {
  Subject,
  Observable,
  debounceTime,
  distinctUntilChanged,
  merge,
  map,
  catchError,
  throwError, interval, takeWhile, BehaviorSubject
} from 'rxjs';
import {
  SelectionMode,
  TableAction,
  TableEvent,
  TableConfig,
  TableField,
  TableActionType
} from 'src/app/core/models/table-config';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { selectAuth } from 'src/app/store/auth/auth.reducers';
import {
  selectFeatureCode,
  State,
  DynaConfActionType, DynaCrudRequest
} from 'src/app/store/shared/shared.reducers';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { TableSelection, TableData, TableStatus } from '../table/table.component';
import { Auth } from 'src/app/auth/models/auth';
import { Sort, SortDirection } from 'src/app/core/models/dynacrud';
import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import {
  Condition, convertirAHexadecimal,
  Entity,
  Holo, KEYCONSTANTSETINSERT, keyDialog, Language,
  Product,
  ProductStructure,
  ProductStructureField,
  Warehouse, WarehouseSession
} from 'src/app/shared/models/entities';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { NgForm, NgModel } from '@angular/forms';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { WarehouseMovementDTORequired } from "../../../../features/warehouse/models/entities";
import { SetInsertService } from "../../../services/set-insert.service";
import { ActivatedRoute, Router } from "@angular/router";
import { BaseAction } from "../../../../store/store.actions";
import { ColumnFieldComponent } from "../Column";
import { HttpErrorResponse } from "@angular/common/http";
import { SharedService } from "../../../../shared/services/shared.service";
import { mergeMap } from "rxjs/operators";
import { LayoutActionType } from "../../../../store/layout/layout.reducers";
import { take } from 'rxjs';


enum TypeEditTable {
  COMMENT = 'COMMENT',
  QUANTITY = 'QUANTITY',
  WAREHOUSES = 'WAREHOUSES', //warehouseToId
  HOLOS = 'HOLOS',
  LANGUAGE = 'LANGUAGE',
  CONDITION = 'CONDITION', //conditionId
  LANGUAGEROW = 'languageRow',
  CONDITIONROW = 'conditionRow',
  PURCHASEPRICE = 'PURCHASEPRICE'

}

const [keepSelectAll, keepValue, keepValueComment, keepValueWarehouse, keepValueQuantity, keepValuePurchasePrice] = ['keepSelectAll', 'keep', 'keepValueComment', 'keepValueWarehouse', 'keepValueQuantity', 'keepValuePurchasePrice']

export enum TypeSaveInsert {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE'
}

export interface ColumnFieldNormal {
  $field: string;
  $editable: boolean;
  $label: string;
  $sortable: boolean;
  $id: string;
  $type?: Type<ColumnFieldComponent>;
  $sortExpression?: string;
  $typeField?: string | Type<any>;
}
export const KeepHeaderValues = 'headerValues';
type WarehouseMovementDTORequiredPrev = Omit<WarehouseMovementDTORequired, "warehouseFromId">;
interface SortItem {
  field: TableField;
  position: number;
  direction: SortDirection;
}
interface SortStatus {
  [id: string]: SortItem;
}
export type Roles = Pick<Auth, "roles">;
export interface CLEANOBJECT { [k: string]: any };

@Component({
  selector: 'app-table-edit-rows',
  templateUrl: './table-edit-rows.component.html',
  styleUrls: ['./table-edit-rows.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableEditRowsComponent implements OnInit, AfterViewInit, OnDestroy {

  featureCode: string | undefined = '';
  setAvailable = false;
  selection: TableSelection = { all: false, rows: [] };
  tableData: TableData = { data: [], tableStatus: { page: { pageIndex: 0, pageSize: 0, length: 0 }, sort: [] } };
  @ViewChild(MatPaginator, { static: false }) paginator!: MatPaginator;
  selectMode = SelectionMode.single;
  sortStatus: { [id: string]: SortItem } = {};
  
  selectionStatus: { [prop: string]: { row: any, selected: boolean | undefined } } = {};
  loadAll = false;
  sessionId: number | undefined;
  comentsValue = '';
  isOpen: { [key: string]: any } = {};
  quantityValue: number | undefined;
  productStructure: ProductStructure | undefined;
  errorCustomHolo: { [k: string]: any } = {};
  errorCustomLingua: { [k: string]: any } = {};
  errorCustomCondizione: { [k: string]: any } = {};
  allsValidValue = 0;
  copyProducts: Product[] = [];
  productSelectValue: { [key: string]: any } = {};
  productSelectValueAll!: boolean;
  entityAll: Warehouse | undefined;
  errorCustomAll = false;
  @ViewChild('genericFieldAll') genericFieldAll!: NgModel;
  errorCustom: { [k: string]: boolean } = {};
  loadingAll = false;
  valueFiledAll: { [key: string]: any } = {};
  validKeyValue = '';
  loadingSave = false;
  youCanReload = false;
  validProductsProp: any[] = [];
  instanciaError: any;
  instanciaIncluded: any;
  existLoad = false;
  noPaginationAndSort = false;
  firstLoadItems = true;
  secondLoadItems = true;
  resetValueOnFireSubject = new BehaviorSubject<boolean>(false);
  resetValueOnFireObs = this.resetValueOnFireSubject.asObservable();
  valuePagueIndex = 1;
  fullAuth!: Auth;
  canLoadNormal = true;
  firstLoads = true;
  persistValueOn: boolean = false;
  loadingType: { [k: string]: boolean } = {};
  purchasePriceValue: number | undefined;
  private subs2 = new SubSink();
  private itemsToLoadOnSave: Product[] = [];
  private subs = new SubSink();
  private $displayedColumns!: string[] | undefined;
  private sortSubject: Subject<SortStatus> = new Subject<SortStatus>();
  readonly sort: Observable<SortStatus> = this.sortSubject.asObservable();
  private actions: { [key: string]: TableAction[] } = {};

  @Output()
  rowSelect = new EventEmitter<TableSelection>();

  @Output()
  status = new EventEmitter<TableStatus>(true);

  @Output()
  custom = new EventEmitter<TableEvent>();

  @Input()
  dataSource!: Observable<TableData>;

  @Input()
  getRowTable: boolean | undefined;

  @Input()
  config!: TableConfig | undefined;

  @Input()
  productStructureCond!: ProductStructure | undefined;

  @Input()
  dataExtra: { [k: string]: any[] } = {};

  @Input() filtersOff!: DynaCrudRequest;

  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  @ViewChild('inputSingle_') inputSingle_: any;

  @ViewChild('cdkConOverlay', { static: false }) overLayConected: CdkConnectedOverlay | undefined;


  constructor(
    public store: Store<State>,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private setInsertService: SetInsertService,
    private activateRouter: ActivatedRoute,
    private sharedService: SharedService,
    private router: Router,
    private productStructureService: ProductStructureService) {

  }

  @Input()
  set displayedColumns(displayedColumns: string[] | undefined) {
    this.$displayedColumns = displayedColumns;
  }

  get displayedColumns(): string[] | undefined {
    return this.$displayedColumns || this.config?.columnsFields?.map(value => value.id);
  }

  get validKey(): string {
    return this.validKeyValue;
  }

  set validKey(v: string) {
    this.validKeyValue = v;
  }

  @HostListener('document:click', ['$event']) clickedOutside() {
    this.isOpen = {};
  }


  get commentsAll() {
    return this.comentsValue;
  }

  set commentsAll(v: string) {
    this.comentsValue = v;
    localStorage.setItem(keepValueComment, '' + this.comentsValue);
    this.copyProducts.forEach((d, index) => {
      if (d && d.active && d.editable && d.editable[TypeEditTable.COMMENT]) {
        this.productGestione(index, false).productComment = this.comentsValue;
      }
    });
  }

  get quantityAll(): number | undefined {
    return this.quantityValue;
  }

  set quantityAll(v: number | undefined) {
    this.quantityValue = v;
    localStorage.setItem(keepValueQuantity, '' + this.quantityValue);
    this.copyProducts.forEach((d, index) => {
      if (d && d.active && d.editable && d.editable[TypeEditTable.QUANTITY]) {
        this.productGestione(index, false).productQuantity = this.quantityValue;
      }
    });
  }

  

  get purchasePriceModel(): number | undefined {
    return this.purchasePriceValue;
  }

  set purchasePriceModel(v: number | undefined) {
    this.purchasePriceValue = v;
    localStorage.setItem(keepValuePurchasePrice, '' + this.purchasePriceValue);
    this.copyProducts.forEach((d, index) => {
      if (d && d.active && d.editable && d.editable[TypeEditTable.PURCHASEPRICE]) {
        this.productGestione(index, false).productPurchasePrice = this.purchasePriceValue;
      }
    });
  }

  get productSelectAll(): boolean {
    return this.productSelectValueAll;
  }

  set productSelectAll(value: boolean) {
  
    this.productSelectValueAll = value;
    localStorage.setItem(keepSelectAll, '' + this.productSelectValueAll);

    console.log('pasando loco');

    this.loadStructureForSelectAll(value);

    /*if ( this.productSelectValueAll ) {

      const values = { ...this.valueFiledAll };

      if ((values != undefined) && !isEmptyObject(values)) {

        me.copyProducts?.map((val: { [k: string]: any }, i) => {

          this.productSelectValue[i] = v;

          val['active'] = v;

          if (val['editable']['COMMENT']) {
            val['comment'] = this.comentsValue;
          }

          if (val['editable']['WAREHOUSES']) {
            val['warehouseRow'] = this.entityAll;
          }

          if (val['editable']['QUANTITY']) {
            val['quantity'] = this.quantityValue;
          }

          if (val['editable']['PURCHASEPRICE']) {
            val['purchasePrice'] = this.purchasePriceValue;
          }

          if (val['editable']['LANGUAGE'] && this.valueFiledAll['languageRow'] != undefined) {
            val['languageRow'] = val['languages'].find((v: Language) => v.id == this.valueFiledAll['languageRow']);
          }

          if (val['editable']['HOLOS'] && this.valueFiledAll['holoRow'] != undefined) {
            val['holoRow'] = val['holos'].find((v: Holo) => v.id == this.valueFiledAll['holoRow']);
          }

          if (val['editable']['CONDITION'] && this.valueFiledAll['conditionRow'] != undefined) {
            val['conditionRow'] = val['conditions'].find((v: Condition) => v.id == this.valueFiledAll['conditionRow']);
          }

          return val;
        });

      } else {

        Object.keys(values).forEach(d => {
          const value = this.valueFiledAll[d];

          me.copyProducts?.map((val: { [k: string]: any }, i ) => {

            this.productSelectValue[i] = v;

            val['active'] = v;

            if ((d != 'languageRow' && d != 'conditionRow') && val['editable'][d]) {
              val[d] = value;
            }

            // fixme current
            if (val['editable']['COMMENT']) {
              val['comment'] = this.comentsValue;
            }

            if (val['editable']['WAREHOUSES']) {
              val['warehouseRow'] = this.entityAll;
            }

            if (val['editable']['QUANTITY']) {
              val['quantity'] = this.quantityValue;
            }

            if (val['editable']['PURCHASEPRICE']) {
              val['purchasePrice'] = this.purchasePriceValue;
            }

            if (val['editable']['LANGUAGE'] && this.valueFiledAll['languageRow'] != undefined) {
              val['languageRow'] = val['languages'].find((v: Language) => v.id == this.valueFiledAll['languageRow']);
            }

            if (val['editable']['HOLOS'] && this.valueFiledAll['holoRow'] != undefined) {
              val['holoRow'] = val['holos'].find((v: Holo) => v.id == this.valueFiledAll['holoRow']);
            }

            if (val['editable']['CONDITION'] && this.valueFiledAll['conditionRow'] != undefined) {
              val['conditionRow'] = val['conditions'].find((v: Condition) => v.id == this.valueFiledAll['conditionRow']);
            }

            return val;
          });
        });
      }

    } else {
      this.copyProducts.map((pr, i) => { this.productSelectValue[i] = false; pr.active = false; return pr; });
    }*/
  }

  private loadStructureForSelectAll (v: boolean) {
    const me = this;

    if ( this.productSelectValueAll ) {

      const values = { ...this.valueFiledAll };

      if ( (values == undefined) || isEmptyObject(values) ) {

        me.copyProducts?.map((val: { [k: string]: any }, i) => {

          this.productSelectValue[i] = v;

          val['active'] = v;

          if (val['editable']['COMMENT']) {
            val['comment'] = this.comentsValue;
          }

          if (val['editable']['WAREHOUSES']) {
            val['warehouseRow'] = this.entityAll;
          }

          if (val['editable']['QUANTITY']) {
            val['quantity'] = this.quantityValue;
          }

          if (val['editable']['PURCHASEPRICE']) {
            val['purchasePrice'] = this.purchasePriceValue;
          }

          if (val['editable']['LANGUAGE'] && this.valueFiledAll['languageRow'] != undefined) {
            val['languageRow'] = val['languages'].find((v: Language) => v.id == this.valueFiledAll['languageRow']);
          }

          if (val['editable']['HOLOS'] && this.valueFiledAll['holoRow'] != undefined) {
            val['holoRow'] = val['holos'].find((v: Holo) => v.id == this.valueFiledAll['holoRow']);
          }

          if (val['editable']['CONDITION'] && this.valueFiledAll['conditionRow'] != undefined) {
            val['conditionRow'] = val['conditions'].find((v: Condition) => v.id == this.valueFiledAll['conditionRow']);
          }

          return val;
        });

      } else {

        Object.keys(values).forEach(d => {
          const value = this.valueFiledAll[d];

          me.copyProducts?.map((val: { [k: string]: any }, i ) => {

            this.productSelectValue[i] = v;

            val['active'] = v;

            if ((d != 'languageRow' && d != 'conditionRow') && val['editable'][d]) {
              val[d] = value;
            }

            // fixme current
            if (val['editable']['COMMENT']) {
              val['comment'] = this.comentsValue;
            }

            if (val['editable']['WAREHOUSES']) {
              val['warehouseRow'] = this.entityAll;
            }

            if (val['editable']['QUANTITY']) {
              val['quantity'] = this.quantityValue;
            }

            if (val['editable']['PURCHASEPRICE']) {
              val['purchasePrice'] = this.purchasePriceValue;
            }

            if (val['editable']['LANGUAGE'] && this.valueFiledAll['languageRow'] != undefined) {
              val['languageRow'] = val['languages'].find((v: Language) => v.id == this.valueFiledAll['languageRow']);
            }

            if (val['editable']['HOLOS'] && this.valueFiledAll['holoRow'] != undefined) {
              val['holoRow'] = val['holos'].find((v: Holo) => v.id == this.valueFiledAll['holoRow']);
            }

            if (val['editable']['CONDITION'] && this.valueFiledAll['conditionRow'] != undefined) {
              val['conditionRow'] = val['conditions'].find((v: Condition) => v.id == this.valueFiledAll['conditionRow']);
            }

            return val;
          });
        });
      }

    } else {
      this.copyProducts.map((pr, i) => { this.productSelectValue[i] = false; pr.active = false; return pr; });
    }

  }

  filterFN(item: WarehouseMovementDTORequiredPrev): boolean {

    if (item['brandId'] == 6) {
      return item && item['quantity'] != undefined && item['conditionId'] && item['languageId'] && item['warehouseToId'] && item['holoId'];
    } else if (item["brandId"] != undefined) {
      return item && item['quantity'] != undefined && item['conditionId'] && item['languageId'] && item['warehouseToId'];
    }

    return false;
  }

  getModel(value: { [k: string]: any }, isLoad: boolean): WarehouseMovementDTORequiredPrev {
    const otherEdit : {[k: string] : any} = { ...value['editable'] }  || {};

    // esto debe controlar los elementos que vienen agregados desde el header, para deshabilitarlos
    // Object.keys( value['editable'] ).forEach(v => {
    //   otherEdit[v] = false;
    // });

    if (isLoad) {

      if (value['editable'] != undefined) {

        Object.keys(value['editable']).forEach(column => {

          otherEdit[column] = true;
          const cf = column;
          
          if (  (value[cf.toLowerCase()] != undefined && value[cf.toLowerCase()] != null) || (value[cf] != undefined && value[cf] != null) ) {
            otherEdit[cf] = false;
          }
          
          if (( (value[cf.toLowerCase() + 'Row'] != undefined) && (value[cf.toLowerCase() + 'Row'] != null) )) {
            otherEdit[column] = false;
          }

        });

      }

      return {
        // active: value['active'],
        // languages: value['languages'],
        // conditions: value['conditions'],
        // warehouses: value['warehouses'],
        // holos: value['holos'],
        brand: value['brand'],
        category: value['category'],
        brandId: value['brandId'],
        categoryId: value['categoryId'],
        name: value['name'],
        altered: value['altered'] != undefined ? value['altered'] : null,
        comment: value['comment'] != undefined ? value['comment'] : null,
        conditionId: value['conditionRow'] != undefined && value['conditionRow'].id != undefined ? value['conditionRow'].id : null,
        firstEdition: value['firstEdition'] != undefined ? value['firstEdition'] : null,
        foil: value['foil'] != undefined ? value['foil'] : null,
        holoId: value['holoRow'] != undefined && value['holoRow'].id != undefined ? value['holoRow'].id : null,
        inked: value['inked'] != undefined ? value['inked'] : null,
        languageId: value['languageRow'] != undefined && value['languageRow'].id ? value['languageRow'].id : null,
        productId: value['id'] != undefined ? value['id'] : null,
        quantity: value['quantity'] != undefined ? value['quantity'] : null,
        purchasePrice: value['purchasePrice'] != undefined ? value['purchasePrice'] : null,
        shadowless: value['shadowless'] != undefined ? value['shadowless'] : null,
        signed: value['signed'] != undefined ? value['signed'] : null,
        staff: value['staff'] != undefined ? value['staff'] : null,
        stamped: value['stamped'] != undefined ? value['stamped'] : null,
        warehouseToId: value['warehouseRow'] != undefined && value['warehouseRow'].id != undefined ? value['warehouseRow'].id : null,
        card_data: value['card_data'],
        expansion: value['expansion'],
        color: value['color'],
        rarity: value['rarity'],
        productStructureId: value['productStructureId'],
        number: value['number'],
        id: value['id'],
        editable: otherEdit,
        languageRow: value['languageRow'],
        conditionRow: value['conditionRow'],
        warehouseRow: value['warehouseRow'],
        holoRow: value['holoRow'],



      };
    }
    return {
      brandId: value['brandId'],
      altered: value['altered'] != undefined ? value['altered'] : null,
      comment: value['comment'] != undefined ? value['comment'] : null,
      conditionId: value['conditionRow'] != undefined && value['conditionRow'].id != undefined ? value['conditionRow'].id : null,
      firstEdition: value['firstEdition'] != undefined ? value['firstEdition'] : null,
      foil: value['foil'] != undefined ? value['foil'] : null,
      holoId: value['holoRow'] != undefined && value['holoRow'].id != undefined ? value['holoRow'].id : null,
      inked: value['inked'] != undefined ? value['inked'] : null,
      languageId: value['languageRow'] != undefined && value['languageRow'].id ? value['languageRow'].id : null,
      productId: value['id'] != undefined ? value['id'] : null,
      quantity: value['quantity'] != undefined ? value['quantity'] : null,
      purchasePrice: value['purchasePrice'] != undefined ? value['purchasePrice'] : null,
      shadowless: value['shadowless'] != undefined ? value['shadowless'] : null,
      signed: value['signed'] != undefined ? value['signed'] : null,
      staff: value['staff'] != undefined ? value['staff'] : null,
      stamped: value['stamped'] != undefined ? value['stamped'] : null,
      warehouseToId: value['warehouseRow'] != undefined && value['warehouseRow'].id != undefined ? value['warehouseRow'].id : null
    };


  }


  setAll() {
    this.loadingType['MULTIPLE'] = true;
    const productsToPush: any[] = _.cloneDeep(this.copyProducts);

    const productsToPushCopy = productsToPush.map((value: { [k: string]: any }) => this.getModel(value, false))
      .filter((e) => this.filterFN(e)).map(s => _.omitBy({ ..._.omit({ ...s }, 'brandId') }, _.isNil));

    this.saveDefinitive(undefined, productsToPushCopy, TypeSaveInsert.MULTIPLE);

  }

  private resetHeader() {
    if (!this.persistValueOn) {


      if (this.productStructure && this.productStructure.stockfields && this.productStructure.stockfields.length > 0) {

        this.productStructure.stockfields.forEach(field => {
          if (field.type == 'LOOKUP') {
            this.productGestioneFieldsAll(field.field + 'Row').productFieldAll = undefined;
            this.valueFiledAll[field.field + 'Row'] = undefined;
            // this.valueFiledAll['languageRow'] = undefined;

          }

          if (field.type == 'FLAG') {

            this.valueFiledAll[field.field as string] = undefined;
          }
        });

      }


      this.commentsAll = '';
      this.entityAll = undefined;
      this.quantityAll = undefined;
      this.productSelectValueAll = false;

    }


    setTimeout(() => {

      this.resetValueOnFireSubject.next(false);
    }, 2000);


  }


  getImagePath(ff: ProductStructureField): string | null {
    if (ff.field == 'signed' || ff.field == 'firstEdition') {
      return `assets/img/structure/${ff.field}.png`;
    }
    return `assets/img/structure/${ff.field}.svg`;

  }

  setValueFieldAll(field: ProductStructureField) {

    this.valueFiledAll[field.field as string] = !this.valueFiledAll[field.field as string];

    localStorage.setItem(keepValue + field.field, '' + this.valueFiledAll[field.field as string]);


    this.copyProducts.forEach((d) => {
      if (field.field && d && d.active && (d.editable as { [key: string]: any })[field.field]) {
        (d as { [k: string]: any })[field.field as string] = this.valueFiledAll[field.field as string];
      }
    });

  }

  noImage(item: Product) {
    item.card_data.image = environment.DefaultImageMagic;
  }


  setValueField(ff: ProductStructureField, indexProduct: number) {

    (this.copyProducts[indexProduct].editable as { [key: string]: boolean })[ff.field as string] = false;

    (this.copyProducts[indexProduct] as {
      [k: string]: any
    })[ff.field as string] = !(this.copyProducts[indexProduct] as { [k: string]: any })[ff.field as string];


  }


  getValues(values: any[]) {
    if (values) {
      const languages = values.find(d => d?.name == 'Inglese')?.id != undefined ? values : [];
      const conditions = values.find(d => d?.name == 'Mint')?.id != undefined ? values : [];
      const holo = values.find(d => d?.name == 'Regular')?.id != undefined ? values : [];

      if (languages && languages.length > 0) {
        this.copyProducts = _.cloneDeep(this.copyProducts.map(el => {
          el.languages = languages;
          return el;
        }));
      }

      if (conditions && conditions.length > 0) {
        this.copyProducts = _.cloneDeep(this.copyProducts.map(el => {
          el.conditions = conditions;
          return el;
        }));
      }

      if (holo && holo.length > 0) {
        this.copyProducts = _.cloneDeep(this.copyProducts.map(el => {
          el.holos = holo;
          return el;
        }));
      }


      setTimeout(() => {
        this.loadingAll = true;
      }, 0);
    }

  }

  public compareEntity(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.id === o2.id;
  }

  getEntitiesDescription(ent: Entity) {
    let value: any = ent;
    if (value && value.id != null) {
      value = value.defaultDescription || value.name || value.description || value.code || value.id;
    }
    return value;
  }

  saveRow(id: number) {

    this.loadingType[id + TypeSaveInsert.SINGLE] = true;
    const value: { [k: string]: any } = this.copyProducts[id];
    let toSave = this.getModel(value, false);
    toSave = _.omitBy(toSave, _.isNil);

    this.saveDefinitive(id, toSave, TypeSaveInsert.SINGLE);


  }

  saveDefinitive(idRow: number | undefined, itemToSave: WarehouseMovementDTORequiredPrev | WarehouseMovementDTORequiredPrev[], type: TypeSaveInsert) {

    this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));

    this.subs.add(
      this.setInsertService.saveMovements(itemToSave, type, this.sessionId).pipe(
        debounceTime(200),
        map(a => a.data as any[]),
        catchError(err => {
          this.loadAll = false;
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          this.loadingType[(idRow != undefined ? idRow : '') + type] = false;

          return throwError(() => {
            return err;
          });
        })).subscribe(d => {



          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

          this.loadingType[(idRow != undefined ? idRow : '') + type] = false;

          if (!this.sessionId) {

            this.sessionId = (d[0].warehouseSession as WarehouseSession).id;

            this.router.navigateByUrl('app/set-insert-out/' + '' + this.sessionId, {
              skipLocationChange: false,
            }).then(() => {
              this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
              this.dialogService.openSnack('Aggiornamento del id riuscito', 'info', 5000, 'top', 'right');
              this.dialogService.openSnack('Caricamento riuscito', 'info', 5000);
              this.reset();
            });
          }

          this.loadAll = false;
          this.resetValueOnFireSubject.next(!this.persistValueOn);
          this.dialogService.openSnack('Caricamento riuscito', 'info', 5000);
          this.reset();
          this.resetHeader();

        })
    );
  }

  private reset() {

    if (this.copyProducts && this.copyProducts.length > 0) {
      this.copyProducts.map((value: { [k: string]: any }) => {
        if (value['brandId'] == 6) {
          value['holoRow'] = undefined;
        }
        value["conditionRow"] = undefined;
        value["languageRow"] = undefined;
        value['purchasePrice'] = undefined;
        value["quantity"] = undefined;
        this.productStructure?.stockfields?.forEach(field => {
          value[field.field as string] = undefined;
        });
        value['warehouseRow'] = undefined;
        value['comment'] = '';
        return value;
      });

    }



  }

  set entityValueAll(id: number | undefined) {
    this.entityAll = this.dataExtra['warehouses']?.find(d => d && (d.id == id));

    localStorage.setItem(keepValueWarehouse, '' + id);

    if (this.entityAll && this.entityAll.id != undefined) {

      this.errorCustomAll = false;

    } else {

      this.errorCustomAll = true;

      this.entityAll = (this.dataExtra['warehouses'].map((n: Warehouse) => {

        if (id && (n.id as number)?.toString().includes(id.toString() as string)) {
          const a: any = { id: '9999_pp__santiagoMadridAllssWarehouses', name: '' };

          return a;
        }
        return;
      })).find((d: Warehouse) => d && d.id != undefined);

      setTimeout(() => {

        this.genericFieldAll.valueAccessor?.writeValue(undefined);
        this.entityAll = undefined;
        this.errorCustomAll = true;

      }, 0);


    }

    this.copyProducts.map((d) => {

      if (d && d.active && d.editable && d.editable[TypeEditTable.WAREHOUSES]) {
        d.warehouseRow = this.entityAll;
        // this.productGestione(index, false).entityValueWarehouse = this.entityAll?.id || undefined;
      }

    });

  }

  get entityValueAll(): any {
    return this.entityAll?.id;
  }


  public get inverseTranslation(): string {

    if (!this.viewport || !this.viewport["_renderedContentTransform"]) {
      return "60px";
    }

    let offset = this.viewport["_renderedContentOffset"];
    return `-${(parseInt(offset.toString()) - 60).toString()}px`;

  }

  get persistValue() {
    return this.persistValueOn;
  }

  set persistValue(v: boolean) {
    this.persistValueOn = v;

    localStorage.setItem(KeepHeaderValues, '' + this.persistValueOn);

    if (!v) {

      localStorage.removeItem(keepSelectAll);

      this.productStructureCond?.allFields?.forEach(field => {
        localStorage.removeItem(keepValue + field.field);
        localStorage.removeItem(keepValue + field.field + 'Row');

      });

      localStorage.removeItem(keepValueComment);
      localStorage.removeItem(keepValueWarehouse);
      localStorage.removeItem(keepValueQuantity);
      localStorage.removeItem(keepValuePurchasePrice);
    }
  }


  clonedRow(id: number) {
    const arrFirst = _.cloneDeep(this.copyProducts);

    arrFirst.splice(id, 0, this.copyProducts[id]);

    this.copyProducts = _.cloneDeep(arrFirst);
  }

  deletedRow(id: number) {

    const arrFirst = _.cloneDeep(this.copyProducts);

    arrFirst.splice(id, 1);

    this.copyProducts = _.cloneDeep(arrFirst);

  }

  loadHeaderValues() {

    this.productSelectValueAll = localStorage.getItem(keepSelectAll) != 'undefined' && localStorage.getItem(keepSelectAll) == 'true' ? true : false ;
    if (  localStorage.getItem(keepSelectAll) != 'undefined' ) {

      console.log('productSelectValueAll', this.productSelectValueAll);

      this.loadStructureForSelectAll(this.productSelectValueAll);

    }

    this.productStructureCond?.allFields?.forEach(field => {
      if (field.stock) {

        if (field.field == 'language' || field.field == 'condition' || field.field == 'holo') {
          if (localStorage.getItem(keepValue + field.field + 'Row') != 'undefined') {
            this.productGestioneFieldsAll((field.field as string) + 'Row').productFieldAll = parseInt(localStorage.getItem(keepValue + field.field + 'Row') as string);
          }

        } else {
          this.valueFiledAll[field.field as string] = localStorage.getItem(keepValue + field.field as string);

          this.copyProducts.forEach((d) => {
            if (field.field && d && d.active && (d.editable as { [key: string]: any })[field.field]) {
              (d as { [k: string]: any })[field.field as string] = this.valueFiledAll[field.field as string];
            }
          });
        }
      }
    });

    // comments

    this.commentsAll = localStorage.getItem(keepValueComment) && localStorage.getItem(keepValueComment) != 'undefined' ? localStorage.getItem(keepValueComment) as string : '';

    // warehouse

    setTimeout(() => {
      this.entityValueAll = localStorage.getItem(keepValueWarehouse) && localStorage.getItem(keepValueWarehouse) != 'undefined' ? parseInt(localStorage.getItem(keepValueWarehouse) as string) : undefined;
    }, 1000);

    // quantity

    this.quantityAll = localStorage.getItem(keepValueQuantity) && localStorage.getItem(keepValueQuantity) != 'undefined' ? parseInt(localStorage.getItem(keepValueQuantity) as string) : undefined;

    // purchase 

    this.purchasePriceModel = localStorage.getItem(keepValuePurchasePrice) && localStorage.getItem(keepValuePurchasePrice) != 'undefined' ? parseInt(localStorage.getItem(keepValuePurchasePrice) as string) : undefined;

  }

  ngOnInit(): void {
    let firstUlt = true;

    this.persistValueOn = localStorage.getItem(KeepHeaderValues) == 'true' ? true : false;

    this.activateRouter.params.pipe().subscribe(rt => this.sessionId = rt && rt['id'] ? parseInt(rt['id']) : undefined);

    this.subs.add(

      this.overLayConected?.overlayOutsideClick.pipe(debounceTime(10)).subscribe(() => {
        this.isOpen = {};
      }),

      this.store.pipe(select(selectFeatureCode), debounceTime(50), distinctUntilChanged()).subscribe(featureCode => {
        this.featureCode = featureCode || undefined;
      }),

      this.dataSource.subscribe(ds => {

        if (this.getRowTable) {
          let cont = 0;
          ds.data = ds.data.map((d: any) => Object.assign(d, { ...d, idRowTable: cont += 1 })) as [];
        }

        this.sortStatus = {};

        this.clearSelection();

        ds.tableStatus.sort = ds.tableStatus.sort || [];

        this.tableData = ds;

        ds.tableStatus.sort.forEach((s: Sort, index: number) => {
          const tableField = this.config?.columnsFields?.find(f => f.sortExpression === s.field);
          if (tableField) {
            this.sortStatus[tableField.id] = {
              field: tableField,
              direction: SortDirection[s.direction.toUpperCase() as SortDirection],
              position: index
            };
          }
        });

        // FIXME: CLICK SEARCH ON FILTER SECTION
        this.sharedService.nextNormalData.pipe(take(1), distinctUntilChanged()).subscribe(normal => {

          if (normal.reload) {

            const productStructure = normal?.crudRequest?.customData?.productStructure;

            this.noPaginationAndSort = false;
            this.canLoadNormal = true;
            this.youCanReload = true;

            if (!this.persistValueOn) {

              if (this.productStructure && this.productStructure.stockfields && this.productStructure.stockfields.length > 0) {

                this.productStructure.stockfields.forEach(field => {
                  if (field.type == 'LOOKUP') {
                    this.productGestioneFieldsAll(field.field + 'Row').productFieldAll = undefined;
                  }
                  if (field.type == 'FLAG') {
                    this.valueFiledAll[field.field as string] = undefined;

                  }
                });

              }

              this.commentsAll = '';
              this.entityAll = undefined;
              this.quantityAll = undefined;
              this.productSelectValueAll = false;
            }

            if (productStructure && this.productStructure && productStructure.id != this.productStructure.id) {
              if (this.productStructure && this.productStructure.stockfields && this.productStructure.stockfields.length > 0) {
                this.productStructure.stockfields.forEach(field => {

                  if (field.type == 'LOOKUP') {
                    this.productGestioneFieldsAll(field.field + 'Row').productFieldAll = undefined;
                  }

                  if (field.type == 'FLAG') {
                    this.valueFiledAll[field.field as string] = undefined;
                  }

                });

              }

              this.commentsAll = '';
              this.entityAll = undefined;
              this.quantityAll = undefined;
              this.productSelectValueAll = false;

              this.copyProducts.map((d) => {
                d.languageRow = undefined;
                d.conditionRow = undefined;
                d.holoRow = undefined;
                d.active = false;
                return d;
              });
            }

          }
        });

        if (this.canLoadNormal) {

          this.copyProducts = [...this.tableData.data.map((d: any, i: any) => {


            if (!d.editable || isEmptyObject(d.editable)) {
              d.editable = {};
            }

            d.editable[TypeEditTable.COMMENT] = d.editable[TypeEditTable.COMMENT] != undefined ? d.editable[TypeEditTable.COMMENT]  :  true;

            d.editable[TypeEditTable.QUANTITY] = d.editable[TypeEditTable.QUANTITY] != undefined ? d.editable[TypeEditTable.QUANTITY]  :  true;

            d.editable[TypeEditTable.PURCHASEPRICE] = d.editable[TypeEditTable.PURCHASEPRICE] != undefined ? d.editable[TypeEditTable.PURCHASEPRICE]  :  true;

            d.editable[TypeEditTable.WAREHOUSES] = d.editable[TypeEditTable.WAREHOUSES] != undefined ? d.editable[TypeEditTable.WAREHOUSES]  :  true;

            d.editable[TypeEditTable.HOLOS] = d.editable[TypeEditTable.HOLOS] != undefined ? d.editable[TypeEditTable.HOLOS]  :  true;

            d.editable[TypeEditTable.LANGUAGE] = d.editable[TypeEditTable.LANGUAGE] != undefined ? d.editable[TypeEditTable.LANGUAGE]  :  true;

            d.editable[TypeEditTable.CONDITION] = d.editable[TypeEditTable.CONDITION] != undefined ? d.editable[TypeEditTable.CONDITION]  :  true;

            if (this.persistValueOn && this.productSelectValueAll) {
              d.active = this.copyProducts[i]?.active != undefined ?  this.copyProducts[i]?.active : true;

              if (this.commentsAll && d.active) {
                d.comment = this.copyProducts[i]?.comment || undefined;
              }

              if (this.quantityAll && d.active) {
                d.quantity = this.copyProducts[i]?.quantity || undefined;
              }

              if (this.entityAll && d.active) {
                d.warehouseRow = this.entityAll;
              }

              if (this.purchasePriceModel != undefined && d.active) {
                d.purchasePrice = this.copyProducts[i]?.purchasePrice;
              }

              this.productStructure?.stockfields?.forEach(field => {
                if (field && field.field && d.active && this.valueFiledAll[field.field]) {
                  d[field.field] = this.valueFiledAll[field.field]
                }
              });
            }

            return d;
          })];

          this.loadStructure();
        }


        if (this.firstLoads) {
          this.firstLoads = false;
          //this.loadCalls(this.validKeyValue, false);
        }

        if (firstUlt) {
          firstUlt = false;

          if (this.persistValueOn) {
            this.loadHeaderValues();
          }

          //mi serve per la gestione salva dati
          // this.readyCall(false);
        }

        this.setInsertService.youCanClickButtonReload.next(false);

      })
    );

    this.init();
    this.setAvailable = false;

  }

  showImage(is: any) {
    Object.keys(this.isOpen).forEach(d => {
      if (this.isOpen[is] != this.isOpen[d]) {
        this.isOpen[d] = false;
      } else {
        // faccio qualcosa

      }
    });
    this.isOpen[is] = !this.isOpen[is]
  }

  ngAfterViewInit() {

    this.subs.add(
      merge(
        this.sort,
        this.paginator?.page.pipe(debounceTime(environment.defaultDebounceTime), distinctUntilChanged())
      ).pipe(
        map(() => {
          const tableStatus: TableStatus = this.tableData.tableStatus;
          this.selectionStatus = {};

          this.selection = { all: false, rows: [] };

          this.rowSelect.emit(this.selection);

          if (this.paginator && this.config) {
            this.config.pageSize = this.paginator.pageSize;
            tableStatus.page = {
              pageIndex: this.paginator.pageIndex,
              pageSize: this.paginator.pageSize,
              length: this.paginator.length
            };
          }

          tableStatus.sort = _.sortBy(Object.values(this.sortStatus), 'position').map((sortItem: SortItem) => {
            return { field: sortItem.field.sortExpression, direction: sortItem.direction };
          });

          return tableStatus;
        })
      ).subscribe(tableStatus => {
        this.status.emit(tableStatus);
      })
    );
  }

  ngOnDestroy() {

    this.subs2.unsubscribe();
    this.subs.unsubscribe();

    // !fixme , controllare se questo chiude le chiamatte

    this.instanciaError?.unsubscribe();
    this.instanciaIncluded?.unsubscribe();

  }

  clearSelection() {
    this.selectionStatus = {};
    this.selection = { rows: [], all: false };
  }

  get pageIndex() {

    return this.valuePagueIndex

  }

  set pageIndex(v: any) {

    this.valuePagueIndex = v;

  }

  allsValid() {
    const v = this.copyProducts.filter(item => item && item.brandId == 6 ? (item.quantity != undefined && item.languageRow && item.conditionRow && item.warehouseRow && item.holoRow) :
      (item.quantity != undefined && item.languageRow && item.conditionRow && item.warehouseRow));

    this.allsValidValue = v.length;

    return v && v.length > 0;
  }

  productGestioneFieldsAll(field: string) {

    const me = this;

    return {

      get productFieldAll() {

        return me.valueFiledAll[field];

      },

      set productFieldAll(value: number | undefined) {
        me.valueFiledAll[field] = value;

        localStorage.setItem(keepValue + field, '' + me.valueFiledAll[field]);

        me.copyProducts.map((d) => {

          if (d && d.active && d.editable && d.editable[TypeEditTable.HOLOS]) {
            d.holoRow = d.holos?.find(p => p.id == me.valueFiledAll['holoRow']);
          }

          if (d && d.active && d.editable && d.editable[TypeEditTable.LANGUAGE]) {
            d.languageRow = d.languages?.find(p => p.id == me.valueFiledAll['languageRow']);
          }

          if (d && d.active && d.editable && d.editable[TypeEditTable.CONDITION]) {
            d.conditionRow = d.conditions?.find(p => p.id == me.valueFiledAll['conditionRow']);
          }
          return d;
        });

      }


    }


  }


  productGestione(index: any, isManual: boolean, idProductIN?: number, inputValue?: any) {

    const me = this;

    return {
      get productSelect() {
        return me.copyProducts[index].active;
      },
      set productSelect(v: boolean | undefined) {
        me.productSelectValue[index] = v;
        me.copyProducts[index].active = me.productSelectValue[index];

        // for comment
        if (me.copyProducts[index].editable && (me.copyProducts[index].editable as {
          [key: string]: any
        })[TypeEditTable.COMMENT]) {
          me.productGestione(index, false).productComment = me.comentsValue;

        }
        // for quantity

        if (me.copyProducts[index].editable && (me.copyProducts[index].editable as {
          [key: string]: any
        })[TypeEditTable.QUANTITY]) {
          me.productGestione(index, false).productQuantity = me.quantityValue;

        }

        // for purchasePrice

        if (me.copyProducts[index].editable && (me.copyProducts[index].editable as {
          [key: string]: any
        })[TypeEditTable.PURCHASEPRICE]) {
          me.productGestione(index, false).productPurchasePrice = me.purchasePriceValue;

        }


        // fields
        Object.keys(me.valueFiledAll)?.forEach(dField => {
          if (me.valueFiledAll && me.valueFiledAll[dField] != undefined) {

            // FIXME: meter la lista de campos 

            if (me.copyProducts[index].editable && me.copyProducts[index].editable?.[dField]) {
              (me.copyProducts[index] as { [k: string]: any })[dField] = me.valueFiledAll[dField];
            }


            if (me.copyProducts[index].editable && me.copyProducts[index].editable?.[TypeEditTable.HOLOS]) {
              if (me.valueFiledAll['holoRow'] != undefined) {
                me.productGestione(index, false).entityValueHolos = me.valueFiledAll['holoRow'];
              }
            }

            if (me.copyProducts[index].editable && me.copyProducts[index].editable?.[TypeEditTable.LANGUAGE]) {
              if (me.valueFiledAll['languageRow'] != undefined) {
                me.productGestione(index, false).entityValueLingua = me.valueFiledAll['languageRow'];
              }
            }
            if (me.copyProducts[index].editable && me.copyProducts[index].editable?.[TypeEditTable.CONDITION]) {
              if (me.valueFiledAll['conditionRow'] != undefined) {
                me.productGestione(index, false).entityValueCondition = me.valueFiledAll['conditionRow'];
              }
            }
          }

        });


        if (me.copyProducts[index].editable && me.copyProducts[index].editable?.[TypeEditTable.WAREHOUSES]) {
          // me.entity[index] = me.entityAll;
          (me.copyProducts[index] as { [k: string]: any })['warehouseRow'] = me.entityAll;
        }
      },

      get productComment(): string | undefined {
        return me.copyProducts[index].comment
      },
      set productComment(v: string | undefined) {
        me.copyProducts[index].comment = v;
        if (isManual) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.COMMENT] = false;
          }
        }
      },


      get productQuantity(): number | undefined {
        return me.copyProducts[index]['quantity']
      },

      set productQuantity(v: number | undefined) {
        me.copyProducts[index]['quantity'] = v;
        if (isManual) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.QUANTITY] = false;
          }
        }
      },

      get productPurchasePrice(): number | undefined {
        return me.copyProducts[index]['purchasePrice']
      },

      set productPurchasePrice(v: number | undefined) {
        me.copyProducts[index]['purchasePrice'] = v;
        if (isManual) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.PURCHASEPRICE] = false;
          }
        }
      },

      set entityValueWarehouse(id: number | undefined) {
        (me.copyProducts[index] as {
          [k: string]: any
        })['warehouseRow'] = (me.copyProducts[index])['warehouses']?.find((w: any) => w.id == id);

        if ((me.copyProducts[index] as { [k: string]: any })['warehouseRow'] && (me.copyProducts[index] as {
          [k: string]: any
        })['warehouseRow']?.id != undefined) {
          me.errorCustom[index + '_' + idProductIN] = false;
        } else {
          me.errorCustom[index + '_' + idProductIN] = true;
          (me.copyProducts[index] as {
            [k: string]: any
          })['warehouseRow'] = ((me.copyProducts[index].warehouses as Warehouse[]).map(n => {
            if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
              const a: any = { id: '9999999999___santiagolegustaelWarehouse', name: '' };
              return a;
            }
            return;
          })).find(d => d && d.id != undefined);
          setTimeout(() => {
            (me.copyProducts[index] as { [k: string]: any })['warehouseRow'] = undefined;
            me.errorCustom[index + '_' + idProductIN] = true;

          }, 0);
        }

        if (isManual && !me.errorCustom[index + '_' + idProductIN]) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.WAREHOUSES] = false;
          }
        } else {

          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {

            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.WAREHOUSES] = true;

            setTimeout(() => {
              me.errorCustom[index + '_' + idProductIN] = false;
            }, 0);

          }

        }
      },

      get entityValueWarehouse(): number | undefined {
        return (me.copyProducts[index] as { [k: string]: any })['warehouseRow']?.id
      },

      set entityValueHolos(id: number | undefined) {
        (me.copyProducts[index] as {
          [k: string]: any
        })['holoRow'] = (me.copyProducts[index])['holos']?.find((w: any) => w.id == id);

        if ((me.copyProducts[index] as { [k: string]: any })['holoRow'] && (me.copyProducts[index] as {
          [k: string]: any
        })['holoRow']?.id != undefined) {

          me.errorCustomHolo[index + '_' + idProductIN] = false;

          if (((me.copyProducts[index] as {
            [k: string]: any
          })['holoRow']?.id?.toString().length == inputValue?.value?.length)) {

          } else {

            if (inputValue) {

              me.errorCustomHolo[index + '_' + idProductIN] = true;

              (me.copyProducts[index] as {
                [k: string]: any
              })['holoRow'] = ((me.copyProducts[index].holos as Holo[])?.map(n => {
                if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
                  const a: any = { id: '9999999999___santiagolegustaelrHolos', name: '' };
                  return a;
                }
                return;
              })).find(d => d && d.id != undefined);

              setTimeout(() => {
                (me.copyProducts[index] as { [k: string]: any })['holoRow'] = undefined;
                me.errorCustomHolo[index + '_' + idProductIN] = true;
              }, 0);
            }
          }
        } else {
          me.errorCustomHolo[index + '_' + idProductIN] = true;

          (me.copyProducts[index] as {
            [k: string]: any
          })['holoRow'] = ((me.copyProducts[index].holos as Holo[])?.map(n => {

            if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
              const a: any = { id: '9999999999___santiagolegustaelrHolos', name: '' };
              return a;
            }
            return;
          }))?.find(d => d && d.id != undefined);
          setTimeout(() => {
            (me.copyProducts[index] as { [k: string]: any })['holoRow'] = undefined;
            me.errorCustomHolo[index + '_' + idProductIN] = true;
          }, 0);
        }

        if (isManual && !me.errorCustomHolo[index + '_' + idProductIN]) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.HOLOS] = false;
          }
        } else {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.HOLOS] = true;

            setTimeout(() => {
              me.errorCustomHolo[index + '_' + idProductIN] = false;
            }, 0);
          }
        }

      },

      get entityValueHolos(): number | undefined {
        return (me.copyProducts[index] as { [k: string]: any })['holoRow']?.id
      },


      set entityValueCondition(id: number | undefined) {

        (me.copyProducts[index] as {
          [k: string]: any
        })['conditionRow'] = (me.copyProducts[index])['conditions']?.find((w: any) => w.id == id);

        if ((me.copyProducts[index] as { [k: string]: any })['conditionRow'] && (me.copyProducts[index] as {
          [k: string]: any
        })['conditionRow']?.id != undefined) {
          me.errorCustomCondizione[index + '_' + idProductIN] = false;

          if (((me.copyProducts[index] as {
            [k: string]: any
          })['conditionRow']?.id?.toString().length == inputValue?.value?.length)) {

          } else {

            if (inputValue) {

              me.errorCustomCondizione[index + '_' + idProductIN] = true;

              (me.copyProducts[index] as {
                [k: string]: any
              })['conditionRow'] = ((me.copyProducts[index].conditions as Condition[])?.map(n => {
                if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
                  const a: any = { id: '9999999999___santiagolegustaelrConditions', name: '' };
                  return a;
                }
                return;
              }))?.find(d => d && d.id != undefined);
              setTimeout(() => {

                (me.copyProducts[index] as { [k: string]: any })['conditionRow'] = undefined;
                me.errorCustomCondizione[index + '_' + idProductIN] = true;

              }, 0);
            }


          }


        } else {
          me.errorCustomCondizione[index + '_' + idProductIN] = true;
          (me.copyProducts[index] as {
            [k: string]: any
          })['conditionRow'] = ((me.copyProducts[index].conditions as Condition[])?.map(n => {
            if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
              const a: any = { id: '9999999999___santiagolegustaelrConditions', name: '' };
              return a;
            }
            return;
          })).find(d => d && d.id != undefined);
          setTimeout(() => {
            (me.copyProducts[index] as { [k: string]: any })['conditionRow'] = undefined;
            me.errorCustomCondizione[index + '_' + idProductIN] = true;

          }, 0);
        }

        if (isManual && !me.errorCustomCondizione[index + '_' + idProductIN]) {
          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {
            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.CONDITION] = false;
          }
        } else {

          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {

            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.CONDITION] = true;

            setTimeout(() => {
              me.errorCustomCondizione[index + '_' + idProductIN] = false;
            }, 0);

          }

        }

        // me.allsValid();
        // me.singleValid(index);
      },

      get entityValueCondition(): number | undefined {
        return (me.copyProducts[index] as { [k: string]: any })['conditionRow']?.id
      },

      set entityValueLingua(id: number | undefined) {

        (me.copyProducts[index] as {
          [k: string]: any
        })['languageRow'] = (me.copyProducts[index])['languages']?.find((w: any) => w.id == id);

        if ((me.copyProducts[index] as { [k: string]: any })['languageRow'] && (me.copyProducts[index] as {
          [k: string]: any
        })['languageRow']?.id != undefined) {
          me.errorCustomLingua[index + '_' + idProductIN] = false;


          if (((me.copyProducts[index] as {
            [k: string]: any
          })['languageRow']?.id?.toString().length == inputValue?.value?.length)) {


          } else {

            if (inputValue) {

              me.errorCustomLingua[index + '_' + idProductIN] = true;

              (me.copyProducts[index] as {
                [k: string]: any
              })['languageRow'] = ((me.copyProducts[index].languages as Language[])?.map(n => {
                if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
                  const a: any = { id: '9999999999___santiagolegustaelrLingua', name: '' };
                  return a;
                }
                return;
              })).find(d => d && d.id != undefined);

              setTimeout(() => {

                (me.copyProducts[index] as { [k: string]: any })['languageRow'] = undefined;
                me.errorCustomLingua[index + '_' + idProductIN] = true;

              }, 0);
            }


          }


        } else {
          me.errorCustomLingua[index + '_' + idProductIN] = true;
          (me.copyProducts[index] as {
            [k: string]: any
          })['languageRow'] = ((me.copyProducts[index].languages as Language[]).map(n => {
            if (id != undefined && (n.id as number)?.toString().includes(id.toString() as string)) {
              const a: any = { id: '9999999999___santiagolegustaelrLingua', name: '' };
              return a;
            }
            return;
          })).find(d => d && d.id != undefined);
          setTimeout(() => {
            (me.copyProducts[index] as { [k: string]: any })['languageRow'] = undefined;
            me.errorCustomLingua[index + '_' + idProductIN] = true;


          }, 0);


        }

        if (isManual && !me.errorCustomLingua[index + '_' + idProductIN]) {

          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {

            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.LANGUAGE] = false;

          }

        } else {

          if (me.copyProducts[index] && me.copyProducts[index].editable != undefined && _.isObject(me.copyProducts[index].editable)) {

            (me.copyProducts[index].editable as { [key: string]: any })[TypeEditTable.LANGUAGE] = true;

            setTimeout(() => {
              me.errorCustomLingua[index + '_' + idProductIN] = false;
            }, 0);

          }

        }

      },

      get entityValueLingua(): number | undefined {
        return (me.copyProducts[index] as { [k: string]: any })['languageRow']?.id
      }

    }

  }


  getChangeForm(f: NgForm) {
    setTimeout(() => {
      Object.keys(f.controls).forEach(item => {
        if ((this.errorCustom[item] && f.controls[item].value)) {
          f.controls[item].reset();
        }
      })
    }, 10);
  }

  getChangeFormValueCustom(f: NgForm, input: any) {


    setTimeout(() => {

      Object.keys(f.controls).forEach(item => {
        if (
          (this.errorCustomHolo[item] && f.controls[item].value != undefined)
          || (this.errorCustomCondizione[item] && f.controls[item].value != undefined)
          || (this.errorCustomLingua[item] && f.controls[item].value != undefined)
        ) {
          f.controls[item].reset();
          input.valueAccessor?.writeValue(undefined);
        }

      })

    }, 10)

  }


  private loadStructure() {


    if (this.copyProducts && this.copyProducts.length > 0) {

      this.productStructure = this.productStructureCond;

      if (!this.productStructure) {

        this.productStructure = this.productStructureService.findStructure((this.copyProducts.find((d) => d && d.categoryId && d.brandId)?.categoryId as number), (this.copyProducts.find((d) => d && d.categoryId && d.brandId)?.brandId as number));

      }


      this.productStructure.allFields?.forEach(field => {
        this.copyProducts.map(product => {

          if (field && field.field != undefined && product.editable != undefined) {
            product.editable[field.field as string] = true;
          }

          return product;
        });
      });

    } else {
      this.productStructure = undefined;
    }

  }


  goToPage(ev: KeyboardEvent | Event) {


    const quantitaPage = this.tableData.tableStatus.page.length / this.tableData.tableStatus.page.pageSize;
    let value = parseFloat((ev.target as any)?.value);

    const max = Math.ceil(quantitaPage);


    if ((value - 1) >= max) {

      value = max;
      this.dialogService.openSnack(`non c'è piu pagina, il massimo e ${(max)} `, '', 5000);

    }

    if ((quantitaPage + 1) >= value || _.isNaN(value)) {
      this.paginator.pageIndex = isNaN(value) ? 0 : (value - 1);

      this.paginator.page.next({
        pageIndex: this.paginator.pageIndex,

        pageSize: this.paginator.pageSize,

        length: this.paginator.length
      });

    } else {
      this.dialogService.openSnack(`non c'è piu pagina, il massimo e ${(max)} `, '', 5000);
    }
  }

  getActions(type: string): TableAction[] {
    return this.actions[type];
  }


  public showColor(v: string) {
    return convertirAHexadecimal(v.toLowerCase());
  }


  private init() {
    this.actions = {};

    if (this.config && !this.config.columnsFields && !this.displayedColumns) {
      this.displayedColumns = ['no_col_defined'];
    }
    if (this.config && !this.config.columnsFields && this.displayedColumns) {
      this.config.columnsFields = this.displayedColumns.map(value => new TableField(value, undefined, 'default.' + value));
    } /*else if (!this.displayedColumns && this.config.columnsFields) {
      this.displayedColumns = this.config.columnsFields.map( value => value.id);
    }*/

    this.loadConfig();

    this.store.pipe(select(selectAuth)).subscribe(auth => {
      // this.rolesProp = auth && auth.roles && auth.roles.length > 0 ? [...auth.roles] : [];
      this.fullAuth = auth;

      if (this.fullAuth && this.fullAuth.username) {
        this.validKeyValue = KEYCONSTANTSETINSERT;
      }
    });

  }

  getValid(products: Product[]) {
    // .filter(d => this.filterFN(d));
    return products.map(a => this.getModel(a, true))
  }

  private loadCalls(validKey: string, isClicked: boolean) {
    const me = this;

    this.subs.add(

      this.setInsertService.getExisteLoads(validKey).pipe(distinctUntilChanged(), debounceTime(10),
        catchError(err => {
          return throwError(() => {

            const errL: any = err;
            if (errL.status == 404) {
              if (!errL.error) {
                errL.error = 'Sessione set insert vuota!';
              }
            }

            // fixme when error its loaded init load items oncache
            this.youCanReload = true;
            if (this.firstLoadItems) {
              this.firstLoadItems = false;
              this.readyCall(err);
            }
            return errL as HttpErrorResponse;


          });
        })).subscribe(valid => {

          this.existLoad = true;
          this.canLoadNormal = false;

          if (isClicked) {
            this.dialog.open(DialogLoadSetInsertComponent, {
              data: { key: validKey },
              height: '30%',
              width: '80%',
              id: keyDialog,
              disableClose: true
            });
          }




          this.dialog.getDialogById(keyDialog)?.afterClosed().subscribe(result => {

            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));

            if (result) {

              // this.firstLoads = true;
              this.canLoadNormal = false;


              this.subs.add(
                this.setInsertService.getLoads(validKey).pipe(distinctUntilChanged(), map(res => res.data), catchError((err: HttpErrorResponse) => {
                  return throwError(() => {
                    const errL: any = err;
                    if (errL.status == 404) {
                      if (!errL.error) {
                        errL.error = 'Sessione set insert vuota!';
                      }
                    }

                    // fixme when error its loaded init load items oncache
                    this.youCanReload = true;
                    if (this.firstLoadItems) {
                      this.firstLoadItems = false;
                      this.readyCall(true);
                    }
                    return errL as HttpErrorResponse;
                  })
                })).subscribe(data => {

                  this.itemsToLoadOnSave = data.items as Product[];

                  const item = _.cloneDeep(this.itemsToLoadOnSave);

                  if (item) {
                    this.productStructure = this.productStructureService.findStructure(item[0].categoryId, item[0].brandId);
                  }

                  this.firstLoads = true;

                  this.canLoadNormal = false;

                  this.noPaginationAndSort = true;

                  this.saveOnFilter(data.filter);

                  this.formatValue(this.itemsToLoadOnSave);

                  // this.sharedService.nextReloadSave(true);

                  this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

                  this.youCanReload = true;
                })
              );

            } else {

              this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

              this.youCanReload = true;

              if (this.secondLoadItems) {
                this.secondLoadItems = false;

              }

            }

            setTimeout(() => { this.readyCall(false); }, 5000);



          })

        }),
    );
  }

  formatValue(values: Product[]) {
    values.map(d => {d.warehouses = this.dataExtra['warehouses']; return d;});

    this.copyProducts = [...values];

    this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
  }

  validInput(id: any | undefined, value: HTMLInputElement) {

    if (value != undefined && value.value != undefined && parseInt(value.value) <= 0 && id) {
      value.value = '';
      this.productGestione(id, true).productQuantity = undefined;
    } else {

      if (value != undefined && value.value != undefined && parseInt(value.value) <= 0) {

        value.value = '';
        this.quantityAll = undefined;

      }


    }
  }

  validInputDouble(id: any | undefined, value: HTMLInputElement) {

    if (value != undefined && value.value != undefined && parseInt(value.value) < 0 && id) {
      value.value = '';
      this.productGestione(id, true).productPurchasePrice = undefined;
    } else {

      if (value != undefined && value.value != undefined && parseInt(value.value) < 0) {

        value.value = '';
        this.purchasePriceModel = undefined;

      }


    }
  }

  private readyCall(error: boolean) {

    const vProp1 = _.cloneDeep(this.copyProducts);

    this.validProductsProp = this.getValid(vProp1);


    if (this.validProductsProp
      && this.validProductsProp.length > 0
      && this.youCanReload
      && this.dialog.getDialogById(keyDialog)?.getState() == undefined
      && this.filtersOff.customData
      && this.filtersOff.customData.brand && this.filtersOff.customData.brand?.id) {

      if (error) {

        if (this.instanciaIncluded) {
          this.instanciaIncluded.unsubscribe();
        }

        this.instanciaError = interval(10000)
          .pipe(

            mergeMap((v: any) => {

              const vProp2 = _.cloneDeep(this.copyProducts);
              this.validProductsProp = this.getValid(vProp2);

              return this.setInsertService.saveObjectKey((this.validKey), {

                items: this.validProductsProp,
                filter: this.filtersOff

              });

            }),

            distinctUntilChanged(),

            catchError(a => {

              this.loadingSave = false;
              return throwError(() => a);

            }),

            takeWhile(() => (this.productStructureCond != undefined) && this.router.url.includes('set-insert-out')),
          )
          .subscribe(data => {

            this.existLoad = true;



          })

      } else {

        if (this.instanciaError) {
          this.instanciaError.unsubscribe();
        }

        this.instanciaIncluded = interval(10000)
          .pipe(
            mergeMap((v: any) => {
              const vProp2 = _.cloneDeep(this.copyProducts);
              this.validProductsProp = this.getValid(vProp2);

              return this.setInsertService.saveObjectKey((this.validKey), {
                items: this.validProductsProp,
                filter: this.filtersOff
              });
            }),

            distinctUntilChanged(),
            catchError(a => {
              this.loadingSave = false;
              return throwError(() => a);
            }),

            takeWhile(() => (this.productStructureCond != undefined) && this.router.url.includes('set-insert-out')),
          )
          .subscribe(data => {
            this.existLoad = true;

          });
      }
    }
  }

  private readyCallActive() {

    this.loadingSave = true;
    const v = _.cloneDeep(this.copyProducts);

    const validProducts = this.getValid(v);
    const filter: DynaCrudRequest = this.filtersOff;


    if (validProducts && validProducts.length > 0) {
      this.subs.add(
        this.setInsertService.saveObjectKey((this.validKey), {
          items: validProducts,
          filter: filter,
          all: this.productSelectValueAll
        }).pipe(debounceTime(200), distinctUntilChanged(),
          catchError(a => {
            this.loadingSave = false;
            return throwError(() => a);
          }),

        ).subscribe(d => {

          this.existLoad = true;
          this.loadingSave = false;
          this.dialogService.openSnack('Data loaded!', 'info', 4000);
        })
      );

    } else {
      this.loadingSave = false;
      this.dialogService.openSnack('ancora nessun prodotto da salvare', 'info', 4000)
    }
  }

  saveOnFilter(filter: DynaCrudRequest) {
    if (filter.searchRequest?.pagination) {
      filter.searchRequest.pagination = _.omit(filter.searchRequest.pagination, ['active']);
    }

    setTimeout(() => {
      this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, filter));
    }, 0);

    setTimeout(() => {
      this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, filter.searchRequest));
    }, 500);
  }

  private loadConfig() {
    if (this.config) {
      this.selectMode = this.config.selectionMode || this.selectMode;
      if (!this.config.pageSize) {
        this.config.pageSize = 20;
      }

      // facciamo vedere sempre il sort clear
      if (this.config.actions || true) {
        const _SELF = this;
        if (this.config.actions) {
          Object.keys(TableActionType).forEach(value => {
            if (_SELF.config?.actions) {
              _SELF.actions[value] = _SELF.config.actions.filter(ac => ac?.type === value)
            }
          });
        }


        if (this.displayedColumns && this.getActions('RECORD').length > 0 && !this.displayedColumns.includes('actions')) {
          this.displayedColumns.push('actions');
        }
      }

      if (this.config.selectionMode === SelectionMode.multiple) {
        if (this.displayedColumns && !this.displayedColumns.includes('multi_selection')) {
          this.displayedColumns.splice(0, 0, 'multi_selection');
        }
      }
    }
  }

  clearSort() {
    this.sortStatus = {};
    this.sortSubject.next(this.sortStatus);
  }


  setSort(columnField: ColumnFieldNormal) {
    if (this.noPaginationAndSort) {
      return;
    }
    const value = new TableField(columnField.$id, undefined, '', columnField.$id, columnField.$sortable, columnField.$sortExpression || undefined /*... atrrb, others*/)
    if (!value || !value.sortable) {
      return;
    }
    const sortItem: SortItem = this.sortStatus[value.id];
    // prima volta
    if (!sortItem) {
      this.sortStatus[value.id] = {
        field: value,
        position: Object.keys(this.sortStatus).length,
        direction: SortDirection.ASC
      };
    } else if (sortItem && sortItem.direction === SortDirection.ASC) {
      sortItem.direction = SortDirection.DESC;
    } else {
      this.sortStatus[value.id] = undefined as any;
      delete this.sortStatus[value.id];
    }
    _.sortBy(Object.values(this.sortStatus), 'position').forEach((si: SortItem, index: number) => si.position = index);
    this.sortSubject.next(this.sortStatus);
  }

  isSorted(value: Object) {
    return Object.keys(value) && Object.keys(value).length && Object.keys(value).length > 0;
  }

}




@Component({
  selector: 'dialog-load-set-insert',
  templateUrl: 'dialog-load-set-insert.html',

})
export class DialogLoadSetInsertComponent implements OnInit, OnDestroy {
  private subs = new SubSink();

  constructor(@Inject(MAT_DIALOG_DATA) public data: { key: string, valid: Observable<boolean> },
    public dialogRef: MatDialogRef<DialogLoadSetInsertComponent>, private setInsertService: SetInsertService, private store: Store<State>) {
  }

  ngOnInit(): void {
    this.dialogRef.afterClosed().pipe().subscribe(d => {
      if (d) {
        // false desabilita el pulsante en el modal
        this.setInsertService.youCanClickButtonReload.next(false);
      }
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  disabled(): Observable<boolean> {

    return this.setInsertService.youCanClickButton();

  }


  deleteLoad() {

    this.subs.add(
      this.setInsertService.deleteLoads(this.data.key).pipe(distinctUntilChanged()).subscribe(d => {

        if (d) {
          this.dialogRef.close();
          // close clear items
          // this.sharedService.nextNormalDataSave(true);
        }
      })
    );

  }
}
