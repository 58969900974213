import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { Filter, OperationType, Sort, SortDirection } from 'src/app/core/models/dynacrud';
import { Product, WarehouseSessionStatus } from 'src/app/shared/models/entities';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { DynaConfActionType, DynaCrudRequest, selectDynaCrudRequest, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { SessionStatusService, statusSession } from '../../../services/session-status.service';
import { Motivation } from '../../../models/entities';
import { isNullOrUndefined } from 'is-what';
import { SessionWrapperService } from '../../../services/session-wrapper.service';
import { SharedService } from 'src/app/shared/services/shared.service';

@Component({
  selector: 'app-session-filter',
  templateUrl: './session-filter.component.html',
  styleUrls: ['./session-filter.component.scss']
})
export class SessionFilterComponent implements OnInit, OnDestroy {

  private subs = new SubSink();
  sortItems: Sort[] = [];
  showStatus!: boolean;
  statusCount: statusSession[] | undefined;

  customData: {
    product: Product | undefined,
    selectedStatus: WarehouseSessionStatus | undefined,
    motivation: Motivation | undefined
  } = { selectedStatus: undefined, motivation: undefined, product: undefined };
  private crudRequest: DynaCrudRequest = {
    searchRequest: {
      filter: {
        filterBy: {},
      },
      sort: [{ field: 'dateCreated', direction: SortDirection.DESC }]
    },
    customData: this.customData
  };

  // motivation item

  motivations: Motivation[] = [];
  motivation: Motivation | undefined;

  get motivationModel(): Motivation | undefined {

    return this.motivation;

  }

  set motivationModel(v: Motivation | undefined) {
    this.motivation = v;

    this.customData.motivation = this.motivation;

    console.log(this.motivation);



  }

  public compareEntityName(o: any, o2: any) {
    if (isNullOrUndefined(o) && isNullOrUndefined(o2)) {
      return true;
    }

    if ((isNullOrUndefined(o) && !isNullOrUndefined(o2)) || (isNullOrUndefined(o2) && !isNullOrUndefined(o))) {
      return false;
    }

    return o === o2 || o.name === o2.name;
  }

  constructor(private store: Store<State>, dynacrudApiWrapper: DynacrudApiWrapper, private sharedService: SharedService,
    private router: Router, private sessionStatusServiceCount: SessionStatusService,
    private sessionWrappService: SessionWrapperService) {
    // this.sessionStatusService = dynacrudApiWrapper.getFor('SessionStatuses');
    this.store.dispatch(new BaseAction(LayoutActionType.Title, 'session.title'));

  }

  valueProduct: Product | undefined;


  ngOnInit() {

    this.subs.add(
      this.sharedService.loadDataProductHeader.pipe(distinctUntilChanged()).subscribe(p => {
        console.log('dal servizio subject ', p);
        this.valueProduct = p.product['session'];
        this.customData.product = this.valueProduct;
        if ((this.customData && this.customData.product )|| p.clear) {
          this.search(undefined, undefined, true);
        }
      })
    );

    this.subs.add(
      this.sessionStatusServiceCount.getStatusCount().pipe(debounceTime(50), map(a => a.data)).subscribe(d => {
        // count delle sessione nel filtro.
        this.statusCount = d;
      }),

      this.sessionWrappService.getMotivations().pipe(debounceTime(50), map(m => m.data)).subscribe(mot => {
        this.motivations = mot;
      })

    );

    let first = true;
    this.subs.add(
      this.store.pipe(select(selectDynaCrudRequest), debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe(request => {
        this.crudRequest = request || this.crudRequest;
        this.customData = this.crudRequest.customData || this.customData;
        this.sortItems = this.crudRequest.searchRequest?.sort as Sort[];
        this.motivation = this.customData.motivation;
        if (first) {
          first = false;
          this.search(undefined, undefined, true);
        }

      }
      ));
  }

  search(status?: any, source?: any, defaultPass?: boolean) {
    if (status || defaultPass) {
      this.showStatus = false;
      this.selectStatus(status, source?.selected);
    } else {
      this.showStatus = true;
    }
  }

  add() {
    this.router.navigateByUrl('app/warehouse/session/add').then();
  }

  selectStatus(status: WarehouseSessionStatus, click?: boolean, init?: boolean) {


    const possibleFilters: Filter[] = [{ field: 'obsolete', operation: OperationType.EQ, value: 'false' }, { field: 'systemSession', value: false, operation: OperationType.EQ }];
    this.customData.selectedStatus = status;
    if (this.customData.selectedStatus && status && !click) {
      this.customData.selectedStatus = undefined;
    }

    if (this.customData.selectedStatus) {
      possibleFilters.push({ field: 'stateId', operation: OperationType.EQ, value: status.id });
    }

    if (this.motivation && this.motivation.name) {
      possibleFilters.push({ field: 'motivation', operation: OperationType.EQ, value: this.motivation.name });

    }

    if (this.customData && this.customData.product) {

      if (this.customData.product.id > 0) {

        possibleFilters.push({

          operation: OperationType.EXISTS,
          field: 'whMovements',
          filters: [
            {
              operation: OperationType.EQ,
              field: 'whMovements.product.id',
              value: this.customData.product.id

            },
            {
              operation: OperationType.OR,
              filters: [
                {
                  operation: OperationType.EQ,
                  field: 'whMovements.obsolete',
                  value: false
                },
                {
                  operation: OperationType.EQ,
                  field: 'whMovements.status',
                  value: 3
                }
              ]
            }
          ]

        });



      } else if (this.customData.product.id < 0 && this.customData.product.idMetaProduct) {

        possibleFilters.push({

          operation: OperationType.EXISTS,
          field: 'whMovements',
          filters: [
            {
              operation: OperationType.EQ,
              field: 'whMovements.product.idMetaProduct',
              value: this.customData.product.idMetaProduct
            },
            {
              operation: OperationType.OR,
              filters: [
                {
                  operation: OperationType.EQ,
                  field: 'whMovements.obsolete',
                  value: false
                },
                {
                  operation: OperationType.EQ,
                  field: 'whMovements.status',
                  value: 3
                }
              ]
            }
          ]

        });



      }



    }



    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }
    this.crudRequest.customData = this.customData ? this.customData : this.crudRequest.customData;
    this.crudRequest.searchRequest = { filter: { filterBy: filterByObj, text: this.crudRequest.searchRequest?.filter?.text }, pagination: { page: 0, size: 20, active: click } };
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

  }

  searchMotivation() {

    const possibleFilters: Filter[] = [
      { field: 'obsolete', operation: OperationType.EQ, value: 'false' },
      { field: 'systemSession', value: false, operation: OperationType.EQ }
    ];

    if (this.customData && this.customData.selectedStatus) {
      possibleFilters.push({ field: 'stateId', operation: OperationType.EQ, value: this.customData.selectedStatus.id });
    }

    if (this.motivation && this.motivation.name) {
      possibleFilters.push({ field: 'motivation', operation: OperationType.EQ, value: this.motivation.name });
    }

    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }

    this.crudRequest.customData = this.customData ? this.customData : this.crudRequest.customData;
    this.crudRequest.searchRequest = { filter: { filterBy: filterByObj, text: this.crudRequest.searchRequest?.filter?.text || '' }, pagination: { page: 0, size: 20, active: true } };
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

  }

  // private clearPagination() {
  //   const paginationClear: any = {pagination: {page: 0, size: 20}, sort: this.sortItems};
  //   this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, paginationClear));
  // }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
