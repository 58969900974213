import { Component, OnInit } from '@angular/core';
import { ColumnData, ColumnFieldComponent } from '../../Column';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-default-link-to-id-column',
  templateUrl: './default-link-to-id-column.component.html',
  styleUrls: ['./default-link-to-id-column.component.scss']
})
export class DefaultLinkToIdColumnComponent implements ColumnFieldComponent, OnInit {
  public mapDescription: {[key: string] : any} = {};
  data!: ColumnData;
  baseUrl = 'https://' + environment.gestionale_V2_CHECKOUT + '.galactus.it' + '/' + 'app/shop/checkout';
  baseUrlToMarket = 'https://' + environment.gestionale_V2_CHECKOUT + '.galactus.it' + '/' + 'app/marketplaces/orders';
  
  // http://localhost:4200/
  // baseUrlToMarket = 'http://localhost:4200' + '/' + 'app/marketplaces/orders';

  ngOnInit(): void {
    this.mapDescription[this.data.row?.order?.id || this.data.row?.orderId ] = this.data.row?.order?.description;
  }

  goPage(id: any): string | undefined {

    if (this.data.row && this.data.row.order && this.data.row.order.warehouse && this.data.row.order.warehouse.virtual  ) {
      return this.baseUrlToMarket + '/' + id;
    }

    return this.baseUrl + '/' + id;

  }


}
