import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { isArray } from 'is-what';
import * as _ from 'lodash';
import { debounceTime, distinctUntilChanged, of, take } from 'rxjs';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { Filter, OperationType, Sort } from 'src/app/core/models/dynacrud';
import { Brand, Category, ProductStructure, CategoryStructure, Warehouse, ProductStructureFieldType, Entity } from 'src/app/shared/models/entities';
import { HostColumnEditService } from 'src/app/shared/services/host-column-edit.service';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { DynaConfActionType, DynaCrudRequest, selectDynaCrudRequest, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { ProductDoubleSearchComponent } from 'src/app/structure/components/structure/product-double-search/product-double-search.component';
import { ProductSearch, StructureDataFilter } from 'src/app/structure/models/Structure';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { CountRowsService } from '../../../services/count-rows.service';

@Component({
  selector: 'app-transfer-type-filter-derivato-stock',
  templateUrl: './transfer-type-filter-derivato-stock.component.html',
  styleUrls: ['./transfer-type-filter-derivato-stock.component.scss']
})
export class TransferTypeFilterDerivatoStockComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('productDouble2') productRef!: ProductDoubleSearchComponent;
  structureReady = false;
  productNumberMin!: string;
  productNumberMax!: string;

  productPriceMin!: number;
  productPriceMax!: number;

  comment!: string;
  sortItems : Sort[] | undefined = [];

  constructor(private store: Store<State>, private _countRowsService: CountRowsService, private productStructureService: ProductStructureService, private router: Router, private activRout: ActivatedRoute,
    private dynacrudApiWrapper: DynacrudApiWrapper, private _snackBar: MatSnackBar, private el: ElementRef, private _hostColumnsService: HostColumnEditService) {
  }

  get productSearch(): ProductSearch | undefined {
    return this.filter.productSearch;
  }
  set productSearch(productSearch: ProductSearch | undefined) {
    this.filter.productSearch = productSearch;
   
    if (this.filter?.productStructure?.id || this.filter.productSearch?.product?.id) {

      this.loadSearchProductStructure(true);
    

     

     
    }

    
  }

  get brands(): Brand[] | undefined {
    return this.filter.brand;
  }
  set brands(brands: Brand[] | undefined) {
    if (brands) {

      this.filter.brand = brands;
    }
    this.loadStructures();
  }
  get categories(): Category[] | undefined {
    return this.filter.category;
  }
  set categories(categories: Category[] | undefined) {
    if (categories) {

      this.filter.category = categories;
    }
    this.loadStructures();
  }

  get productCategory(): Category | undefined {
    return this.filter?.productSearch?.product?.category;
  }

  set productCategory(cat: Category | undefined) {
    if (cat && this.filter.productSearch?.product) {
      this.filter.productSearch.product.category = cat;
    }
  }
  get productBrand(): Brand | undefined {
    return this.filter?.productSearch?.product?.brand;
  }

  set productBrand(bd: Brand | undefined) {
    if (bd && this.filter.productSearch?.product) {
      this.filter.productSearch.product.brand = bd;
    }
  }

  get numberProduct(): number | undefined {
    return this.filter?.productSearch?.product?.number;
  }

  set numberProduct(nm: number | undefined) {
    if (nm && this.filter.productSearch?.product) {
      this.filter.productSearch.product.number = nm;
    }
  }

  private subs = new SubSink();
  filter: StructureDataFilter = { brand: [], category: [] };
  private crudRequest: DynaCrudRequest = {
    searchRequest: {},
    customData: this.filter
  };
  productStructure!: ProductStructure | undefined;
  private categoryStructures!: CategoryStructure[];
  brandFilter: CallableFunction = (w: Brand) => !w.obsolete;
  categoryFilter: CallableFunction = (w: Category) => w.id != 2;
  warehouseFilter: CallableFunction = (w: Warehouse) => !w.virtual && !w.obsolete;
  idSession!: number | undefined;

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  

  ngOnInit(): void {
    this.detSession();
    let first = true;
    this.subs.add(this.productStructureService.isReadyObs().subscribe(ret => {
      if (ret) {
        this.subs.add(this.store.pipe(select(selectDynaCrudRequest), debounceTime(0), distinctUntilChanged()).subscribe(request => {

          console.log('request', request);

          this.crudRequest = request ? { ...request } : this.crudRequest;

          if (this.crudRequest.customData) {
            this.filter = { ...this.crudRequest.customData };
            if (this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id) {
              this.firstHasProduct = false;

            }
          }

          if (this.filter) {
            if (this.filter.minNumber) {
              this.productNumberMin = this.filter.minNumber;

            }

            if (this.filter.maxNumber) {
              this.productNumberMax = this.filter.maxNumber;

            }
            if (this.filter['minPrice']) {
              this.productPriceMin = this.filter['minPrice'];

            }
            if (this.filter['maxPrice']) {
              this.productPriceMax = this.filter['maxPrice'];

            }
            if (this.filter['comments']) {
              this.comment = this.filter['comments'];

            }
          }

          this.filter.brand = this.filter.brand || [];
          this.filter.category = this.filter.category || [];
          this.sortItems = this.crudRequest.searchRequest?.sort;

          this.structureReady = true;

          if (!this.filter.productStructure || !this.productStructure) {
            this.loadStructures();
          }

          setTimeout(() => {

            if ((this.crudRequest?.customData['reserved'] != undefined) && !(this.crudRequest?.customData?.productSearch?.product?.id)) {
              this.filter['reserved'] = this.crudRequest?.customData['reserved'];
            } else if (this.filter?.productSearch?.product?.id) {
              this.filter['reserved'] = this.crudRequest?.customData?.productSearch?.product['reserved'];
            } else {
              this.filter['reserved'] = this.filter['reserved'];
            }

          }, 0);


          if (first) {
            first = false;
            this.search();
          }
        }
        ));
      }
    }
    ));
  }

  firstHasProduct = true;

  private loadSearchProductStructure(isReset: boolean) {

    if (this.filter.productSearch?.click) {
      this.filter.productSearch.click = false;

      if (this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id) {

        this.firstHasProduct = false;

      }

      if (isReset) {
        this.aps();
      }

      const OBS = of(1);
      this.subs.add(
        OBS.pipe(take(1), distinctUntilChanged()).subscribe(() => {
          this.search(new Event('click'));
        })
      );
    }


  }

  

  private aps() {
    const ap = _.cloneDeep(this.productStructure) || undefined;

    if (this.crudRequest.customData?.productSearch?.product?.id &&
      (this.crudRequest.customData?.productSearch?.product?.reserved != undefined)
      && !this.filter.productSearch?.product?.id) {

      this.filter['reserved'] = undefined;

      // ! questo e il problema 
      // this.productStructure?.fields?.forEach(field => {
      //   if (field) {
      //     this.filter[field.field as string] = undefined;
      //   }
      // });
    }

    if ((this.crudRequest?.customData['reserved'] != undefined) && !(this.filter?.productSearch?.product?.id)) {
      this.filter['reserved'] = this.crudRequest?.customData['reserved'];
    }

    if (this.productStructure && ap) {
      if ((this.filter && this.filter['reserved'] == undefined)) {

        // togliere dopo! da pensare...  in tanto cablato per quelli non normali 
        if ((this.brands && this.brands.length == 1) && (this.brands.find(d =>  d.id == 1 ))) {
          (this.productStructure.fields as any[])[2] = undefined;
          (this.productStructure.fields as any[])[2] = (ap.fields as any[])[2];
        }
      }
    }
  }

  public getBrandCategory(value: any) {

    if (value != undefined && value != 'refresh') {
      this.filter.brand = this.productRef.brandCategory.brand ? [this.productRef.brandCategory.brand] : undefined;
      this.filter.category = this.productRef.brandCategory.category ? [this.productRef.brandCategory.category] : undefined;
    }

    if (value && value == 'refresh') {
      this.aps();
    }

  }

  protected loadStructures() {
    const productSearch: ProductSearch | undefined = this.filter.productSearch;
    if (productSearch && productSearch.product && productSearch.product.id) {
      this.productStructure = this.productStructureService.findStructure(productSearch.product.category.id, productSearch.product.brandId);
    } else {
      this.categoryStructures = [];
      const brandIds: number[] = this.brands && this.brands.filter(b => b != null).length > 0 ? this.brands?.map(b => b.id) : [null];
      this.categories?.filter(c => c.id != null).map(c => c.id).forEach(cId => {
        brandIds.forEach(bId => {
          this.productStructureService.getCategoryStructures(cId, bId).forEach(cs => this.categoryStructures.push(cs));
        });
      });

      // FIXME K3 potremmo anche prevederne più di uno!! e fare un merge :) dei fields...
      const catStruct = _.uniqBy(this.categoryStructures, 'productStructureId');
      if (catStruct.length === 1) {

        const found = this.productStructureService.findStructure(catStruct[0].categoryId, catStruct[0].brandId);
        if (found && found.id !== this.productStructure?.id) {
          this.productStructure = found;
        }
      } else {
        this.productStructure = undefined;
      }
    }
    this.filter.productStructure = this.productStructure;

    if ((!this.filter.productStructure || (this.hasProduct() && this.filter.productStructure)) && this.firstHasProduct) {
      this.firstHasProduct = false;
      this.filter['color'] = [];
      this.filter['condition'] = [];
      this.filter['expansion'] = [];
      this.filter['firstEdition'] = undefined;
      this.filter['foil'] = undefined;
      this.filter['language'] = [];
      this.filter['rarity'] = undefined;

      this.filter['reserved'] = undefined;
      this.filter['signed'] = undefined;
      this.filter['altered'] = undefined;
      this.filter['stamped'] = undefined;
      this.filter['maxNumber'] = undefined;
      this.filter['minNumber'] = undefined;

      this.filter['shadowless'] = undefined;
      this.filter['inked'] = undefined;
      this.filter['firstEdition'] = undefined;
      this.filter['minNumber'] = undefined;
 
      if (this.filter && this.filter['holo']) {
        this.filter['holo'] = undefined;
      }
    }
    if (this.hasProduct()) {
      this.ngAfterViewInit();
    }
  }

  ngAfterViewInit() {
    if (this.hasProduct()) {

      setTimeout(() => {
        this.el.nativeElement.querySelector('.searchFocus')?.focus();
      }, 0);

    } else {
      setTimeout(() => {
        this.el.nativeElement.querySelector('.mat-mdc-input-element')?.focus();
      }, 0);
    }
    // this.detSession();
  }

  hasProduct(): boolean {
    if (!(this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id)) {

      this.firstHasProduct = true;

    }
    return this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id;
  }
  search(click?: Event) {
    const possibleFilters: Filter[] = [
      { operation: OperationType.EQ, field: 'active', value: 'true' },
      { operation: OperationType.GT, field: 'avaible', value: '0' },
      { operation: OperationType.EQ, field: 'product.obsolete', value: false }
    ];
    this.filter.minNumber = this.productNumberMin;
    this.filter.maxNumber = this.productNumberMax;
    this.filter['minPrice'] = this.productPriceMin;
    this.filter['maxPrice'] = this.productPriceMax;
    this.filter['comments'] = this.comment;
    const hasProduct = this.hasProduct();
    let textFilter = this.filter.productSearch ? this.filter.productSearch.text : this.productRef?.productSuggestInput ? this.productRef.productSuggestInput.nativeElement.value : null;
    if (!hasProduct) {
      if (this.brands && this.brands.length > 0) {
        possibleFilters.push({ operation: OperationType.IN, field: 'product.brand.id', value: this.brands.map(b => b.id) });
      }
      if (this.categories && this.categories.length > 0) {
        possibleFilters.push({ operation: OperationType.IN, field: 'product.category.id', value: this.categories.map(b => b.id) });
      }
    } else {
      // textFilter = null; // questo dice se rimane il testo oppure no
      textFilter = null;
      if (this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.id > 0) {
        possibleFilters.push({ operation: OperationType.EQ, field: 'product.id', value: this.filter.productSearch.product.id });
      }

      if (this.filter && this.filter.productSearch && this.filter.productSearch.brand != undefined && this.filter.productSearch.category != undefined) {
        this.brands = [this.filter.productSearch.brand];
        this.categories = [this.filter.productSearch.category];

        possibleFilters.push({ operation: OperationType.IN, field: 'product.brand.id', value: this.brands.map(b => b.id) });

        possibleFilters.push({ operation: OperationType.IN, field: 'product.category.id', value: this.categories.map(b => b.id) });


      } else if (this.filter && this.filter.productSearch && this.filter.productSearch.product && this.filter.productSearch.product.brand != undefined && this.filter.productSearch.product.category != undefined) {
        this.brands = [this.filter.productSearch.product.brand];
        this.categories = [this.filter.productSearch.product.category];

        possibleFilters.push({ operation: OperationType.IN, field: 'product.brand.id', value: this.brands.map(b => b.id) });
        possibleFilters.push({ operation: OperationType.IN, field: 'product.category.id', value: this.categories.map(b => b.id) });

      }
    }

    if (this.filter.productSearch?.product?.idMetaProduct && this.filter.productSearch?.product?.id < 0) {
      const valueMetaProduct = this.filter.productSearch.product.idMetaProduct;
      possibleFilters.push({ operation: OperationType.EQ, field: 'product.idMetaProduct', value: valueMetaProduct });
    }

    if (this.filter['warehouse'] && this.filter['warehouse'].length > 0) {
      possibleFilters.push({ operation: OperationType.IN, field: 'warehouse.id', value: this.filter['warehouse'].map((b: Entity) => b.id) });
    }

    if (this.filter.minNumber) {
      const ValueFilter = _.padStart(this.filter.minNumber || '00000000000000000000', 20, '0');
      possibleFilters.push({ operation: OperationType.GTE, field: 'product.numberOrder', value: ValueFilter });
    }
    if (this.filter.maxNumber || this.filter.maxNumber == '0') {
      const ValueFilterMax = _.padStart(this.filter.maxNumber || '90000000000000000000', 20, '0');
      possibleFilters.push({ operation: OperationType.LTE, field: 'product.numberOrder', value: ValueFilterMax == '90000000000000000000' ? '00000000000000000000' : ValueFilterMax });
    }

    if (this.filter['minPrice'] >= 0 && this.filter['minPrice'] != null) {
      const ValueFilter = this.filter['minPrice'];
      possibleFilters.push({ operation: OperationType.GTE, field: 'actualPrice', value: ValueFilter });
    }

    if (this.filter['maxPrice'] >= 0 && this.filter['maxPrice'] != null) {
      const ValueFilterMax = this.filter['maxPrice'];
      possibleFilters.push({ operation: OperationType.LTE, field: 'actualPrice', value: ValueFilterMax });
    }

    if (this.filter['comments']) {
      const valueC = this.filter['comments'];
      possibleFilters.push({ operation: OperationType.LIKE, field: 'comments', value: valueC });
    }

    if (this.productStructure && this.productStructure.allFields) {
      this.productStructure.allFields.forEach(f => {
        if (f.field && this.filter[f.field as string] != null) {
          if (!hasProduct || f.stock) {
            const fieldName = (f.stock ? 'combination.' : 'product.') + f.field + (f.type === ProductStructureFieldType.LOOKUP ? '.id' : '');

            if (isArray(this.filter[f.field as string])) {
              if (this.filter[f.field as string].length > 0) {
                possibleFilters.push({ operation: OperationType.IN, field: fieldName, value: this.filter[f.field].map((b: Entity) => b.id) });
              }
            } else {
              possibleFilters.push({ operation: OperationType.IN, field: fieldName, value: this.filter[f.field] });
            }
          }
        }
      });
    }
    for (const f of possibleFilters) {
      if (f.operation === OperationType.IN) {
        const arrayValue = f.value as any[];
        if (arrayValue.length === 1) {
          f.operation = OperationType.EQ;
          f.value = arrayValue[0];
        }
      }
    }
    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }
    this.crudRequest.customData = {...this.filter};
    if (click) {
      if (click.type) {
        this._hostColumnsService._resetData.next(true);
        this.crudRequest.searchRequest = { filter: { text: textFilter, filterBy: filterByObj }, pagination: { page: 0, size: 20, active: true} };
        this.clearPagination();
      }
    } else {
      this.crudRequest.searchRequest = { filter: { text: textFilter, filterBy: filterByObj } };
      // this._hostColumnsService._resetData.next(false);
    }



    // FIXME: CLEAR PRODUCTS ROW

    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));
    this._countRowsService.deleteRow();

    this.focusSearch();
  }

  private clearPagination() {
    const paginationClear: any = { pagination: { page: 0, size: 20 }, sort: this.sortItems };
    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, paginationClear));
  }

  focusSearch() {
    return setTimeout(() => {
      this.el.nativeElement.querySelector('.mat-mdc-input-element').focus();
    }, 100);
  }
  selectsReset(f: NgForm) {
    const form = f;
    if (this.productSearch) {
      this.productRef.productSuggestInput.nativeElement.value = '';
      this.productSearch.text = '';
      this.productSearch.product = undefined;
    }
    form.reset();
    this.search();
    this._hostColumnsService._resetData.next(true);
    this.clearSort();

  }

  clearSort() {
    if (this.crudRequest?.searchRequest?.sort) {
      this.crudRequest.searchRequest.sort = [];
    }
    if (this.crudRequest?.searchRequest?.pagination) {
      this.crudRequest.searchRequest.pagination = undefined;
    }
    this.store.dispatch(new BaseAction(DynaConfActionType.PaginationSortChange, this.crudRequest.searchRequest));

  }

  detSession(e?: Event): void {
    e?.preventDefault();

    this.subs.add(
      this._hostColumnsService._obIdSession.subscribe((res) => {
        if (res && res.id) {
          this.idSession = res.id as number;
          if (e && e.type) {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));
            this.router.navigateByUrl(`app/warehouse/session/${this.idSession}`).then((): void => {
              this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            });
          }
        } else {
          this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
          this.idSession = undefined;
          if (e && e.type) {
            this.openSnackBar();
          }
        }
      })
    );

  }
  openSnackBar() {

    this._snackBar.openFromComponent(InfoButton, {
      duration: 5000,
    });

  }

}

@Component({
  selector: 'snack-bar-component-example-snack',
  template: `
    <span>{{'error.noSessionId' | translate}}</span>
  `,
  styles: [`

  `],
})
export class InfoButton { }
