import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import _ from 'lodash';
import { catchError, debounceTime, distinctUntilChanged, map, Subject, throwError } from 'rxjs';
import { DefaultListComponentConf, TypeTable } from 'src/app/core/components/default-list/default-list.component';
import { SelectionMode, TableField } from 'src/app/core/models/table-config';
import { State, selectDynaCrudRequest, DynaConfActionType, DynaCrudRequest } from 'src/app/store/shared/shared.reducers';
import { StructureDataFilter } from 'src/app/structure/models/Structure';
import { SubSink } from 'subsink';
import { DialogLoadSetInsertComponent } from "../../../../../core/components/table/table-edit-rows/table-edit-rows.component";
import { MatDialog } from "@angular/material/dialog";
import { Brand, Category } from "../../../models/entities-report";
import { BaseAction } from "../../../../../store/store.actions";
import { KEYCONSTANTSETINSERT, keyDialog } from 'src/app/shared/models/entities';
import { SetInsertService } from 'src/app/core/services/set-insert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-set-insert-conf',
  templateUrl: './set-insert-conf.component.html',
  styleUrls: ['./set-insert-conf.component.scss']
})
export class SetInsertConfComponent implements OnInit, OnDestroy, AfterViewInit {

  private subs = new SubSink();
  mobile = false;
  filter!: StructureDataFilter;
  defaultListConf: any;

  typeConst = TypeTable;

  constructor(private store: Store<State>, private breakpointObserver: BreakpointObserver, private dialog: MatDialog, private setInsertService: SetInsertService, private activRouter: ActivatedRoute) {
  }


  public viewTable = false;

  lastDynaCrudRequest: DynaCrudRequest | undefined;

  ngOnInit(): void {

    const validKey = KEYCONSTANTSETINSERT;
    let FIRST = true;

    this.subs.add(
      this.store.pipe(select(selectDynaCrudRequest)).pipe(distinctUntilChanged(), debounceTime(50)).subscribe(request => {
        const crudRequest = request || {};
        this.lastDynaCrudRequest = request;
        this.filter = crudRequest.customData || this.filter || {};

        if (this.filter && this.filter.productStructure) {

          this.viewTable = true;

          if (FIRST) {
            FIRST = false;


            /*
            this.subs.add(
              this.setInsertService.getLoads(validKey).pipe(map(a => a.data),
                catchError((err: HttpErrorResponse) => {
                  return throwError(() => {

                    const errL: any = err;
                    if (errL.status == 404) {
                      if (!errL.error) {
                        errL.error = 'Sessione set insert vuota!';
                      }
                    }
                    return errL as HttpErrorResponse;

                  });

                })).subscribe(res => {
                  if (res && res.filter && res.filter.customData && res.filter.customData.brand && res.filter.customData.brand.id) {

                    this.subs.add(
                      this.activRouter.params.pipe().subscribe(param => {

                        if (param && !param['id']) {

                          console.log('risposta dal conf 1 id',res);

                          this.dialog.open(DialogLoadSetInsertComponent, {
                            data: { key: validKey, valid: undefined },
                            height: '30%',
                            width: '80%',
                            id: keyDialog,
                            disableClose: true
                          });

                        }
  
                      })

                    );

                    

                  }
                })
            );

            */


          }


        } else {

          if (FIRST) {
            FIRST = false;


            /*
            this.subs.add(
              this.setInsertService.getLoads(validKey).pipe(map(a => a.data), catchError((err: HttpErrorResponse) => {
                return throwError(() => {

                  const errL: any = err;
                  if (errL.status == 404) {
                    if (!errL.error) {
                      errL.error = 'Sessione set insert vuota!';
                    }
                  }
                  return errL as HttpErrorResponse;

                });

              })
              ).subscribe(res => {

                if (res && res.filter && res.filter.customData && res.filter.customData.brand && res.filter.customData.brand.id) {

                  this.viewTable = true;

                  console.log('risposta dal conf 2',res);

                  this.dialog.open(DialogLoadSetInsertComponent, {
                    data: { key: validKey, valid: undefined },
                    height: '30%',
                    width: '80%',
                    id: keyDialog,
                    disableClose: true
                  });

                }
              })
            );


            */

          }

        }

        this.loadTable();
      })
    );

    this.breakpointObserver.observe(['(max-width: 900px)']).pipe(distinctUntilChanged()).subscribe((state: BreakpointState) => {

      this.mobile = state.matches;

      this.loadTable();

    });

  }

  ngAfterViewInit(): void {

    setTimeout(() => {
      
      this.dialog.getDialogById(keyDialog)?.afterClosed().subscribe(result => {
  
        if (!result) {
          if (!(this.filter && this.filter.productStructure)) {
            this.viewTable = false;
          }
  
          if (!(this.filter.brand && (this.filter.brand as unknown as Brand).id && this.filter.category && (this.filter.category as unknown as Category).id)) {
            this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, { ...this.lastDynaCrudRequest }))
  
          }
        } else {
          this.viewTable = true;
        }
      });
    }, 2000);

  }

  ngOnDestroy(): void {

    this.subs.unsubscribe();

  }

  private loadTable() {

    this.defaultListConf = undefined;

    if (this.filter) {


      const columnsFields: TableField[] = [];

      const productStructure = this.filter.productStructure;

      columnsFields.push(new TableField('name', undefined, 'Name'));

      if (productStructure && productStructure.fields && productStructure.fields.length > 0) {

        productStructure.fields.forEach(f => {
          if (f.field != 'reserved') {
            columnsFields.push(new TableField(`${f.field}.id`, undefined, `stock.${f.field}.id`, undefined, true))

          }


        })

      }


      columnsFields.push(new TableField('number', undefined, 'stock.number', undefined, true, 'numberOrder'));


      const displayedColumns = [...columnsFields.map(cf => cf.id)];

      this.defaultListConf = this.defaultListConf || _.cloneDeep({
        loadOnInit: false,
        tableConfig: {
          selectionMode: SelectionMode.multiple,
          actions: [],
          columnsFields: [...columnsFields]
        },
        displayedColumns: [...displayedColumns]
      });

    }

  }


}
