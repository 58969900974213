<ng-container *ngIf="structureReady">
  <form name="form-cart" #f="ngForm" (keyup.enter)="search($event)">

    <div class="search">
      <app-product-double-search  name="productDouble2" (clearProduct)="getBrandCategory($event)" class="search-bar" #productDouble2 id="productDouble2"
        [(ngModel)]="productSearch" [structureDataFilter]="filter"> </app-product-double-search>
    </div>

    <div class="showSelects d-flex gap-1">
      <!--SISTEMATIZAR FIXME SANTIAGO-->
      <ng-container *ngIf="hasProduct()">
        <app-default-select class="itemShow" [readonly]="true" name="category" [filterBy]="categoryFilter"
          [(ngModel)]="productCategory" label="category" [multiple]="false" source="category"></app-default-select>
        <app-default-select class="itemShow" [readonly]="true" name="brand" [(ngModel)]="productBrand" label="brand"
          [multiple]="false" source="brand"></app-default-select>
      </ng-container>
      <ng-container *ngIf="!hasProduct()">
        <app-default-select class="itemShow" [disabled]="hasProduct()" [filterBy]="categoryFilter" name="category"
          [(ngModel)]="categories" label="category" [multiple]="true" source="category"></app-default-select>
        <app-default-select class="itemShow" [disabled]="hasProduct()" name="brand" [(ngModel)]="brands" label="brand"
          [multiple]="true" source="brand" [filterBy]="brandFilter"></app-default-select>
      </ng-container>
      <app-default-select class="itemShow" name="warehouse" [(ngModel)]="filter['warehouse']"
        [filterBy]="warehouseFilter" label="stock.warehouse" [multiple]="true" source="warehouse"></app-default-select>
      <mat-form-field class="field_num">
        <mat-label>{{'order_product.minPrice' | translate}}</mat-label>
        <input type="number" matInput [(ngModel)]="productPriceMin" #minPrice="ngModel" name="productpriceMin"
                min="0" [max]="productNumberMax">
        <mat-error *ngIf="minPrice.errors">{{'order_product.hint_error' | translate}}</mat-error>
      </mat-form-field>
      <mat-form-field class="field_num">
        <mat-label>{{'order_product.maxPrice' | translate}}</mat-label>
        <input type="number" [(ngModel)]="productPriceMax" matInput name="productpriceMax" #maxPrice="ngModel"
                min="0">
        <mat-error *ngIf="maxPrice.errors">{{'order_product.hint_error' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field_num">
        <mat-label>{{'order_product.comment' | translate}}</mat-label>
        <input type="text" matInput [(ngModel)]="comment" #commentRef="ngModel" name="comment" >
        <mat-error *ngIf="commentRef.errors">{{'order_product.hint_error' | translate}}</mat-error>
      </mat-form-field>
    </div>



    <div class="row-select-option1 d-flex gap-1 flex-wrap">

      <mat-form-field class="field_num">
        <mat-label>{{'order_product.min' | translate}}</mat-label>
        <input type="number" matInput [(ngModel)]="productNumberMin" #minOrder="ngModel" name="productNumberMin"
               [disabled]="hasProduct()" min="0" [max]="productNumberMax" maxlength="20">
        <mat-error *ngIf="minOrder.errors">{{'order_product.hint_error' | translate}}</mat-error>
      </mat-form-field>

      <mat-form-field class="field_num">
        <mat-label>{{'order_product.max' | translate}}</mat-label>
        <input type="number" [(ngModel)]="productNumberMax" matInput name="productNumberMax" #maxOrder="ngModel"
               [disabled]="hasProduct()" min="0" maxlength="20">
        <mat-error *ngIf="maxOrder.errors">{{'order_product.hint_error' | translate}}</mat-error>
      </mat-form-field>

<!--      fixme numero product -->

      <ng-container *ngIf="hasProduct()">
        <mat-form-field class="field_num_read">
          <mat-label>{{'order_product.onlyNumber' | translate}}</mat-label>
          <input type="number" readonly matInput name="number" [(ngModel)]="numberProduct">
        </mat-form-field>
      </ng-container>

      <ng-container *ngFor="let field of productStructure?.fields; index as i">
        <ng-container *ngIf="field && field.field">
          <app-structure-field [disabled]="hasProduct()" [name]="field.field" [(ngModel)]="filter[field.field]"
            [structureField]="field" [structureDataFilter]="filter"></app-structure-field>

        </ng-container>
      </ng-container>

      <ng-container *ngFor="let field of productStructure?.stockfields; index as i">
        <ng-container *ngIf="field && field.field">
          <app-structure-field [name]="field.field" [(ngModel)]="filter[field.field]"
            [structureField]="field"></app-structure-field>
        </ng-container>
      </ng-container>


    </div>

    <!--buttons-->

    <div class="row-button">

      <button type="button" [color]="idSession ? 'primary' : 'warn'" [disabled]="!idSession"
        class="searchFocus button-search" mat-raised-button color="primary" (click)="detSession($event)">
        <span style="font-weight: 600;">
          Go to session
        </span>
        <!-- <mat-icon class="iconSearch">search</mat-icon> -->
      </button>

      <button type="button" class="button-search" mat-raised-button color="warn" (click)="selectsReset(f)">
        Reset
      </button>
      <button type="button" [disabled]="productSearch?.err || false" class="searchFocus button-search" mat-raised-button
        color="primary" (click)="search($event)">
        Search
        <mat-icon class="iconSearch">search</mat-icon>
      </button>

    </div>
  </form>
</ng-container>
