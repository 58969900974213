import {Injectable, Type} from '@angular/core';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import { Language } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends DynacrudWeakDataService<Language> {

  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('languages', LanguageService, dynacrudApiWrapper);
  }

  getType(): Type<Language> | string {
    return 'language';
  }
}
