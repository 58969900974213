import { CdkConnectedOverlay } from '@angular/cdk/overlay';
import { AfterViewInit, Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { debounceTime } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { ColumnData } from '../../Column';
import { DefaultColumnComponent } from '../default-column/default-column.component';

@Component({
  selector: 'app-default-image-column',
  templateUrl: './default-image-column.component.html',
  styleUrls: ['./default-image-column.component.scss']
})
export class DefaultImageColumnComponent extends DefaultColumnComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  override data!: ColumnData;
  imageUrl: string | undefined;
  style = '';
  isOpen: boolean = false;
  @ViewChild('cdkConOverlay', {static:  false}) overLayConected: CdkConnectedOverlay | undefined;

  showimage(event: MouseEvent) {
    this.style = 'vedi image';
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
  
  noImage() {
    const erroimage = environment.DefaultImageMagic;
    this.imageUrl = undefined;
    this.imageUrl = this.data.field.attributes ? this.data.field.attributes['errorImage'] || erroimage  : erroimage;;
  }

  override ngOnInit(): void {
    this.imageUrl = this.getValue();
  }

  ngAfterViewInit(): void {
    this.subs.add(
      this.overLayConected?.overlayOutsideClick.pipe(debounceTime(10)).subscribe(d => {
        this.isOpen = false;
      })
    );
  }
}
