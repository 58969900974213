import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import * as _ from 'lodash';
import { ControlValueAccessorBaseImpl } from 'src/app/core/components/control-value/ControlValueAccessorBaseImpl';
import { ProductStructureField } from 'src/app/shared/models/entities';
import { StructureDataFilter, StructureFieldType } from 'src/app/structure/models/Structure';


export const STRUCTURE_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => StructureFieldComponent),
  multi: true
};

@Component({
  selector: 'app-structure-field',
  templateUrl: './structure-field.component.html',
  styleUrls: ['./structure-field.component.scss'],
  providers: [STRUCTURE_CONTROL_VALUE_ACCESSOR]
})
export class StructureFieldComponent extends ControlValueAccessorBaseImpl<any> implements StructureFieldType, OnInit {

  @Input()
  structureField!: ProductStructureField;

  @Input()
  structureDataFilter!: StructureDataFilter;

  @Input()
  featureCode : string | undefined;

  @Input()
  mult!: boolean;

  @Input() colorIs: string | undefined;


  expansionSort: CallableFunction = (items: any[]) => {
    return _.reverse(_.sortBy(items, function(i){ return i.releaseDate || ''; }));
  }

}
