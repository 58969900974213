import {AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ProductStructure, Warehouse} from 'src/app/shared/models/entities';
import {ProductStructureService} from 'src/app/structure/services/product-structure.service';
import * as _ from 'lodash';
import {CdkConnectedOverlay} from '@angular/cdk/overlay';
import {SubSink} from 'subsink';
import {BehaviorSubject, catchError, debounceTime, distinctUntilChanged, map, Observable, take, throwError} from 'rxjs';
import {DynacrudApiWrapper} from 'src/app/core/api/dynacrud-api';
import {ComponentUtils} from 'src/app/core/models/component-utils';
import {OperationType, PaginationResponse, SearchRequest, SortDirection} from 'src/app/core/models/dynacrud';
import {DefaultListComponentConf} from 'src/app/core/components/default-list/default-list.component';
import {
  NewMovementNotIdTRANSRoutConfssigPerEDIT,
  NewMovementNotIdUNLOADRoutConfssigPerEdit
} from '../../../models/route-config';
import {RouteConf} from 'src/app/core/models/route-config';
import {TableData, TableStatus} from 'src/app/core/components/table/table/table.component';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {TypeModalSessionProduct, WarehouseMovementDTO} from '../../../models/entities';
import {DialogEditService} from '../../../services/dialog-edit.service';
import {isArray} from 'is-what';
import {Store} from '@ngrx/store';
import {DynaConfActionType, State} from 'src/app/store/shared/shared.reducers';
import {BaseAction} from 'src/app/store/store.actions';
import {DialogService} from 'src/app/shared/services/dialog.service';
import {LayoutActionType} from 'src/app/store/layout/layout.reducers';

@Component({
  selector: 'app-dialog-edit-row-table',
  templateUrl: './dialog-edit-row-table.component.html',
  styleUrls: ['./dialog-edit-row-table.component.scss']
})
export class DialogEditRowTableComponent extends ComponentUtils implements OnInit, AfterViewInit, OnDestroy {
  subs = new SubSink();
  productStructure!: ProductStructure;
  modelData: { [key: string]: any } = {combination: {}};
  cache: any = {};
  isOpen: { [key: string]: any } = {};

  featureCodeType = TypeModalSessionProduct;
  featureCode = '';
  defaultListConfCustom!: DefaultListComponentConf;

  listProducts: { [k: string]: any }[] = [];

  @ViewChild('cdkConOverlay', {static: false}) overLayConected: CdkConnectedOverlay | undefined;

  dataSourcetSub: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({
    data: [],
    tableStatus: {page: {length: 0, pageIndex: 0, pageSize: 0}, sort: []}
  });
  readonly dataSource: Observable<TableData> = this.dataSourcetSub.asObservable();

  quantityRows: number = 0;

  loadingBlock = false;

  // private defaultListComponent = new DefaultListComponent(this.router, this.activatedRouter, this.dynacrudApiWrapper, this.store, this.dialogService, this.hostColumnService  );

  constructor(@Inject(MAT_DIALOG_DATA) public dataOver2: {
                item: any,
                idSession: string,
                featureType: TypeModalSessionProduct
              },
              private productStructureService: ProductStructureService,
              private dynacrudApiWrapper: DynacrudApiWrapper,
              private dialogServiceEditSession: DialogEditService,
              private dialogServiceLayout: DialogService,
              public dialog: MatDialog, private http: HttpClient, private store: Store<State>) {

    super();
    this.cache = _.cloneDeep(dataOver2.item);
    this.featureCode = dataOver2.featureType;


  }

  limitarDecimales(event: any, i: number) {
    const inputValue: string = event.target.value;
    const parts: string[] = inputValue.split('.');
    
    if (parts.length > 1 && parts[1].length > 2) {
      // Si hay más de dos decimales, bloquea la entrada del tercer decimal
      event.target.value = parts[0] + '.' + parts[1].substring(0, 2);
    }

    // this.priceToEditValue =  event.target.value;

    this.getData(i).purchasePrice = event.target.value;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  duplicate(index: number) {
    // duplicate item

    // this.objectCacheItem = {};
    this.listProducts[index] = _.cloneDeep(this.objectCacheItem[index]);

    const pr = _.cloneDeep(this.listProducts[index]);
    this.listProducts.splice(index, 0, pr);

    Object.keys(this.listProducts).forEach((d: any, onIndex: number) => {


      this.objectCacheItem[onIndex] = _.cloneDeep(this.listProducts[onIndex]);

    });


  }

  deleteItem(index: number) {

    Object.keys(this.objectCacheItem).forEach((d: any, onIndex: number) => {
      this.listProducts[onIndex] = _.cloneDeep(this.objectCacheItem[onIndex]);
    });

    this.objectCacheItem = {};

    this.listProducts = this.listProducts.filter((d, i) => {

        return i !== index;
      }
    );


    Object.keys(this.listProducts).forEach((d: any, onIndex: number) => {
      this.objectCacheItem[onIndex] = _.cloneDeep(this.listProducts[onIndex]);
    });

  }

  add(index: number) {

    if (this.singleValid(index) && this.singleValid(index).validSingle) {

      this.modaleApplica(true, index)

      // this.setItem(true, index);

    } else {
      this.dialogServiceLayout.openSnack('controlla i campi richiesti', 'warning', 5000);
    }


  }

  private dataDet: any[] = [];
  private tableConfigOnly!: PaginationResponse;

  private getSessionsDetails() {
    const relativeUrl = `/sessions/${this.cache.product.id}/${this.cache.id}`;
    if (this.featureCode != this.featureCodeType.LOAD) {
      this.subs.add(
        this.http.get(`${environment.apiUrl}/v2/stocks${relativeUrl}`).pipe(debounceTime(50)).subscribe((data: any) => {
          if (data) {


            this.tableConfigOnly = data.meta?.pagination;
            this.dataDet = data?.data;
            if (this.dataDet && this.dataDet.length > 0) {
              this.dynacrudApiWrapper.getFor('warehouse').search({
                filter: {
                  filterBy: {
                    operation: OperationType.AND,
                    filters: [
                      {field: 'obsolete', value: 'false', operation: OperationType.EQ},
                      {field: 'virtual', value: 'false', operation: OperationType.EQ}
                    ]
                  }
                }
              }).pipe(map(d => d.data), take(1), debounceTime(environment.defaultDebounceTime)).subscribe(dataW => {


                if (dataW?.length > 0 && this.dataDet?.length > 0) {
                  this.dataDet.forEach((element: any, index: number) => {
                    Object.defineProperty(element, 'warehouses', {
                      value: dataW,
                      writable: true,
                      configurable: true,
                      enumerable: true
                    });
                    Object.defineProperty(element, 'id', {
                      value: '' + index,
                      writable: true,
                      configurable: true,
                      enumerable: true
                    });
                  });
                }
              });
              this.loadConfNewDinaTable();
            }
          }
        })
      );

    }


  }

  // void
  private loadConfNewDinaTable() {

    let det: any[] = [];

    if (this.dataDet && this.dataDet?.length > 0) {
      det = this.dataDet.map(newProductSession => {
        return newProductSession;
      });
    }


    this.dataSourcetSub.next({
      data: det as [],
      tableStatus: {
        sort: [],
        page: {pageSize: 99999, pageIndex: 0, length: 99999}
      }
    });

  }

  ngAfterViewInit(): void {
    this.subs.add(
      this.overLayConected?.overlayOutsideClick.pipe(debounceTime(10)).subscribe(() => {
        Object.keys(this.isOpen).forEach(d => {
          this.isOpen[d] = false;
        })
      })
    );
  }

  public reset() {


    this.objectCacheItem = {};
    this.listProducts = _.cloneDeep([]);

    this.cache = _.cloneDeep(this.dataOver2.item);
    this.modelData = {...this.cache};
    this.listProducts.push({...this.modelData});
    this.objectCacheItem[0] = {...this.modelData};
    // this.ngOnInit();
  }

  get quantityItem(): number {
    return this.modelData['quantity'];
  }

  set quantityItem(v: number) {
    this.modelData['quantity'] = v;
  }

  get warehouseRow(): number | undefined {
    return this.modelData['warehouse']?.id;
  }


  set warehouseRow(v: number | undefined) {
    this.modelData['warehouse'] = this.warehouses.find(w => w.id == v);

  }

  get commentRow(): string | undefined {
    return this.modelData?.['comments'];
  }


  set commentRow(v: string | undefined) {
    this.modelData['comments'] = v;
  }

  getDataField(id: number, field: string) {
    const me = this;
    return {
      get combinationLookUp() {
        if (field) {
          return me.listProducts[id]['combination'][field];
        }
      },
      set combinationLookUp(v: any) {
        const item2 = _.cloneDeep(me.listProducts.find((d, iOn) => {
          return iOn == id;
        }));
        if (field && item2 && v != undefined) {
          item2['combination'][field] = v;
          me.objectCacheItem[id]['combination'][field] = _.cloneDeep(item2['combination'][field]);
        }
      },
    }

  }

  objectCacheItem: { [key: string]: any } = {};

  getData(id: number, field?: any) {
    const me = this;
    return {
      get quantity() {
        return me.objectCacheItem[id]['quantity'];
      },

      set quantity(v: any) {
        me.objectCacheItem[id]['quantity'] = v;
      },

      

      
      get purchasePrice() {
        return me.objectCacheItem[id]['purchasePrice'];
      },
      set purchasePrice(v: any) {

        me.objectCacheItem[id]['purchasePrice'] = v;

      },

      get comments() {
        return me.objectCacheItem[id]['comments'];
      },
      set comments(v: any) {

        me.objectCacheItem[id]['comments'] = v;

      },

      get warehouseRow() {
        return me.objectCacheItem[id]['warehouse']?.id;
      },
      set warehouseRow(v: any) {

        me.objectCacheItem[id]['warehouse'] = me.warehouses.find(w => w.id == v);


      },

      get combination() {
        if (field) {
          return me.listProducts[id]['combination'][field];

        }
      },
      set combination(va: any) {
        const item = _.cloneDeep(me.listProducts.find((d, i) => i == id));

        if (field && item && va != undefined) {

          item['combination'][field] = va;

          // me.listProducts[id] = _.cloneDeep(item);
          me.objectCacheItem[id]['combination'][field] = _.cloneDeep(item['combination'][field]);


        }
      },

      // combinationFlag

      // combinationLookUp


    }
  }

  valid() {


    let arrItems: any[] = [];

    Object.keys(this.objectCacheItem).forEach(d => {
      arrItems.push(this.objectCacheItem[d]);
    });

    const v = _.cloneDeep(arrItems);


    return v.filter(d => d && d.combination && d.combination.conditionId != undefined && d.combination.languageId != undefined && d.quantity != undefined && d.warehouse && d.warehouse.id != undefined);


  }

  singleValid(index: number) {


    return {
      items: [this.objectCacheItem[index]].filter((d) => d && d.combination && d.combination.conditionId != undefined && d.combination.languageId != undefined && d.quantity != undefined && d.warehouse && d.warehouse.id != undefined),

      validSingle: this.objectCacheItem[index] && this.objectCacheItem[index].combination && this.objectCacheItem[index].combination.conditionId != undefined && this.objectCacheItem[index].combination.languageId != undefined &&
        this.objectCacheItem[index].quantity != undefined && this.objectCacheItem[index].warehouse && this.objectCacheItem[index].warehouse.id != undefined
    };
  }


  showTable = false;
  featureTypeCompTrans: RouteConf = NewMovementNotIdTRANSRoutConfssigPerEDIT;
  warehouses: Warehouse[] = [];
  featureTypeCompUnload: RouteConf = NewMovementNotIdUNLOADRoutConfssigPerEdit;

  ngOnInit(): void {

    this.dynacrudApiWrapper.getFor('warehouse').search({
      filter: {
        filterBy: {
          operation: OperationType.AND, filters: [
            {field: 'obsolete', operation: OperationType.EQ, value: false}, {
              field: 'virtual',
              operation: OperationType.EQ,
              value: false
            }
          ]
        }
      }
    }).pipe(map(d => d.data as Warehouse[])).subscribe(wh => {
      this.warehouses = wh;
    });

    this.getSessionsDetails();

    this.modelData['brand'] = this.cache.product.brand;
    this.modelData['category'] = this.cache.product.category;

    const productStructure: ProductStructure = this.productStructureService.findStructure(this.cache.product.categoryId, this.cache.product.brandId);
    if (productStructure) {
      this.productStructure = productStructure;
      this.productStructure.fields = [];

    }

    setTimeout(() => {
      this.modelData = {...this.cache};

      this.productStructure?.stockfields?.forEach(field => {
        if (this.cache.combination[field.field as string] != undefined) {
          this.modelData['combination'][field.field as string] = this.cache.combination[field.field as string];
        }
      });

      this.listProducts.push({...this.modelData});
      this.objectCacheItem[0] = {...this.modelData};


    }, 0);

    setTimeout(() => {

      if (this.featureCode && this.featureCode == TypeModalSessionProduct.TRANSFER) {


        this.defaultListConfCustom = {
          ...NewMovementNotIdTRANSRoutConfssigPerEDIT.attributes?.['DefaultListComponent']
        };

      } else if (this.featureCode == TypeModalSessionProduct.UNLOAD) {

        this.defaultListConfCustom = {
          ...NewMovementNotIdUNLOADRoutConfssigPerEdit.attributes?.['DefaultListComponent']
        };


      }


      if (this.defaultListConfCustom && this.defaultListConfCustom.tableConfig) {

        this.defaultListConfCustom.tableConfig = {
          ...this.defaultListConfCustom.tableConfig,
          paginatorMode: 'none'
        };

      }

      this.showTable = true;

    }, 0);


  }


  tableStatusChangeCart(tableStatus: TableStatus) {


    this.tableConfigOnly.currentPage = tableStatus?.page?.pageIndex;
    this.tableConfigOnly.totalElements = tableStatus?.page?.length;
    this.tableConfigOnly.pageSize = tableStatus?.page?.pageSize;

    const searchRequest: SearchRequest = {};
    if (tableStatus && tableStatus.sort) {
      searchRequest.sort = tableStatus.sort.map(s => {
        return {field: s.field, direction: SortDirection[s.direction.toUpperCase() as SortDirection]};
      });
    }
    if (tableStatus && tableStatus.page) {
      searchRequest.pagination = {page: tableStatus.page.pageIndex, size: tableStatus.page.pageSize};
    }

  }

  rows: any[] = [];

  public getRows(items: any) {
    this.rows = items.rows;
    this.quantityRows = this.rows.length || 0;

  }


  private preSave(singleValid?: boolean, id?: number): any[] {
    // pick value json no brand ne category
    let value = singleValid && (id != undefined) ? this.singleValid(id).items : this.valid();

    if (this.featureCode != this.featureCodeType.LOAD) {


      value = [...this.rows];


    }


    return value;

  }

  selectedWarehouseFrom(warehouse: Warehouse, index: number) {
    this.warehouseRow = warehouse.id;

    this.objectCacheItem[index]['warehouse'] = warehouse;
  }

  setItem(singleItem?: boolean, id?: number) {

    this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, true));
    const value: any[] = singleItem && (id != undefined) ? this.preSave(singleItem, id) : this.preSave();

    const newValue: WarehouseMovementDTO[] = [];

    if (this.featureCode == this.featureCodeType.LOAD) {
      newValue.push(...value.map(value => {

          return {
            productId: value.product.id,
            quantity: value?.quantity,
            purchasePrice: value?.purchasePrice,
            conditionId: value?.combination?.conditionId || null,
            languageId: value?.combination?.languageId || null,
            holoId: value?.combination?.holo?.id != null ? value?.combination?.holo?.id : null,
            signed: value?.combination?.signed != null ? value.combination.signed : null,
            stamped: value?.combination?.stamped != null ? value.combination.stamped : null,
            inked: value?.combination?.inked != null ? value.combination.inked : null,
            altered: value?.combination?.altered != null ? value.combination.altered : null,
            foil: value?.combination?.foil != null ? value.combination.foil : null,
            firstEdition: value?.combination?.firstEdition != null ? value.combination.firstEdition : null,
            shadowless: value?.combination?.shadowless != null ? value.combination.shadowless : null,
            staff: value.combination?.staff != null ? value.combination.staff : null,
            // warehouseFromId: value.warehouse?.id,
            warehouseToId: value?.warehouse?.id || null,
            comments: value?.comments || null,
          };

        })
      );

    } else if (isArray(value)) {

      newValue.push(...value.map(item => {

        const valueOwn = item.row;
        return {
          productId: valueOwn.product.id,
          quantity: valueOwn.instanceValue,
          purchasePrice: valueOwn?.purchasePrice,
          conditionId: valueOwn?.combination?.condition.id || null,
          languageId: valueOwn?.combination?.language.id || null,
          holoId: valueOwn?.combination?.holo?.id != null ? valueOwn?.combination?.holo?.id : null,
          signed: valueOwn?.combination?.signed != null ? valueOwn.combination.signed : null,
          stamped: valueOwn?.combination?.stamped != null ? valueOwn.combination.stamped : null,
          inked: valueOwn?.combination?.inked != null ? valueOwn.combination.inked : null,
          altered: valueOwn?.combination?.altered != null ? valueOwn.combination.altered : null,
          foil: valueOwn?.combination?.foil != null ? valueOwn.combination.foil : null,
          firstEdition: valueOwn?.combination?.firstEdition != null ? valueOwn.combination.firstEdition : null,
          shadowless: valueOwn?.combination?.shadowless != null ? valueOwn.combination.shadowless : null,
          staff: valueOwn.combination?.staff != null ? valueOwn.combination.staff : null,
          warehouseFromId: valueOwn.warehouse?.id,
          warehouseToId: valueOwn?.instanceValueWarehouse?.id || null,
          comments: valueOwn?.comments || null,
        };

      }));

    }

    // this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
    // ''
    // return ;

    this.subs.add(
      this.dialogServiceEditSession.updateItemModal(parseInt(this.dataOver2.idSession), this.cache.id, [...newValue]).pipe(debounceTime(50), catchError(err => {
        this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
        return throwError(() => {
          return err;
        });
      })).subscribe(() => {

        this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));

        this.dialogServiceLayout.openSnack('Congratulazioni!, hai fatto la modifica', 'done');
        this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
        this.dialogServiceEditSession.setReload(true);
        this.dialog.closeAll();


      })
    );


  }


  modaleReset() {
    const options = {
      title: `Vuoi resettare tutto?`,
      message: ``,
      cancelText: `Cancel`,
      confirmText: `OK`
    };
    this.dialogServiceLayout.open({...options});
    this.dialogServiceLayout.confirmed().pipe(distinctUntilChanged()).subscribe(res => {
      if (res) {

        this.reset();

      }
    });


  }

  modaleApplica(single?: boolean, id?: number) {
    const options = {
      title: `Sei sicuro di cancellare e di ricreare questo prodotto?`,
      message: `Questa azione elimina e ricrea il prodotto, per favore attento alla modifica`,
      cancelText: `Cancel`,
      confirmText: `OK`
    };
    this.dialogServiceLayout.open({...options});
    this.dialogServiceLayout.confirmed().pipe(distinctUntilChanged()).subscribe(res => {
      if (res) {
        this.setItem(!!single, (id != null) ? id : undefined);
      }
    });
  }


}
