import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State, selectAuth } from 'src/app/store/auth/auth.reducers';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-get-role-user',
  templateUrl: './get-role-user.component.html',
  styleUrls: ['./get-role-user.component.scss']
})
export class GetRoleUserComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  public authRole: {[key: string]: any} = {};

  constructor(private store: Store<State>) {}
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    this.subs.add(
      this.store.pipe(select(selectAuth) ).subscribe(auth => {

        if (auth && auth.roles && auth.roles.length > 0) {
          auth.roles.forEach(rl => {
            this.authRole[rl] = rl;
  
          });

        }

      })
    );
  }

}
