import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { BreadcrumService } from '../../services/breadcrum.service';
import { ActivatedRoute } from '@angular/router';

export interface Breadcrumb {
  label: string;
  url: string;
}


@Component({
  selector: 'app-breadcrum',
  templateUrl: './breadcrum.component.html',
  styleUrls: ['./breadcrum.component.scss']
})
export class BreadcrumComponent {

  breadcrumbs$!: Observable<Breadcrumb[]>;

  constructor(private breadcrumService : BreadcrumService) {

  }

}
