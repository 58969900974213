import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ColumnData, ColumnFieldComponent } from 'src/app/core/components/table/Column';
import { ProductStructure, ProductStructureField, ProductStructureFieldType } from 'src/app/shared/models/entities';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { SubSink } from 'subsink';
import * as _ from 'lodash';

interface FullField {
  obj: any;
  structureField: ProductStructureField;
}


interface FullFieldObject {
  obj: any;
  structureField: any;
}

@Component({
  selector: 'app-structure-info-column',
  templateUrl: './structure-info-column.component.html',
  styleUrls: ['./structure-info-column.component.scss']
})
export class StructureInfoColumnComponent implements ColumnFieldComponent, OnInit, OnDestroy {

  private readonly iconColumns = ['foil', 'altered', 'inked', 'stamped', 'firstEdition', 'shadowless', 'signed', 'staff', 'holo'];
  private readonly iconColumnsLanguage = ['American', 'Ceco', 'Cinese-S', 'Cinese-T', 'Coreano', 'Francese', 'Giapponese', 'Indonesiano', 'Inglese', 'Italiano', 'Olandese', 'Polacco', 'Portoghese', 'Russo', 'Spagnolo', 'Svedese', 'Tailandese', 'Tedesco', 'Ungherese'];
  private readonly iconColumnsCondition = ['Excellent', 'Good', 'Light Played', 'Near Mint', 'Played', 'Poor', 'Mint'];


  @Input()
  data!: ColumnData;

  private subs = new SubSink();

  productPath = '';
  stockPath = '';
  omitFields: string[] = [];
  productStructure!: ProductStructure;
  fields: FullField[] = [];
  icons!: any[];

  groupValue: any[] = [];

  constructor(private productStructureService: ProductStructureService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer) { }


  ngOnInit() {
    this.subs.add(this.productStructureService.isReadyObs().subscribe(ready => {
      if (ready) {

        if (this.data.field.attributes) {
          this.productPath = this.data.field.attributes['productPath'] || this.productPath;
          this.stockPath = this.data.field.attributes['stockPath'] || this.stockPath;
          this.omitFields = this.data.field.attributes['omitFields'] || this.omitFields;

          this.groupValue = this.data.field.attributes['groupValue'] || this.groupValue;
        }



        if (this.data.row) {
          const row = this.data.row;

          const categoryId: number | null = _.result(row, (this.productPath.length > 0 ? this.productPath + '.' : '') + 'category.id', null) as number | null;
          const brandId: number | null = _.result(row, (this.productPath.length > 0 ? this.productPath + '.' : '') + 'brand.id', null) as number | null;
          this.productStructure = this.productStructureService.findStructure(categoryId as number, brandId as number);

          if (this.productStructure) {

            if (this.groupValue && this.groupValue.length > 0) {
             
              const groupFieldsToMap = this.groupValue?.map((d: string) => d.substring(12, d.length)) || [];
              const groupFieldsMaped = groupFieldsToMap?.map(s => { if (s && s.length > 7) { return s.substring(0, s.length - 3) } return s; }) || [];
              const fieldsToshow = this.productStructure.allFields?.filter(f => ((groupFieldsMaped?.findIndex(a => a === f.field) != -1) || this.omitFields.findIndex(s => s === f.field) === -1) );

              if (fieldsToshow) {
                this.fields = fieldsToshow.map(f => ({ obj: _.result(row, f.stock ? this.stockPath : this.productPath, row), structureField: f }));
              }

            } else {

              const fieldsToshow = this.productStructure.allFields?.filter(f => (this.omitFields.findIndex(s => s === f.field) === -1) );

              if (fieldsToshow) {
                this.fields = fieldsToshow.map(f => ({ obj: _.result(row, f.stock ? this.stockPath : this.productPath, row), structureField: f }));
              }

            }




          }
        }
      }
    }));

  }

  getPositionSprite(i: any): { x?: number, y?: number } {
    if (i) {
      let x = this.getFloor((i % 10)) * 21;
      let y = this.getFloor((i / 10)) * 21;
      return { x: x, y: y };
    }

    return { x: undefined, y: undefined }
  }

  getFloor(x: any) {
    return Math.floor(x);
  }

  isImage(structureField: ProductStructureField) {
    return this.iconColumns.findIndex(s => s === structureField.field) !== -1;
  }

  isImageLanguage(lingua: string | undefined) {
    return this.iconColumnsLanguage.findIndex(s => s === lingua) !== -1;
  }

  isImageCondition(condition: string | undefined) {
    return this.iconColumnsCondition.findIndex(s => s === condition) !== -1;
  }

  getType(ff: FullField): FullField | undefined {
    if (ff && ff.structureField.type == ProductStructureFieldType.LOOKUP) {
      if ( (ff.structureField.field == 'expansion') || (ff.structureField.field == 'rarity')) {
        return ff;
      }
    }

    return undefined;
  }


  getValue(ff: FullField | undefined): string | undefined {

    if (ff) {
      const val = ff.structureField.type == ProductStructureFieldType.FLAG ? ff.structureField.field : ff.obj[ff.structureField.field as string];

      if (val) {
        return val.name || val.description || val.code || val.id || val;
      }

      return val;
    }
    return undefined;
  }

  getImagePath(ff: FullField): string | null {
    const val = ff.obj[ff.structureField.field as string];
    let id = '';
    if (!val) { return null; }

    if (val.id !== undefined) {
      if (val.id) {
        id = '_' + val.id;
      } else {
        return null;
      }

    }
    // this.matIconRegistry.addSvgIcon(
    //   `${ff.structureField.field}`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/img/structure/${ff.structureField.field}${id}.svg`)
    // );
    return `assets/img/structure/${ff.structureField.field}${id}.svg`;

  }

  public getImagePathCondition(ff: FullFieldObject): string | null {
    const val = ff.obj[ff.structureField.field]?.id ? ff.obj[ff.structureField.field]?.description : null;
    let id = '';
    if (!val) { return null; }

    if (val) {
      id = val;
      if (id.includes('%')) {
        id.replace(/%/g, ' ');
      } else {
        id = val;
      }
    } else {
      return null;
    }
    // this.matIconRegistry.addSvgIcon(
    //   `${id}`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/condition/${id}.svg`)
    // );
    return `assets/icons/condition/${id}.svg`;

  }

  public getImagePathLanguage(ff: FullFieldObject) {
    const val = ff.obj[ff.structureField.field]?.id ? ff.obj[ff.structureField.field]?.name : null;
    let id = '';
    if (!val) { return null; }

    if (val) {
      id = val;
    } else {
      return null;
    }
    // this.matIconRegistry.addSvgIcon(
    //   `${id}`,
    //   this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/language/${id}.svg`)
    // );
    return `assets/icons/language/${id}.svg`;

  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
