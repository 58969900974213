import { Component, Input, OnInit } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { Observable, Subject } from 'rxjs';

const color = 'colorSelectAll'

@Component({
  selector: 'app-default-select-all-button',
  templateUrl: './default-select-all-button.component.html',
  styleUrls: ['./default-select-all-button.component.scss']
})
export class DefaultSelectAllButtonComponent implements OnInit {

  @Input() matSelect!: MatSelect;
  @Input() disabledItem!: boolean;
  @Input() disabledAll!: Subject<any>;

  colorValue: string = '';

  get color(): string {
    return this.colorValue;
  }

  set color(v: string) {

    this.colorValue = v;

    localStorage.setItem(color, '' + v);

    console.log(this.colorValue);

  }





  constructor() { }

  ngOnInit(): void {


    this.color = localStorage.getItem(color) || 'false';



    this.matSelect.valueChange.pipe().subscribe(result => {
      console.log(result);
      if (result) {
        const m = [...this.matSelect.options];
        let sum = 0;
        let a = 0;
        m.map((d, i) => {
          a += i;
          return a;
        });

        result.forEach((item: MatOption, i: any) => {
    
          if (item && item.value) {
            item.select();
            if (item.selected) {
              sum += i;
    
            }
          }
    
          if (sum == a) {
            console.log(sum, a);
            this.color = 'true';
    
          } else {
    
            this.color = 'false';
    
          }
        });



      }
    })
  }

  selectAllToggle() {
    const m = [...this.matSelect.options];
    let sum = 0;
    let a = 0;
    m.map((d, i) => {
      a += i;
      return a;
    });


    this.matSelect.options.forEach((item: MatOption, i) => {
    
      if (item && item.value) {
        item.select();
        if (item.selected) {
          sum += i;

        }
      }

      if (sum == a) {
        console.log(sum, a);
        this.color = 'true';

      } else {

        this.color = 'false';

      }
    });


    // !lo dejo por si acaso , tal vez cambien de idea.
    // const selected = this.matSelect.options.find(opt => opt.selected);
    // if (selected) {
    //   this.matSelect.options.forEach((item: MatOption) => item.deselect());
    // } else {
    // }
  }

}
