import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor(private notificationsService: NotificationService) {
    super();
  }

  override handleError(error: Error | HttpErrorResponse) {
    let displayMessage = error?.message || 'An error occurred.';

    if (error instanceof HttpErrorResponse) {
      const httpErr = error?.error;
      displayMessage =  typeof httpErr == 'string' ? httpErr : httpErr?.message || httpErr?.error || httpErr?.errorMessage || httpErr?.error?.errorMessage || error?.message || httpErr;
    }

    if (!environment.production) {
      displayMessage += ' See console for details.';
    }

    this.notificationsService.error((error as any)?.error?.message?.includes('uni_order_cat') ? 'Controlla il order number type!' : displayMessage );
    //FIXME K3 provvisorio
    //this.store.dispatch(new BaseAction(LayoutActionType.Loading, false));
    
    super.handleError(error);
  }
}
