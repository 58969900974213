import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, map, take, throwError } from 'rxjs';
import { WarehouseSessionService } from 'src/app/features/warehouse/services/warehouse-session.service';
import { WarehouseSessionEdit } from 'src/app/shared/models/entities';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit {
  constructor(public titleService: Title, private router: Router, private activateRouter: ActivatedRoute, private store: Store<State>, private warehouseSessionService: WarehouseSessionService) { }
  idSession: number | undefined = undefined;

  subs = new SubSink();
  memorySession: any = {};

  get session() {
    return this.memorySession;
  }

  set session(v: any) {
    this.memorySession = v;

  }

  systemSession : boolean | undefined;

  isCompletedDaTable: boolean = false;

  ngOnInit(): void {
    this._editNameValue = this.titleService.getTitle();

    this.activateRouter.firstChild?.params.subscribe(param => {
      this.idSession = +param['id'];
    });

    // distinctUntilChanged()
    let first = true;


    this.subs.add(
      this.warehouseSessionService.getSessionObs.pipe(distinctUntilChanged(), debounceTime(150)).subscribe(d => {

        this.session = d;
        this.systemSession = this.session?.systemSession != undefined ?  this.session.systemSession : undefined;
        this.idSession = this.session.id;
        if (this.session && this.session.name && first) {
          this.editNameModel = this.session.name;
          this.editName = false;
        }

        this.warehouseSessionService.dataAggiorna.pipe(debounceTime(environment.defaultDebounceTime), distinctUntilChanged()).subscribe((data: boolean) => {
          this.isCompletedDaTable = data;
          //do what ever needs doing when data changes
        });


      })
    );
  }

  @Input() isEditTitle: boolean | undefined = false;
  @ViewChild('inputTitle') inputTitle!: ElementRef;
  editName = false;
  _editNameValue!: string;

  get editNameModel(): string {
    return this._editNameValue;
  }

  set editNameModel(v: string) {
    this._editNameValue = v;
  }


  openEditare() {
    this._editNameValue = this.titleService.getTitle();
  }

  private preSave(): WarehouseSessionEdit {
    return { id: this.idSession as number, name: this.editNameModel, priority: this.memorySession.priority };
  }

  save() {

    const value = this.preSave();

    this.subs.add(

      this.warehouseSessionService.saveEditSession(value).pipe(debounceTime(50), catchError(err => {
        this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
        this.warehouseSessionService.getListSession(true);
        return throwError(() => err);
      }), map(a => a.data)).subscribe(d => {

        this.editNameModel = d.name;
        this.session.name = this.editNameModel;

        this.titleService.setTitle(this.session.name);

       
        

        this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
        this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
        // this.titleService.setTitle(d.data.name);
        this.warehouseSessionService.fullReloadSession(true);
        this.warehouseSessionService.getListSession(true);

        this.editName = false;

      })
    );






  }
}

