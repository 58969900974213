import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { WorkingMovement } from 'src/app/shared/models/entities';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WarehouseMovementsService {

  constructor(private http: HttpClient) { }
  public completeMovement(sessionId: number): Observable<DynacrudApi<WorkingMovement>> {
    return this.http.patch<DynacrudApi<WorkingMovement>>(`${environment.apiUrl}/v2/workingMovements/${sessionId}/complete`, null)
      .pipe(catchError(error => {
        return throwError(error);
      }));
  }


  public completeAllMovement(sessionId: number[]): Observable<DynacrudApi<WorkingMovement>> {

    return this.http.patch<DynacrudApi<WorkingMovement>>(`${environment.apiUrl}/v2/workingMovements/complete`, sessionId).pipe(catchError(error => { 
      return throwError(error); 
    }));


  }

}
