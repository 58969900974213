import { Injectable, Type } from '@angular/core';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { Color, Entity } from '../models/entities';
import { DynacrudWeakDataService } from './DynacrudWeakDataService';



@Injectable({
  providedIn: 'root'
})
export class ColorService extends DynacrudWeakDataService<Color> {

  
  constructor(dynacrudApiWrapper: DynacrudApiWrapper) {
    super('colors', ColorService, dynacrudApiWrapper);
  }

  getType(): Type<Color> | string {
    return 'color';
  }
}
