import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { PositionChild } from 'src/app/core/models/route-config';
import { selectRouteConf, State } from 'src/app/store/shared/shared.reducers';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-layout-position',
  templateUrl: './layout-position.component.html',
  styleUrls: ['./layout-position.component.scss']
})
export class LayoutPositionComponent implements OnInit, OnDestroy {

  @Input('position') position!: string;


  private subs = new SubSink();
  children!: PositionChild[];

  constructor(private store: Store<State>) {}

  ngOnInit() {
    this.subs.add(this.store.pipe(select(selectRouteConf)).subscribe(routeConf => {
      if (routeConf && routeConf.position && routeConf.position[this.position]) {
        this.children = routeConf.position[this.position].children;
      } else {
        this.children = [];
      }
    }));

  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
