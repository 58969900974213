<div class="default_item__cust input-group input-group-sm">
  <!-- (change)="objectCont(itemInput.value ? itemInput.value : null ) " -->
  <div class="c_1">
      <input [ngClass]="{ 'error' : error, 'noError': (!error) }"
      min="0" type="number" (ngModelChange)="objectCont(itemInput.value ? itemInput.value : null ) "
      [value]=" bindingResultWarehouse " #itemInput
      [(ngModel)]=" bindingSingle(data.row.id).valId || bindingResultWarehouse " class="form-control item__">
  </div>
  <div class="c_2">
      <select (click)="objectCont(bindingSingle(data.row.id).item)" #typeSelect="ngModel" class="form-select form-select-sm item__"  style="background-position: center !important;" name="row_{{data.row?.id}}_item"
      [(ngModel)]="bindingSingle(data.row.id).item">
          <option [ngValue]="null"></option>
          <option *ngFor="let item of whHost" [ngValue]="item">
              <span style="color: transparent;">{{item?.id}} - {{item?.name}}</span>
          </option>
      </select>
  </div>
</div>

