import { Brand, Category, Condition, Entity, Expansion, Language, Rarity, User } from "src/app/shared/models/entities";
import { ConditionPercentage, ConditionPercentageToWrap, LanguagePercentage, LanguagePercentageToWrap } from "../../prices/models/entities";

export const WarehouseSessionStatuses: { OPEN: number, NOT_APPROVED: number, APPROVED: number, COMPLETED: number } = {
  OPEN: 1,
  NOT_APPROVED: 2,
  APPROVED: 3,
  COMPLETED: 4
};

export const MovementStatuses: { CREATED: number, TEMPORARY: number, COMPLETED: number } = {
  CREATED: 1,
  TEMPORARY: 2,
  COMPLETED: 3
};

export const FormuleTypesSessionWrapper = []

export interface MinCondition extends Entity {
  code: string;
  description: string;
  hashSize: number;
  name: string;
}
//
export interface MaxCondition extends Entity {
  code: string;
  description: string;
  hashSize: number;
  name: string;
}

export enum TypeModalSessionProduct {
  LOAD = 'LOAD',
  TRANSFER = 'TRANS',
  UNLOAD = 'UNLOAD'
}

export interface StockInventory extends Entity {
  [key: string]: any;
  foil?: boolean;
  bottomPriceRange?: number;
  topPriceRange?: number;
  description?: string;
  category?: Category;
  brand?: Brand;
  expansions: Expansion[];
  rarities: Rarity[];
  stockInventoryDefinition: StockInventoryDefinition[];
}


export interface StockInventoryDefinition extends Entity {
  language: Language | null;
  minCondition: MinCondition | null;
  maxCondition: MaxCondition | null;
  understockThreshold: number | null;
  overstockThreshold: number | null;
  total: boolean;
}

export const MOVEMENT_PRIORITY = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export interface WarehouseMovementDTO {
  productId?: number;
  languageId?: number;
  holoId?: number;
  conditionId?: number;
  warehouseFromId?: number;
  warehouseToId?: number;
  signed?: boolean;
  stamped?: boolean;
  inked?: boolean;
  altered?: boolean;
  foil?: boolean;
  firstEdition?: boolean;
  shadowless?: boolean;
  staff?: boolean;
  comments?: string;
  quantity?: number;
  purchasePrice?: number;
  [prop: string]: any; // funzionerebbe con una mappa generica
}

export interface WarehouseMovementDTORequired {
  brandId?: number;

  productId: number;
  languageId: number;
  holoId: number;
  conditionId: number;
  warehouseFromId: number;
  warehouseToId: number;
  signed: boolean;
  stamped: boolean;
  inked: boolean;
  altered: boolean;
  foil: boolean;
  firstEdition: boolean;
  shadowless: boolean;
  staff: boolean;
  comments: string;
  quantity: number;
  [prop: string]: any; // funzionerebbe con una mappa generica
}


export interface WrapperWarehouseSessions extends Entity {

  name: string;
  stateId: number;
  priority: number;
  user: User;
  status: Status;
  systemSession: boolean;
  motivation: Motivation;
  wrapper: SessionsWrapper;
}

// export interface User {
//   obsolete: boolean
//   modifiedDate: string
//   createdBy: string
//   modifiedBy: string
//   id: number
//   name: string
//   surname: string
//   warehouseId: number
//   email: string
//   siteLang: string
//   dateCreated: string
//   defaultDescription: string
// }

export interface Status extends Entity {
  description: string;
}

export interface Instruction {
  name: string;
  description: string;
  formula: string;
}

export interface Motivation {
  name: string;
  description: string;
}

export interface SessionsWrapper extends Entity {

  motivation: Motivation | undefined;
  totalPurchasePrice: number | null;
  description: string;
  instruction: Instruction | undefined;
  conditions: Condition[];
  languages: Language[];
  languagePercentages: LanguagePercentageToWrap[];
  conditionPercentages: ConditionPercentageToWrap[];
  excludedConditions: Condition[];
  autoCalculation: boolean;
  version?: number;
  fixedPrice?: number;

}

export interface Formula extends Entity {
  formula: string;
  name: string;
  description: string;
}