import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';

const noop = () => {
};

@Component({
  template: ''
})
export class ControlValueAccessorBaseImpl<T> implements OnInit, OnChanges, ControlValueAccessor  {
  
  onTouchedCallback: () => void = noop;
  onChangeCallback: (_: any) => void = noop;

  $value!: T | undefined ;
  disabled!: boolean;

  @Input()
  readonly!: boolean;

  @Input()
  set required(req: boolean) {
    this.$required = req;
  }
  $required = false;
  get isRequired(): boolean {
    return this.$required;
  }
   get value() {
    return this.$value;

  }

  set value(val) {
    this.$value = val;
    this.onChangeCallback(val);
    this.onTouchedCallback();

  }

  writeValue(obj: any): void {

    this.value = obj;

  }

  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit(): void {
  }
}
