<ng-container *ngIf="multiple">
    <ng-container *ngIf="!readonly; else readonlyOut" class="reset">
        <ng-container *ngIf="source == 'expansion' || source == 'language'; else noMultipleFields">
            <mat-form-field *ngIf="!readonly; " class="selectOPT" appearance="outline">
                <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}} </mat-label>

                <button class="btn_" (click)="selectAllToggle(selectRef)" matTooltip="Select all" matPrefix [disableRipple]="true"
                    mat-icon-button>
                    <mat-icon
                        [color]=" value?.length == itemsMemory.length ? 'primary' : value?.length > 0 ? 'accent' : 'warn' ">check_circle</mat-icon>
                </button>

                <mat-select disableRipple (openedChange)="isOpenBro($event)" [(ngModel)]="value"
                    [disabled]="(!itemsMemory || itemsMemory.length == 0) || disabled" #selectRef multiple
                    [compareWith]="compareEntity">



                    <mat-option class="p-0">
                        <div class="d-flex flex-row justify-content-between w-100 custom__">

                            <div class="d-flex align-items-center">
                                <button matPrefix (click)="selectAllToggle(selectRef)" [disableRipple]="true"
                                    mat-icon-button matTooltip="Select the searched items"
                                    class="d-flex gap-1 justify-content-between base_ w-100 ">
                                    <mat-icon
                                        [color]="value?.length == itemsMemory.length ? 'primary' : value?.length > 0 ? 'accent' : 'warn' ">check_circle</mat-icon>
                                </button>
                            </div>

                            <div class="d-flex align-items-center">
                                <ngx-mat-select-search [disableInitialFocus]="true" #valueNgxSelectSearch (paste)="pasteValue($event);" [hideClearSearchButton]="true" ngModel
                                    placeholderLabel="Search {{label}}" style="position: relative;"
                                    noEntriesFoundLabel="'Nessun resultato'"></ngx-mat-select-search>
                            </div>

                        </div>
                    </mat-option>


                    <mat-option *ngFor="let item of itemsMemory" [value]="item">
                        <div class="item_cont__">
                            <span>{{showValue(item)}}</span>
                            <app-column-icon-field-select [item]="item"
                                [brandId]="item.brandId"></app-column-icon-field-select>
                        </div>
                    </mat-option>
                </mat-select>
                <mat-hint *ngIf="value?.length > 1 && !hideHint">
                    ({{value?.length }} {{ 'total' | translate}})
                </mat-hint>


                <button mat-icon-button matSuffix (click)="clearValues(); $event.preventDefault()">
                    <mat-icon>
                        clear
                    </mat-icon>
                </button>


            </mat-form-field>
        </ng-container>

        <ng-template #noMultipleFields>

            <ng-container *ngIf="source == 'warehouse' ; else nowr">

                <mat-form-field *ngIf="!readonly; " class="selectOPT" appearance="outline">
                    <mat-label style="text-transform: capitalize;" *ngIf="label">
                        {{label | translate}}
                    </mat-label>

                    <button (click)="selectAllToggle(selectRefOp)" matTooltip="Select all"
                        *ngIf="!disabledItemAllButton" matPrefix [disableRipple]="true" mat-icon-button>
                        <mat-icon
                            [color]="value?.length == itemsMemory.length ? 'primary' : value?.length > 0 ? 'accent' : 'warn' ">check_circle</mat-icon>
                    </button>

                    <mat-select [required]="isRequiredVal" (openedChange)="isOpenBro($event)" [(ngModel)]="value"
                        [disabled]="(!itemsMemory || itemsMemory.length == 0) ||disabled" #selectRefOp multiple
                        [compareWith]="compareEntity">

                        <mat-option class="p-0">
                            <div class="d-flex flex-row justify-content-between w-100 custom__">

                                <div class="d-flex align-items-center">
                                    <button matPrefix (click)="selectAllToggle(selectRefOp)" [disableRipple]="true"
                                        mat-icon-button matTooltip="Select the searched items"
                                        class="d-flex gap-1 justify-content-between base_ w-100 ">
                                        <mat-icon
                                            [color]="value?.length == itemsMemory.length ? 'primary' : value?.length > 0 ? 'accent' : 'warn' ">check_circle</mat-icon>
                                    </button>
                                </div>

                                <div class="d-flex align-items-center">
                                    <ngx-mat-select-search #valueNgxSelectSearch [hideClearSearchButton]="true" ngModel
                                        placeholderLabel="Search {{label | translate}}" style="position: relative;"
                                        noEntriesFoundLabel="'Nessun resultato'"></ngx-mat-select-search>
                                </div>

                            </div>
                        </mat-option>



                        <mat-option *ngFor="let item of itemsMemory" [value]="item">
                            {{ showValue(item) }}
                        </mat-option>

                    </mat-select>
                    <mat-hint *ngIf="value?.length > 1 && !hideHint">
                        ({{value?.length }} {{ 'total' | translate}})
                    </mat-hint>

                    <button mat-icon-button matSuffix [disabled]="disabledClear" (click)="clearValues();">
                        <mat-icon>
                            clear
                        </mat-icon>
                    </button>

                </mat-form-field>

            </ng-container>

            <ng-template #nowr>

                <mat-form-field *ngIf="!readonly; " class="selectOPT" appearance="outline">
                    <mat-label style="text-transform: capitalize;" *ngIf="label">
                        {{label | translate}}
                    </mat-label>

                    <button (click)="selectAllToggle(selectRefOp)" matTooltip="Select all"
                        *ngIf="!disabledItemAllButton" matPrefix [disableRipple]="true" mat-icon-button>
                        <mat-icon
                            [color]="value?.length == itemsMemory.length ? 'primary' : value?.length > 0 ? 'accent' : 'warn' ">check_circle</mat-icon>
                    </button>

                    <mat-select [required]="isRequiredVal" [(ngModel)]="value"
                        [disabled]="(!itemsMemory || itemsMemory.length == 0) ||disabled" #selectRefOp multiple
                        [compareWith]="compareEntity">



                        <mat-option *ngFor="let item of itemsMemory" [value]="item">
                            {{ showValue(item) }}
                        </mat-option>

                    </mat-select>
                    <mat-hint *ngIf="value?.length > 1 && !hideHint">
                        ({{value?.length }} {{ 'total' | translate}})
                    </mat-hint>

                    <button mat-icon-button matSuffix [disabled]="disabledClear" (click)="clearValues();">
                        <mat-icon>
                            clear
                        </mat-icon>
                    </button>

                </mat-form-field>

            </ng-template>

        </ng-template>




    </ng-container>
</ng-container>



<ng-container *ngIf="!multiple">
    <ng-container *ngIf="!readonly; else readonlyOut">
        <mat-form-field class="selectOPT" appearance="outline">
            <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
            <mat-select [compareWith]="compareEntity" [disabled]="disabled" [(ngModel)]="value">
                <mat-option *ngFor="let item of itemsMemory" [value]="item">
                    {{showValue(item)}}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-container>
</ng-container>

<ng-template #readonlyOut>
    <mat-form-field class="selectOPT" appearance="outline">
        <mat-label style="text-transform: capitalize;" *ngIf="label">{{label | translate}}</mat-label>
        <input [readonly]="true" matInput type="text" value="{{showValue(value)}}">
    </mat-form-field>
</ng-template>