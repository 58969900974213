<ng-container *ngIf="fields && fields.length > 0">

    <ng-container *ngFor="let field of fields; let first = first; let last = last">

        <ng-container *ngIf="!isImage(field.structureField) ;">
            <ng-container *ngIf="field.structureField.type != 'FLAG'">
                <!--                        {{getValue(field)}} {{' '}}-->
                <ng-container *ngIf="isImageLanguage(getValue(field)) && getImagePathLanguage(field)">
                    <img NgOptimizedImage width="20" height="20"
                        [src]="getImagePathLanguage(field) ? (getImagePathLanguage(field)) : ''"
                        matTooltip="{{(getValue(field) || '') | translate}}"
                        [alt]="(getValue(field) || '') | translate">
                    <!-- <mat-icon [svgIcon]="getImagePathLanguage(field) || ''" class="mat-icon-small" matTooltip="{{(getValue(field) || '') | translate}}"></mat-icon> -->
                </ng-container>

                <ng-container *ngIf="isImageCondition(getValue(field)) && getImagePathCondition(field)">
                    <img NgOptimizedImage width="20" height="20"
                        [src]="getImagePathCondition(field) ? (getImagePathCondition(field) ) : ''"
                        matTooltip="{{(getValue(field) || '') | translate}}"
                        [alt]="(getValue(field) || '') | translate">
                    <!-- <mat-icon [svgIcon]="getImagePathCondition(field) || '' " class="mat-icon-small" matTooltip="{{getValue(field) || '' | translate}}"></mat-icon> -->
                </ng-container>

                <ng-container *ngIf="getType(field); ">
                
                    <span class="span_expansion_name"
                        *ngIf="getType(field) && getType(field)?.['structureField']?.['field'] == 'expansion'">
                        <app-column-icon-field-select [item]="data.row.product?.expansion"
                            [brandId]="data.row.product.brandId"></app-column-icon-field-select>
                        <span style="width: 100%; display: flex;">{{ getValue(getType(field)) }}</span>
                    </span>
                    <span class="span_rarity_name" *ngIf="getType(field)?.['structureField']?.['field'] == 'rarity'">
                        <span style="width: 100%;">{{ getValue(getType(field)) }}</span>
                    </span>


                </ng-container>
                
                <ng-container
                    *ngIf="!isImageCondition(getValue(field)) && !isImageLanguage(getValue(field)) && !getType(field);">
                    <span style="display: flex; align-items: center;"> {{ getValue(field) }} </span>
                </ng-container>

                <!-- <ng-container *ngIf="!isImageCondition(getValue(field)) && !isImageLanguage(getValue(field)) && !getType(field);">
                    <span style="display: flex; align-items: center;">{{getValue(field)}} {{' '}}</span>
                </ng-container> -->




            </ng-container>
            <ng-container *ngIf="field.structureField.type == 'FLAG' && field.obj[field.structureField.field || '']">
                <span>{{field.structureField.field}}</span>
            </ng-container>
        </ng-container>
        <ng-container *ngIf="isImage(field.structureField) && getImagePath(field)">
            <img NgOptimizedImage width="20" height="20" [src]="getImagePath(field) ? (getImagePath(field)) : ''"
                matTooltip="{{(getValue(field) || '') | translate}}" [alt]="(getValue(field) || '') | translate">
            <!-- <mat-icon [svgIcon]="getImagePath(field) || ''" class="mat-icon_small" matTooltip="{{getValue(field) || '' | translate}}"></mat-icon> -->
        </ng-container>
    </ng-container>
</ng-container>