import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged } from 'rxjs';
import { Filter, OperationType } from 'src/app/core/models/dynacrud';
import { DynaCrudRequest, DynaConfActionType, State, selectCurrentDynaData, selectDynaCrudRequest } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-stock-template-filters',
  templateUrl: './stock-template-filters.component.html',
  styleUrls: ['./stock-template-filters.component.scss']
})
export class StockTemplateFiltersComponent implements OnInit, OnDestroy {

  private crudRequest: DynaCrudRequest = { searchRequest: {} };
  constructor(private store: Store<State>) { }
  subs = new SubSink();
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  ngOnInit(): void {
    let first = true;


    this.subs.add(

      this.store.pipe(select(selectDynaCrudRequest), distinctUntilChanged()).subscribe(request => {

        if (request) {

          this.crudRequest = request;
        }

        if (first) {
          first = false;
          this.search();
        }
      })


    );

  

  }

  private search() {
    const possibleFilters: Filter[] = [{value: 'false', operation: OperationType.EQ, field: 'obsolete'}];

    for (const f of possibleFilters) {
      if (f.operation === OperationType.IN) {
        const arrayValue = f.value as any[];
        if (arrayValue.length === 1) {
          f.operation = OperationType.EQ;
          f.value = arrayValue[0];
        }
      }
    }
    let filterByObj: Filter | undefined = undefined;
    if (possibleFilters.length > 0) {
      if (possibleFilters.length > 1) {
        filterByObj = { operation: OperationType.AND, filters: possibleFilters };
      } else {
        filterByObj = possibleFilters[0];
      }
    }


    this.crudRequest.searchRequest = { filter: { text: this.crudRequest.searchRequest?.filter?.text || '' , filterBy: filterByObj } };
    this.store.dispatch(new BaseAction(DynaConfActionType.FilterChange, this.crudRequest));

  }

}
