<div class="d-flex position-relative align-items-center justify-content-end w-100" >

  <button mat-icon-button mat-dialog-close class="position-absolute top-0" [disabled]="(disabled() | async)">

    <mat-icon>
      close
    </mat-icon>
  </button>

</div>

<div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">

  <div class="d-flex flex-column">

    <h5>
      Abbiamo trovato una sessione aperta di set insert <br>
      vorresti caricare la sessione?
    </h5>
    <pre>
      <span style="color: rgb(239,98,98); font-style: italic">dovresti aspettare il caricamento dei prodotti se hai il internet troppo lento*</span>
    </pre>

  </div>


  <div class="d-flex gap-5">
    <!-- {{(data.valid ) | async }} -->

    <button mat-raised-button color="warn" [disabled]="(disabled() | async)" (click)="deleteLoad(); " > No / Delete </button>

    <button mat-raised-button mat-dialog-close [disabled]="(disabled() | async)" >No</button>

    <button mat-raised-button color="primary" [disabled]="(disabled() | async)" (click)="dialogRef.close('yes')"  >Yes</button>

  </div>

</div>
