import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DynacrudApi } from 'src/app/core/models/dynacrud';
import { environment } from 'src/environments/environment';
import { WarehouseMovementDTO } from '../models/entities';

@Injectable({
  providedIn: 'root'
})
export class AddMovementService {

  constructor(private http: HttpClient) { }

  public saveMovement(WhMovement: WarehouseMovementDTO, sessionId?: number): Observable<DynacrudApi<WarehouseMovementDTO>> {
    if (sessionId) {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movements`, WhMovement);
    } else {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/movements`, WhMovement);
    }

  }

  public saveMovementBulk(WhMovement: WarehouseMovementDTO, sessionId?: number): Observable<DynacrudApi<WarehouseMovementDTO>> {
    if (sessionId) {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/${sessionId}/movementsbulk`, WhMovement);
    } else {
      return this.http.post<DynacrudApi<WarehouseMovementDTO>>(`${environment.apiUrl}/v2/sessions/movementsbulk`, WhMovement);
    }

  }
}
