<div class="d-flex align-items-center w-100 gap-1">

  <button mat-icon-button (click)="duplica()">
    <mat-icon>edit</mat-icon>

  </button>

  <button mat-icon-button (click)="deleteItem()">
    <mat-icon>delete</mat-icon>

  </button>

</div>