import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Store, select } from '@ngrx/store';
import { BehaviorSubject, Observable, catchError, debounceTime, distinctUntilChanged, forkJoin, take, throwError } from 'rxjs';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { DynacrudApi, Filter, OperationType, SearchRequest, SortDirection } from 'src/app/core/models/dynacrud';
import { State, selectCurrentDynaData } from 'src/app/store/shared/shared.reducers';
import { ReportsStructureDataFilter } from 'src/app/structure/models/Structure';
import { SubSink } from 'subsink';
import { DefaultListComponentConf } from '../../../default-list/default-list.component';
import {
  Combination,
  Entity,
  Order,
  Product,
  Warehouse
} from 'src/app/shared/models/entities';
import { SelectionMode, TableField } from 'src/app/core/models/table-config';
import { ProductStructureService } from 'src/app/structure/services/product-structure.service';
import { StructureInfoColumnComponent } from 'src/app/structure/components/table/structure-info-column/structure-info-column.component';
import { environment } from 'src/environments/environment';
import { TableData, TableStatus } from '../../table/table.component';
import * as _ from 'lodash';
import { DefaultDateColumnComponent } from '../../column-impl/default-date-column/default-date-column.component';
import { DefaultLinkToIdColumnComponent } from '../../column-impl/default-link-to-id-column/default-link-to-id-column.component';
import { OrderStatusId } from "../../../../../features/shop/models/entities";
import {
  PriceRulesIdsFace
} from "../../../../../features/shop/components/columns/go-to-rules-id/go-to-rules-id.component";

export interface GroupedOrderDetail extends Entity {
  actualPrice: number;
  avgPrice: number;
  combination: Combination;
  combinationId: number;
  maxPrice: number;
  minPrice: number;
  oldStatus: number;
  order: Order;
  orderId: number;
  price: number;
  product: Product;
  productId: number;
  productStructureId: number;
  quantity: number;
  status: OrderStatusId;
  totalPrice: number;
  totalQuantity: number;
  warehouse: Warehouse;
  warehouseId: number;
  whMovements: any;
  priceRules: PriceRulesIdsFace[];
  stockTotal: number;



}

@Component({
  selector: 'app-dialog-row',
  templateUrl: './dialog-row.component.html',
  styleUrls: ['./dialog-row.component.scss']
})
export class DialogRowComponent implements OnInit, OnDestroy {

  subs = new SubSink();
  defaultListConf!: DefaultListComponentConf;

  loading = false;

  searchRequest1: SearchRequest | undefined;
  searchRequest2: SearchRequest | undefined;

  constructor(@Inject(MAT_DIALOG_DATA) public row: GroupedOrderDetail,
    private dilogService: MatDialog, private dynacrudApiWrapp: DynacrudApiWrapper,
    private store: Store<State>, private breakpointObserver: BreakpointObserver,
    private productStructureService: ProductStructureService) { }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

  dataSourcetSub: BehaviorSubject<TableData> = new BehaviorSubject<TableData>({ data: [], tableStatus: { page: { length: 0, pageIndex: 0, pageSize: 0 }, sort: [] } });
  readonly dataSource: Observable<TableData> = this.dataSourcetSub.asObservable();



  formatDetailReport: DynacrudApi<any[]>[] = [];

  showNormal: boolean = false;
  changeToogle(toogle: MatSlideToggleChange) {

    this.showNormal = toogle.checked;
    this.loadTable();
  }
  mobile = false;
  currentData: SearchRequest | undefined;
  filter: ReportsStructureDataFilter = { brand: [], category: [], maxPrice: null, minPrice: null, groupByValue: false };


  // @HostListener('window:keyup.esc', ['$event'])

  onWindowsKey(event: any) {

    this.dilogService.closeAll();

    event.stopPropagation();
    event.preventDefault();



  }

  ngOnInit(): void {

    this.breakpointObserver.observe(['(max-width: 900px)']).pipe(distinctUntilChanged()).subscribe((state: BreakpointState) => {
      this.mobile = state.matches;
      if (this.formatDetailReport && this.formatDetailReport.length > 0) {
        this.loadTable();
      }
    })




    this.subs.add(

      this.store.pipe(select(selectCurrentDynaData)).pipe(debounceTime(50), distinctUntilChanged(), take(1)).subscribe(res => {


        this.currentData = res.request?.searchRequest ? { ...res.request?.searchRequest, pagination: { page: 0, size: 20 } } : undefined;
        this.filter = res.request?.customData || undefined;


        this.search();

      })




    );

  }



  search() {
    this.loading = true;
    let filter1 = undefined;
    let filter2 = undefined;

    const possibleFilters1: Filter[] = [];
    const possibleFilters2: Filter[] = [];

    const searchRequest1 = _.cloneDeep(this.currentData);
    const searchRequest2 = _.cloneDeep(this.currentData);

    

    let newGroupBy1: string[] = [];
    newGroupBy1.push(...this.currentData?.filter?.parameters['group by']);
    newGroupBy1.push('order.id');


    let newGroupBy2: string[] = [];
    newGroupBy2.push(...this.currentData?.filter?.parameters['group by'])

    newGroupBy2.push('order.id');
    newGroupBy2.push('combination.id');
    newGroupBy2.push('price');


    possibleFilters1.push({ field: this.filter.groupByValue ? 'product.idMetaProduct' : 'product.id', value: this.filter.groupByValue ? this.row.product.idMetaProduct : this.row.product.id, operation: OperationType.EQ });

    possibleFilters2.push({ field: this.filter.groupByValue ? 'product.idMetaProduct' : 'product.id', value: this.filter.groupByValue ? this.row.product.idMetaProduct : this.row.product.id, operation: OperationType.EQ });

    this.filter.productStructure?.stockfields?.forEach( f => {

      newGroupBy2.forEach(fg => {
        if ( f && (fg == ('combination.'+ f.field) ) ) {
          possibleFilters2.push({field: fg, value: this.row.combination[f.field as string], operation: OperationType.EQ});
        }

        if (f && (fg == ('combination.'+ f.field + '.id') )) {
          possibleFilters2.push({field: fg, value: this.row.combination[f.field as string].id, operation: OperationType.EQ});

        }

      });

      newGroupBy1.forEach(fg => {

        if ( f && (fg == ('combination.'+ f.field) ) ) {
          possibleFilters1.push({field: fg, value: this.row.combination[f.field as string], operation: OperationType.EQ});
        }

        if (f && (fg == ('combination.'+ f.field + '.id') )) {
          possibleFilters1.push({field: fg, value: this.row.combination[f.field as string].id, operation: OperationType.EQ});

        }

      });



    });



    if (this.currentData?.filter?.filterBy?.filters) {

      filter1 = [
        ...this.currentData.filter.filterBy.filters,
        ...possibleFilters1
      ];

      filter2 = [
        ...this.currentData.filter.filterBy.filters,
        ...possibleFilters2
      ];

    }

    if (searchRequest1 && searchRequest1.filter && searchRequest1.filter.filterBy) {

      searchRequest1.filter.filterBy.filters = filter1;

      this.filter.productStructure?.stockfields?.forEach(f => {

        const field = f.type == 'LOOKUP' ?  'combination.' + f.field + '.id' : 'combination.' + f.field;

        newGroupBy1 = [...newGroupBy1.filter(a => a != field )];

      });



      searchRequest1.filter.parameters['group by'] = [...newGroupBy1];
    }

    if (searchRequest2 && searchRequest2.filter && searchRequest2.filter.filterBy) {

      searchRequest2.filter.filterBy.filters = filter2;

      this.filter.productStructure?.stockfields?.forEach(f => {

        const field = f.type == 'LOOKUP' ?  'combination.' + f.field + '.id' : 'combination.' + f.field;

        newGroupBy2 =  [...newGroupBy2.filter(a => a != field )];

      });


      searchRequest2.filter.parameters['group by'] = [...newGroupBy2];

    }
    const searchRequestA1 = _.omit(searchRequest1, 'filter.havingFilter');
    const searchRequestA2 = _.omit(searchRequest2, 'filter.havingFilter');

    this.searchRequest1 = searchRequestA1;
    this.searchRequest2 = searchRequestA2;


    console.log('1', searchRequestA1);

    console.log('2', searchRequestA2);



    this.subs.add(
      forkJoin([
        this.dynacrudApiWrapp.getFor('groupedOrderDetails').search({ ...searchRequestA1 }),
        this.dynacrudApiWrapp.getFor('groupedOrderDetails').search({ ...searchRequestA2 }),
      ]).pipe(debounceTime(50), distinctUntilChanged(), catchError(err => {
        this.loading = false;
        return throwError(() => {
          return err;
        })
      })).subscribe(result => {

        // FIXME: per lavorare
        this.formatDetailReport = result;
        this.loading = false;

        this.loadTable();
      })
    );

  }

  tableStatusin!: TableStatus;

  tableStatusin2!: TableStatus;

  tableStatusChangeCartNoExtra(tableStatus: TableStatus) {

    this.tableStatusin2 = tableStatus;
    const searchRequest: SearchRequest = {};
    if (tableStatus && tableStatus.sort) {
      searchRequest.sort = tableStatus.sort.map(s => {
        return { field: s.field, direction: SortDirection[s.direction.toUpperCase() as SortDirection] };
      });
    }
    if (tableStatus && tableStatus.page) {
      searchRequest.pagination = { page: tableStatus.page.pageIndex, size: tableStatus.page.pageSize };
    }


    this.searchRequest2 = {
      filter: this.searchRequest2?.filter,
      pagination: searchRequest.pagination,
      sort: searchRequest.sort
    };


    this.subs.add(
      forkJoin([
        this.dynacrudApiWrapp.getFor('groupedOrderDetails').search({ ...this.searchRequest2 }),
      ]).pipe(debounceTime(50), distinctUntilChanged(), catchError(err => {
        this.loading = false;
        return throwError(() => {
          return err;
        })
      })).subscribe(result => {

        // FIXME: per lavorare
        this.formatDetailReport[1] = result[0];
        this.loading = false;

        this.loadTable();
      })
    );

  }

  tableStatusChangeCart(tableStatus: TableStatus) {

    this.tableStatusin = tableStatus;

    const searchRequest: SearchRequest = {};

    if (tableStatus && tableStatus.sort) {
      searchRequest.sort = tableStatus.sort.map(s => {
        return { field: s.field, direction: SortDirection[s.direction.toUpperCase() as SortDirection] };
      });
    }

    if (tableStatus && tableStatus.page) {
      searchRequest.pagination = { page: tableStatus.page.pageIndex, size: tableStatus.page.pageSize };
    }

    this.searchRequest1 = {
      filter: this.searchRequest1?.filter,
      pagination: searchRequest.pagination,
      sort: searchRequest.sort
    };


    this.subs.add(
      forkJoin([
        this.dynacrudApiWrapp.getFor('groupedOrderDetails').search({ ...this.searchRequest1 }),
      ]).pipe(debounceTime(50), distinctUntilChanged(), catchError(err => {
        this.loading = false;
        return throwError(() => {
          return err;
        })
      })).subscribe(result => {


        // FIXME: per lavorare
        this.formatDetailReport[0] = result[0];
        this.loading = false;

        this.loadTable();

      })
    );


  }

  isOpen = false;

  @HostListener('document:click', ['$event']) clickedOutside() {

    this.isOpen = false;
  }

  urlv1 = `https://${environment.gestionale_V1URL_PRICE}.galactus.it/home/price_rule/`;

  getUrl(path: number) {

    return this.urlv1 + this.row.product.brandId + '/' + this.row.product.categoryId + '/' + path.toString();

  }

  private loadTable() {
    if (this.filter) {

      let det: DynacrudApi<any[]>;

      if (this.showNormal) {
        det = this.formatDetailReport[0] || [];
      } else {
        det = this.formatDetailReport[1] || [];
      }

      this.dataSourcetSub.next({
        data: det.data as [], tableStatus: {
          sort: det.meta?.sort || [],
          page: { pageSize: det.meta?.pagination?.pageSize as number, pageIndex: det.meta?.pagination?.currentPage as number, length: det.meta?.pagination?.totalElements as number }
        }
      });

      this.filter.productStructure = this.productStructureService.findStructure(parseInt(this.row.product.categoryId.toString()), parseInt(this.row.product.brandId.toString()));

      const columnsFields: TableField[] = [];

      // const omitStructure: string[] = [];

      // FIXME: creare componente generico para el id con click que porta al carro de compra

      // columnsFields.push(new TableField('product.imageName', DefaultImageColumnComponent, 'stock.image', undefined, false ));

      if (this.showNormal) {

        if (this.mobile) {
          // push columns on mobile

          columnsFields.push(new TableField('totalQuantity', undefined, 'stock.total_quantity'));
          columnsFields.push(new TableField('order.description', DefaultLinkToIdColumnComponent, 'description_order', undefined, true, 'order.id'));
        } else {

          columnsFields.push(new TableField('totalQuantity', undefined, 'stock.total_quantity'));
          columnsFields.push(new TableField('order.warehouse', undefined, 'stock.warehouse'));
          // modifiedDate

          columnsFields.push(new TableField('order.description', DefaultLinkToIdColumnComponent, 'description_order', undefined, true, 'order.id'));

          columnsFields.push(new TableField('order.modifiedDate', DefaultDateColumnComponent, 'Date', undefined, true));



        }

      } else {

        if (this.mobile) {
          // push columns on mobile

          columnsFields.push(new TableField('totalQuantity', undefined, 'stock.total_quantity'));
          columnsFields.push(new TableField('extra_info', StructureInfoColumnComponent, undefined, undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['expansion'] }));
        } else {

          columnsFields.push(new TableField('totalQuantity', undefined, 'stock.total_quantity'));
          columnsFields.push(new TableField('extra_info', StructureInfoColumnComponent, undefined, undefined, false, undefined, { productPath: 'product', stockPath: 'combination', omitFields: ['expansion'] }));

          columnsFields.push(new TableField('price', undefined, 'Price'));

          columnsFields.push(new TableField('order.description', DefaultLinkToIdColumnComponent, 'description_order', undefined, true, 'order.id'));



        }

      }

      const displayedColumns = /*this.conf.displayedColumns || */[...columnsFields.map(cf => cf.id)];

      this.defaultListConf = {
        loadOnInit: false,

        tableConfig: {
          selectionMode: SelectionMode.single,
          actions: [],
          columnsFields: [...columnsFields]
        },
        displayedColumns: [...displayedColumns]
      };
    }
  }

}
