import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { ActionHostDirective } from './directives/action-host.directive';
import { ColumnHostDirective } from './directives/column-host.directive';
import { TableComponent } from './components/table/table/table.component';
import { ContextService } from './services/context.service';
import { ErrorHandlerService } from './services/error-handler.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ColumnComponent } from './components/table/column/column.component';
import { ActionComponent } from './components/table/action/action.component';
import { DefaultColumnComponent } from './components/table/column-impl/default-column/default-column.component';
import { DefaultCheckColumnComponent } from './components/table/column-impl/default-check-column/default-check-column.component';
import { DefaultCurrencyColumnComponent } from './components/table/column-impl/default-currency-column/default-currency-column.component';
import { DefaultDateColumnComponent } from './components/table/column-impl/default-date-column/default-date-column.component';
import { DefaultImageColumnComponent } from './components/table/column-impl/default-image-column/default-image-column.component';
import { DefaultSearchHeaderComponent } from './components/default-search-header/default-search-header.component';
import { DefaultListComponent } from './components/default-list/default-list.component';
import { DefaultSelectComponent } from './components/default-select/default-select.component';
import {OverlayModule} from '@angular/cdk/overlay';
import { DialogRowComponent } from './components/table/dialogs/dialog-row/dialog-row.component';
import { DefaultLinkToIdColumnComponent } from './components/table/column-impl/default-link-to-id-column/default-link-to-id-column.component';
import {
  DialogLoadSetInsertComponent,
  TableEditRowsComponent
} from './components/table/table-edit-rows/table-edit-rows.component';
import { ActionsSetInsertComponent } from './components/table/column/actions-set-insert/actions-set-insert.component';
import { StructureRapidLookUpFieldNormalComponent } from './components/table/column-impl/structure-rapid-look-up-field-normal/structure-rapid-look-up-field-normal.component';
import { DefaultSearchHeaderCodeComponent } from './components/default-search-header-code/default-search-header-code.component';
import { NgxUiLoaderConfig, SPINNER, PB_DIRECTION, POSITION, NgxUiLoaderModule } from 'ngx-ui-loader';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DefaultSelectAllButtonComponent } from './components/default-select-all-button/default-select-all-button.component';




const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsColor: 'yellow',
  fgsType: SPINNER.threeStrings, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 3, // progress bar thickness
  pbColor: 'yellow',
  delay: 0,
  blur: 0,
  hasProgressBar: true, // Valutare di rimuovere la prima barra
  maxTime: -1,
  minTime: 300,
  bgsColor: 'yellow',
  bgsOpacity: 1,
  bgsPosition: POSITION.centerCenter,
  bgsSize: 80,
  fgsSize: 100,
  fgsPosition: POSITION.centerCenter,
  bgsType: SPINNER.threeStrings
};




@NgModule({
  declarations: [


    ColumnComponent,
    ActionComponent,
    ActionHostDirective,
    ColumnHostDirective,
    TableComponent,
    DefaultColumnComponent,
    DefaultCheckColumnComponent,
    DefaultCurrencyColumnComponent,
    DefaultDateColumnComponent,
    DefaultImageColumnComponent,
    DefaultListComponent,
    DefaultSelectComponent,
    DialogRowComponent,
    DefaultLinkToIdColumnComponent,
    TableEditRowsComponent,
    ActionsSetInsertComponent,
    StructureRapidLookUpFieldNormalComponent,
    DialogLoadSetInsertComponent,
    DefaultSelectAllButtonComponent,
    
  ],
  imports: [
    NgOptimizedImage,
    OverlayModule,
    NgxMatSelectSearchModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    CommonModule,
    SharedModule,
    TranslateModule.forChild(),
    FormsModule,
  ],
  exports: [
    TableComponent,
    TableEditRowsComponent,
    DefaultSelectComponent,
    DefaultListComponent,
    ColumnComponent
  ],
  providers: [
    // { provide: LOCALE_ID, useValue: 'it' },
    ContextService,
    {
      provide: APP_INITIALIZER,
      useFactory: () => function() {
        return true;
      },
      deps: [ContextService],
      multi: true
    },
    {provide: ErrorHandler, useClass: ErrorHandlerService}
  ],
  // entryComponents: [DefaultColumnComponent, DefaultImageColumnComponent, DefaultSearchHeaderComponent, DefaultSearchHeaderCodeComponent, DefaultDateColumnComponent, DefaultCurrencyColumnComponent, ActionComponent]
})
export class CoreModule { }
