<div class="d-flex w-100 align-items-center justify-content-end">

    <div class="d-flex align-items-center w-25 p-2">

        <mat-form-field class="d-flex w-100">
            <mat-label>Select Motivazione</mat-label>
            <mat-select [(ngModel)]="motivation" (ngModelChange)="searchMotivation()" name="motivation"
                [compareWith]="compareEntityName">
                <mat-option [value]="undefined">All</mat-option>
                <mat-option *ngFor="let item of motivations; index as m" [value]="item">
                    {{item.description}}

                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>