import { FlatTreeControl } from '@angular/cdk/tree';
import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import { MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, Observable, interval, delay, debounceTime, map, catchError, throwError, take } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { selectAuth, selectAuthRoles } from 'src/app/store/auth/auth.reducers';
import { LayoutActionType, selectSidenavMenu, selectLayoutSidenav, State } from 'src/app/store/layout/layout.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';
import { SIDENAV_CONFIG } from '../../models/data-sidenav';
import { MenuItem } from '../../models/layout-models';
import { EventsService } from '../../services/events.service';
import * as _ from 'lodash';

export const defaultHelp = 'il punto rosso segnala in quale feature ti trovi';

declare var bootstrap: any;

interface Nodes {
  expandable: boolean;
  isExpanded: boolean;
  name: string;
  level: number;
  data: MenuItem; // Fixme Santiago deve andare in layout entities e può essere passato a livello di modulo
}

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit, OnDestroy {

  treeControl!: FlatTreeControl<any>;
  treeFlattener!: MatTreeFlattener<any, any>;
  dataSource!: MatTreeFlatDataSource<any, any>;
  menu: MenuItem[];
  selectedRoute: string | undefined;

  defaultItems = environment.menu;

  eventCoda: number = 0;
  chiamataMkm: number = 0;

  _bootstrap = bootstrap

  public defaultTesto = defaultHelp;


  constructor(private eventsService: EventsService, private store: Store<State>, private router: Router, private routeA: ActivatedRoute,
    private authService: AuthService, @Inject(SIDENAV_CONFIG) config: MenuItem[]) {

      this.subs.add(
        this.store.pipe(select(selectAuthRoles), distinctUntilChanged(), take(1)).subscribe(d => {
          this.roles = d;
        })

      );

    this.menu = config.map((down_) => {
      const v = down_;
      if (v.roles && v.roles.length > 0) {
        // fixme santiago pensiamoci un modo per levare tutto il feature da un altra parte :)


      } else if (v.children && v.children.find(d => d.roles && d.roles.length > 0)) {

        v.children.forEach((item, indx) => {

          if (item && item.roles) {

            const valuesOn = _.intersectionBy([...item.roles], [...this.roles]);

            if (valuesOn && valuesOn.length == 0) {


              v.children = v.children?.filter((d, index) => indx != index);

            }
          }






        });

      }

      return v;
    });


  }
  username!: string;
  private subs = new SubSink();
  private subs2 = new SubSink();


  ngAfterViewInit(): void {
    const me = this;
    // Inicializa los popovers
    const popoverTriggerList = [].slice.call(document.querySelectorAll('.popover-hover_'));

    popoverTriggerList.map(function (popoverTriggerEl: any) {
      return new me._bootstrap.Popover(popoverTriggerEl, {
        trigger: 'hover',
        placement: 'auto',
        html: true,
        customClass: 'custom_pop_',

        content: `
        <div class="position-relative d-flex" >

        ( <span class="position-relative bg-danger border punto_rosso  border-light rounded-circle">
          <span class="visually-hidden">New alerts</span>
        </span> )

        ${me.defaultTesto}
        </div>
        `
      });
    });
  }

  private transformer = (node: MenuItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      data: node,
      level
    };
  }

  public atLeastOne = false;

  hasChild = (nm: number, node: Nodes) => node.expandable;

  collapseAll() {

    this.treeControl.collapseAll();
    this.menu.forEach(n => n.opened = false);
    this.store.dispatch(new BaseAction(LayoutActionType.Menu, this.menu));
    // fixme chiudi tutto tranne la rotta corrente

  }
  toogle(node: Nodes) {
    node.data.opened = !node.data.opened;
    node.isExpanded = !node.isExpanded;
    if (!this.menu.find(d => d.opened)) {
      this.atLeastOne = false;
    }
    this.store.dispatch(new BaseAction(LayoutActionType.Menu, this.menu));
  }



  private merge(nodes: MenuItem[], oldNodes: MenuItem[]) {
    if (nodes && nodes.length > 0 && oldNodes) {
      nodes.forEach(n => {
        const old: MenuItem = oldNodes.find(o => o.name === n.name) as MenuItem;
        if (old) {
          n.opened = old.opened;
          this.merge(n.children as MenuItem[], old.children as MenuItem[]);
        }
      });
    }
  }


  roles: string[] = [];

  ngOnInit() {
    this.treeControl = new FlatTreeControl<Nodes>(node => node.level, node => node.expandable);
    this.treeFlattener = new MatTreeFlattener(this.transformer, node => node.level, node => node.expandable, node => node.children);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);


    // FIXME: prendo il ruolo prima --> sempre
    this.subs.add(


      this.store.pipe(select(selectSidenavMenu), distinctUntilChanged(), map(d => {

        // FIXME: SANTIAGO PENSACI AD UN ALTRO MODO per ora questa porcatta funziona
        const v = d;
        let items: MenuItem[] | MenuItem | undefined = [];

        if (v.items?.find(d => d.roles)) {
          items = v.items?.find(d => d.roles);
        } else if (v.items?.find(is => is.children?.find(wd => wd.roles && wd.roles.length > 0))) {
          items = v.items?.find(is => is.children?.find(wd => wd.roles && wd.roles.length > 0))?.children?.filter(d => d && d.roles && d.roles.length > 0);
          if (!items?.find(d => d.roles?.find((rl: any) => this.roles.find(w => w == rl)))) {
            v.items.map(it => {
              let ind = it.children?.findIndex(d => (items as MenuItem[])?.find(ret => ret.name == d.name));
              if (ind != undefined && ind != -1) {
                it.children = it.children?.filter((d, index) => ind != index);
              }
              return it;
            })
          }
        }

        return v;

      })).subscribe(m => {
        const savedMenuBefore = m.items;
        if (savedMenuBefore) {
          this.merge(this.menu, m.items as MenuItem[]);
          this.dataSource.data = this.menu;
          this.treeControl.dataNodes.forEach(n => {
            if (n.data.opened) {
              this.treeControl.expand(n);
              this.atLeastOne = true;
            }
          });
        } else {
          this.dataSource.data = this.menu;
        }

        if (m && m.selectedItem && m.selectedItem.route) {
          this.selectedRoute = m?.selectedItem?.route;

        } else {

          const routerCurrent = this.routeA.parent?.snapshot.url[0].path;

          if (routerCurrent && this.defaultItems && (this.defaultItems.find(d => d.route.includes(routerCurrent.toLowerCase())))) {
            this.selectedRoute = (this.defaultItems.find(d => d.route.includes(routerCurrent.toLowerCase())))?.route;
          }

        }

        const route = this.selectedRoute;


        let nameDir = route?.toLowerCase()?.substring(5);
        let getNameOtherside = nameDir?.indexOf('/');

        // name of feature :)
        let getotherside = nameDir?.substring(0, getNameOtherside as number);


        let nameNode = getotherside?.toLowerCase();
        let exist = route?.toLowerCase()?.includes(nameNode as string);



        if (exist && nameNode) {

          const str = nameNode;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          this.existNotify[str2] = true;

        } else if (!exist && nameNode) {

          const str = nameNode;
          const str2 = str.charAt(0).toUpperCase() + str.slice(1);
          this.existNotify[str2] = false;

        }

        this.treeControl.dataNodes.forEach(n => {
          if (n.data.opened) {
            this.treeControl.expand(n);
          }
        });

      }));

    this.subs.add(this.store.pipe(select(selectAuth), distinctUntilChanged()).subscribe(auth => {
      let nameFilter = auth.username;
      this.username = nameFilter ? nameFilter.substring(0, nameFilter.lastIndexOf("@")) : 'errorName';

    }));

    this.subs.add(
      this.store.pipe(select(selectLayoutSidenav), distinctUntilChanged()).subscribe(data => {
        let exampleObs = new Observable();
        if (data.opened) {
          //emit value every 1s
          const source = interval(3000);
          //after 5 seconds, emit value
          const timer$ = delay(0);
          //when timer emits after 5s, complete source
          exampleObs = source.pipe(timer$);
          this.subs2.add(
            exampleObs.subscribe(() => {
              this.eventCode(); // FIXME: QUESTO FA PARTIRE EVENTO MKM
            })
          );
        } else if (!data.opened) {
          this.unsubscribe2();
        }
      })
    );
  }
  logout() {
    this.subs.unsubscribe();
    this.unsubscribe2();
    this.authService.logout();
  }


  private unsubscribe2() {
    this.subs2.unsubscribe();
  }

  private eventCode() {
    // this.store.pipe(select(selectLayoutSidenav)).subscribe(data => {
    //   if (data.opened) {
    this.subs.add(
      this.eventsService.EventCode().pipe(distinctUntilChanged(), debounceTime(environment.defaultDebounceTime),
      map(data => data.data), catchError(err => {
        return throwError(() => err);
      })).subscribe(data => {
        this.eventCoda = data ? data : 0;
      }),
      this.eventsService.EventMKM().pipe(distinctUntilChanged(), debounceTime(environment.defaultDebounceTime),
      map(data => data.data), catchError(err => {
        return throwError(() => err);
      })).subscribe(data => {
        this.chiamataMkm = data ? data : 0;
      })
    );

    // }
    // })
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    this.subs2.unsubscribe();
  }

  existNotify: { [key: string]: boolean } = {};

  go(node: Nodes) { // FIXME SANTIAGO --vai con lo stato

    this.store.dispatch(new BaseAction(LayoutActionType.MenuOpen, node.data));

    this.router.navigateByUrl(node.data.route as string | UrlTree).then();


  }

}
