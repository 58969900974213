import {Injectable, Type} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Warehouse} from '../models/entities';
import {BehaviorSubject, Observable} from 'rxjs';
import {DynacrudApi} from '../../core/models/dynacrud';
import {environment} from '../../../environments/environment';
import {DynacrudWeakDataService} from './DynacrudWeakDataService';
import {DynacrudApiWrapper} from '../../core/api/dynacrud-api';
import {tap} from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class WarehouseService extends DynacrudWeakDataService<Warehouse> {

  private warehouseChangeSub: BehaviorSubject<Warehouse | undefined> = new BehaviorSubject<Warehouse | undefined>(undefined);
  public readonly warehouseChange: Observable<Warehouse | undefined> = this.warehouseChangeSub.asObservable();

  constructor(private http: HttpClient, dynacrudApiWrapper: DynacrudApiWrapper) {
    super('warehouses', WarehouseService, dynacrudApiWrapper);
  }

  getType(): Type<Warehouse> | string {
    return 'warehouse';
  }

  getCurrentWarehouse(): Observable<DynacrudApi<Warehouse>> {
    return this.http.get<DynacrudApi<Warehouse>>(`${environment.apiUrl}/v2/warehouses/current`).pipe( tap(w => this.warehouseChangeSub.next(w.data)));
  }

  changeCurrentWarehouse(currWarehouseId: number | null): Observable<DynacrudApi<Warehouse>> {
    return this.http.put<DynacrudApi<Warehouse>>(`${environment.apiUrl}/v2/warehouses/current`, currWarehouseId ).pipe( tap(w => this.warehouseChangeSub.next(w.data)) );
  }
}
