<nav *ngIf="nameProd" class="navbar navbar-expand-lg fixed-bottom bg-dark " style="margin: 0 50px;">
  <div class="container-fluid justify-content-between ">
    <div class="d-flex">
      <span  style="color: white; font-size: 20px;">
        {{nameProd}}
      </span>
    </div>

    <div class="d-flex">
      <span class="sn" (click)="nameProd = undefined">X</span>
    </div>
  </div>
</nav>

<router-outlet></router-outlet>
