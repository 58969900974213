import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActionData, TableActionComponent } from 'src/app/core/components/table/Actions';
import { SessionWrapperService } from '../../../services/session-wrapper.service';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { Store } from '@ngrx/store';
import { DynaConfActionType, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-wrapp-session-action',
  templateUrl: './wrapp-session-action.component.html',
  styleUrls: ['./wrapp-session-action.component.scss']
})
export class WrappSessionActionComponent implements TableActionComponent, OnDestroy, OnInit {
  @Input() data!: ActionData;
  idWrapp: number | undefined;
  environment = environment;
  private subs = new SubSink();
  constructor(private sessionWrapService: SessionWrapperService,
    private activeRouter: ActivatedRoute,
    private dialogLayoutService: DialogService,
    private store: Store<State>,
    private router: Router) { }

  row() {
    return this.data.item;
  }


  ngOnInit() {
    this.activeRouter.params.pipe().subscribe(d => {
      this.idWrapp = +d['id'];
    });

  }

  set() {

    const isSession = this.router.url.includes('Wrapper') ? false : true;

    if (this.row().id >= 1  && this.idWrapp) {
      const idSessione = this.row().id;

      this.store.dispatch(new BaseAction( LayoutActionType.LoadingBlock , true ));

      this.subs.add(
        this.sessionWrapService.addSession(this.idWrapp as number, { warehouseSessionIds: [idSessione] }).pipe(
          
          catchError((err) => {
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            return throwError(() => err);
          }), map( a => a.data )).subscribe((d) => {
            
            this.dialogLayoutService.openSnack('Sessione aggiunta', 'done', 5000)
            this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
            this.store.dispatch(new BaseAction(LayoutActionType.LoadingBlock, false));
            this.sessionWrapService.refreshAction(true);
            this.sessionWrapService.reloadVersione(d);

            if (isSession) {
              this.router.navigateByUrl(`app/warehouse/sessionWrapper/${d.id}`).then(() => {
                this.sessionWrapService.refreshAction(true);
              });
            }

          })
      );


    } else {
      if (isSession) {
        this.router.navigateByUrl(`app/warehouse/sessionWrapper/add`).then(d => {
          this.sessionWrapService.addRow(this.data.item);
          this.dialogLayoutService.openSnack('Sessione aggiunta', 'done', 5000);
          this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));
        })
      } else {

        this.sessionWrapService.addRow(this.data.item);
        this.dialogLayoutService.openSnack('Sessione aggiunta', 'done', 5000);
        this.store.dispatch(new BaseAction(DynaConfActionType.Refresh));

      }
    }

  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
