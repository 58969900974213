import { AfterViewInit, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { Observable, debounceTime, map } from 'rxjs';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DynacrudApiWrapper } from 'src/app/core/api/dynacrud-api';
import { OperationType, SearchRequest } from 'src/app/core/models/dynacrud';
import { Product, Warehouse } from 'src/app/shared/models/entities';
import { SharedService } from 'src/app/shared/services/shared.service';
import { selectAuth } from 'src/app/store/auth/auth.reducers';
import { LayoutActionType } from 'src/app/store/layout/layout.reducers';
import { selectFeatureCode, selectRouteConf, State } from 'src/app/store/shared/shared.reducers';
import { BaseAction } from 'src/app/store/store.actions';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

export const arrayFeaturesEditabileSessionsName = ['session_detail'];

@Component({
  selector: 'app-warehouse-header',
  templateUrl: './warehouse-header.component.html',
  styleUrls: ['./warehouse-header.component.scss']
})
export class WarehouseHeaderComponent implements OnInit, OnDestroy, AfterViewInit {

  private subs = new SubSink();
  username: string | undefined;
  // children: PositionChild[] | undefined;
  warehouse: Observable<Warehouse[]> | undefined;
  isSearchProductVisible?: boolean = false;

  edita = false;
  disableSelect = new FormControl(true);



  constructor(private store: Store<State>,
    private authService: AuthService,
    private dynacrudApiWrapper: DynacrudApiWrapper) {
  }

  ngAfterViewInit(): void {
    
  }

  defaultSearchProduct(t: string): SearchRequest {

    return {
      filter: {
        filterBy: {
          operation: OperationType.AND,
          filters: [
            { field: 'obsolete', operation: OperationType.EQ, value: false },
          ]
        }, text: t
      }, pagination: { page: 0, size: 20 }, sort: []
    };

  }

  

  noImage(value: Product): void {
    value.imageName = environment.DefaultImageMagic || '';
  }

  hoverProduct(e: any, product: any) {
    this.isOpen = {};
    this.isOpen[product.id] = !this.isOpen[product.id];
    e.stopPropagation();
  }

  loading = false;
  showImg = false;
  clicEventProduct = false;
  isOpen: { [key: string]: any } = {};

  @HostListener('mouseleave') onMouseLeave() {
    this.showImg = false;
    this.isOpen = {};
  }

  ngOnInit() {

    this.subs.add(this.store.pipe(select(selectAuth)).subscribe(auth => this.username = auth.username));

    this.subs.add(this.store.pipe(select(selectFeatureCode), debounceTime(50)).subscribe(d => {

      this.edita = arrayFeaturesEditabileSessionsName.find(a => a === d) != undefined ? true : false;

    }));

    this.warehouse = this.dynacrudApiWrapper.getFor('Warehouses').search()
      .pipe(
        map(
          ret => ret.data
        )
      ) as Observable<Warehouse[]>;

  }

  logout() {
    this.authService.logout();
  }
  toogleSideNav() {
    this.store.dispatch(new BaseAction(LayoutActionType.SidenavButtonClick));
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
